@use '@angular/material' as mat;
@include mat.core();
$website-scholenwijzer-primary: mat.define-palette(mat.$indigo-palette);
$website-scholenwijzer-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$website-scholenwijzer-warn: mat.define-palette(mat.$red-palette);
$website-scholenwijzer-theme: mat.define-light-theme((
        color: (
                primary: $website-scholenwijzer-primary,
                accent: $website-scholenwijzer-accent,
                warn: $website-scholenwijzer-warn,
        )
));
@include mat.all-component-themes($website-scholenwijzer-theme);

@import "assets/css/jce.css";
@import "assets/js/slick/slick.css";
@import "assets/js/slick/slick-theme.css";
@import "nouislider/dist/nouislider.min.css";
@import "utils";

/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  padding: 0;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.match-c-search {
  display: block;
  margin-top: 15px;
  margin-left: 18px;
}

@media (max-width: 420px) {

  .match-c-search {
    margin-left: 18px;
  }

}

.tooltipster-content a {
  text-decoration: underline;
}


.section-school-header {
    lottie-player {
        position: absolute;
        width: calc(500px * 0.8);
        height: calc(500px * 0.8);
        top: 0;
        right: 0;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .scroll-container {
        position: absolute;
        width: 300px;
        height: 300px;
        bottom: 200px;
        z-index: 300;

        @media (max-width: 991px) {
            width: 250px;
            height: 250px;
        }

        @media (max-width: 621px) {
            width: 200px;
            height: 200px;
            bottom: 140px;
        }
    }
}

.section-category-header {
    lottie-player {
        position: absolute;
        width: calc(500px * 0.7);
        height: calc(500px * 0.7);
        top: 0;
        right: 0;

        @media (max-width: 1480px) {
            width: calc(500px * 0.6);
            height: calc(500px * 0.6);
        }

        @media (max-width: 1280px) {
            width: calc(500px * 0.5);
            height: calc(500px * 0.5);
        }

        @media (max-width: 991px) {
            width: calc(500px * 0.4);
            height: calc(500px * 0.4);
        }

        @media (max-width: 700px) {
            width: calc(500px * 0.3);
            height: calc(500px * 0.3);
        }
    }

    .st-content {
        ng-lottie {
            position: absolute;
            width: calc(500px * 0.25);
            height: calc(500px * 0.25);
            top: -120px;
            left: -160px;
        }
    }
}

.section-school-text {
    lottie-player {
        position: absolute;
        width: calc(500px * 0.8);
        height: calc(500px * 0.8);
        top: 0;

        @media (max-width: 1480px) {
            width: calc(500px * 0.7);
            height: calc(500px * 0.7);
        }

        @media (max-width: 1280px) {
            width: calc(500px * 0.6);
            height: calc(500px * 0.6);
        }

        @media (max-width: 991px) {
            width: calc(500px * 0.5);
            height: calc(500px * 0.5);
        }
    }
}

.section-category-article {
    lottie-player {
        position: absolute;
        width: calc(500px * 0.8);
        height: calc(500px * 0.8);
        bottom: 0;
        left: 0;

        @media (max-width: 1280px) {
            width: calc(500px * 0.6);
            height: calc(500px * 0.6);
        }

        @media (max-width: 991px) {
            width: calc(500px * 0.5);
            height: calc(500px * 0.5);
        }
    }
}

