.mod-article-images {
	
	margin-bottom: 20px;
	
	.title {
		margin-bottom: 20px;
	}
	
	.article-images {
		
		overflow: hidden;
		
		a {
			float: left;
			
			width: 18%;
			margin: 0 2% 20px 0;
			
			&:hover {
				opacity: 0.8;
			}
			
		}
		
	}
	
}


@media (max-width: 1480px) {
	
	.mod-article-images {
	
		.article-images {
			
			a {
				
				width: 23%;
				margin: 0 2% 20px 0;
				
			}
			
		}
	
	}

}


@media (max-width: 1281px) {
	
	.mod-article-images {
	
		.article-images {
			
			a {
				
				width: 31%;
				margin: 0 2% 20px 0;
				
			}
			
		}
	
	}

}


@media (max-width: 620px) {
	
	.mod-article-images {
	
		.article-images {
			
			a {
				
				width: 48%;
				margin: 0 2% 20px 0;
				
			}
			
		}
	
	}

}