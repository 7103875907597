a.back-to-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: @black;


  position: fixed;
  bottom: 80px;

  z-index: 500;

  transition: all 0.3s ease-in-out;
  opacity: 0;
  right: -45px;
  pointer-events: none;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-90deg);

    font-size: 30px;
    color: @white;

  }

  &.show {
    opacity: 1;
    right: 30px;
    pointer-events: auto;
  }

}

.c-search {

  .input {
    width: 190px;
    height: 40px;
    float: left;
    margin-right: 10px;

    border: 2px solid @black;
    border-radius: 40px;
    padding-left: 15px;
    font-size: 14px;
  }

  a, button {
    display: block;
    float: left;
    background: @black;
    line-height: 40px;
    padding: 0 25px;
    border-radius: 40px;
    margin-left: 10px;
    font-size: 14px;
    color: @white;
  }

  button {
    border: none;
    &:hover {
      opacity: 0.7;
    }
  }

}


.c-search-box {

  background-color: @white;

  position: absolute;
  top: -50%;
  right: 40px;
  z-index: 50;

  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;

  padding-right: 30px;

  &.show {
    top: 50%;
  }

  form {

    position: relative;

    .input {
      width: 300px;
      height: 40px;

      border: 2px solid @black;
      border-radius: 40px;
      padding-left: 15px;
      font-size: 14px;
    }

    a {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

  }

  .toggle-search-close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

}



.c-accordeon {

  .panel {
    padding-right: 40px;
  }

  .question {

    font-size: 20px;
    line-height: 1.2em;
    padding: 12px 0;
    margin: 0;
    border-bottom: 2px solid @purple;
    position: relative;

    cursor: pointer;

    .arrow {
      position: absolute;
      top: 14px;
      right: -36px;
      transition: all 0.25s ease-in-out;
    }

    &.open {

      border-color: @white;

      .arrow {
        transform: rotate(90deg);
      }

    }

  }

  .answer {

  }

}


.c-faq-links {

  margin-top: 0;

  .question {
    display: block;
    font-size: 20px;
    line-height: 1.2em;
    padding: 12px 0;
    margin: 0;
    border-bottom: 2px solid @purple;
    position: relative;

    cursor: pointer;

    .arrow {
      position: absolute;
      top: 14px;
      right: -36px;
      transition: all 0.25s ease-in-out;
    }

  }

}


.c-anchor-menu {

  margin-top: 40px;

  ul,li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 8px 0;
  }


  a {
    display: block;
    font-weight: 700;
    position: relative;
    padding-left: 30px;

    .text {
      display: inline-block;
      border-bottom: 1px solid @black;
    }

    i {
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      color: @purple;
    }

  }

}


.c-article-list {

  .article-item {

    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &.c-kostenleerjaar {

      .article-output {

        p {
          display: block;
          margin-bottom: 5px;
        }

      }

    }

    .article-actions {

      &.floats {

        margin-top: 20px;
        overflow: hidden;

        .float-a {
          float: left;
          margin-right: 50px;
        }

        .float-b {
          float: left;
        }

      }

    }

  }

}


.c-tiplist {

  li {
    padding: 4px 0;
    position: relative;

    .infotip {
      position: relative;
      top: -5px;
      opacity: 0.8;
    }

  }

}


/* artikel blokken in de slider */
.c-article-box {

  .article-image {
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      background: @purple;

      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    .arrow {
      font-size: 46px;
      color: @white;


      position: absolute;
      top: 70%;
      left: 50%;
      z-index: 20;

      transform: translate(-50%,-50%);

      transition: all 0.2s ease-in-out 0.1s;
      opacity: 0;

    }

  }

  .bgimg {

    height: 300px;
    .bgcover;

    position: relative;
    transition: all 0.3s ease-in-out;

    img {
      display: none;
    }

  }

  .title {

    .inside {
      display: block;
      position: relative;
      padding-right: 28px;

      i {
        position: absolute;
        top: 0;
        right: 0;
      }

    }

  }

  .arrow {

  }

  &:hover {

    .article-image {

      &:before {
        transform: scale(1);
        opacity: 0.7;
      }

      .arrow {
        top: 50%;
        opacity: 1;
      }

    }

    .bgimg {

      transform: scale(1.1);

    }

  }

}


.article-grid-item,
.slick-slide {

  &:nth-child(4n+2) {

    .article-image {

      &:before {
        background-color: @green;
      }

    }

  }

  &:nth-child(4n+3) {

    .article-image {

      &:before {
        background-color: @blue;
      }

    }

  }

  &:nth-child(4n+4) {

    .article-image {

      &:before {
        background-color: @pink;
      }

    }

  }

}



.c-blocks {

  text-align: center;

  .c-block {
    display: inline-block;
    text-align: left;

    position: relative;
    padding-right: 200px;

    .line-2 {
      margin-bottom: 0;
    }

    .button {
      position: absolute;
      right: 0;
      bottom: 17px;

      &.v-middle {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
      }

    }

  }

}



.c-article-data {

  margin-top: 60px;

  .data-footer-text {

    margin-top: 15px;

    .key {
      display: inline-block;
      margin-right: 30px;
    }

    .value {
      display: inline-block;
      .f2;
    }

  }

}


.c-data-table {

  width: 100%;

  .title {
    display: block;
    .f2;
  }
  .subtitle {
    display: block;
    font-size: 14px;
  }

  tr {
    border-bottom: 2px solid @blue;
  }

  td {
    vertical-align: bottom;
    padding: 5px 0;

    &.center {
      text-align: center;
    }

  }

  td.key {
    width: 160px;
  }

}


.c-data-table-beschikbaar {

  width: 80%;

  .title {
    display: block;
    .f2;
  }
  .subtitle {
    display: block;
    font-size: 14px;
  }

  tr {
    border-bottom: 2px solid @blue;

    &:last-child {
      border-bottom: none;
    }

  }

  td {
    vertical-align: bottom;
    padding: 5px 0;
  }

  td.value {
    width: 80px;
    text-align: center;
    border-left: 2px solid @blue;

    &.fit {
      width: fit-content;
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
    }
  }

}


.c-list-view {

    .flex-wrapper:nth-child(even) {
        .list-row {
            background-color: white;
        }
    }

  .list-row {

    background-color: @white;

    position: relative;
    overflow: hidden;

    padding: 0 25px;
    border-radius: 40px;
    margin: 5px 0;


      &.row-pull-left {
      margin-left: -25px;
    }

    &:nth-child(odd) {
      background-color: #E8F2FA;
    }

    .row-action {
      display: block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      font-size: 32px;

      transition: all 0.3s ease-in-out;
    }

    &.has-goto {

      .row-action {
        right: 25px;
      }

      &:hover {
        background-color: @baby2;

        .row-action {
          right: 10px;
        }

      }

    }

    .cell {

      width: 25%;
      float: left;
      padding: 10px 0;

      &.cell-brd {
        border-left: 2px solid @grey5;
        padding-left: 15px;
      }

      &.cell-title {
        width: 75%;
      }

      &.cell-1x {
        background-color: #0a939f;
      }
      &.cell-2x {
        background-color: #4c7c33;
      }
      &.cell-3x {
        background-color: indianred;
      }
      &.cell-4x {
        background-color: dodgerblue;
      }

    }
    
  }

}

@media (max-width: 1480px) {


}

@media (max-width: 1281px) {


}

@media (max-width: 991px) {

  .c-list-view {

    .list-row {

      &.row-pull-left {
        margin-left: 0;
      }

    }

  }

}

@media (max-width: 767px) {


}



.c-ajax-loader {

  min-height: 200px;
  background-image: url(../img/loader.gif);
  background-position: center center;
  background-repeat: no-repeat;

}



/* This is the core CSS of Tooltipster */
.infotip {
  cursor: pointer;
}


/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
  if greater that the natural height of the tooltip, will be enforced
  in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
  and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* grow */

.tooltipster-grow {
  -webkit-transform: scale(0,0);
  -moz-transform: scale(0,0);
  -o-transform: scale(0,0);
  -ms-transform: scale(0,0);
  transform: scale(0,0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  -o-transform: scale(1,1);
  -ms-transform: scale(1,1);
  transform: scale(1,1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

/* slide */

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}

/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */

/* .tooltipster-box */

.tooltipster-sidetip .tooltipster-box {
  background: @yellow;
  border-radius: 10px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}

/* .tooltipster-content */

.tooltipster-sidetip .tooltipster-content {

  font-size: 90%;
  color: @black;
  padding: 25px;

  p {

    &:last-child {
      margin-bottom: 0;
    }

  }

}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  /* half the width, for centering */
  margin-left: -10px;
  top: 0;
  width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
  been positioned yet */
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  /* same as .tooltipster-left .tooltipster-arrow */
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: @yellow;
  left: 0;
  top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: @yellow;
  left: -3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: @yellow;
  left: 3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: @yellow;
  left: 0;
  top: -3px;
}

/* .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: @yellow;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: @yellow;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: @yellow;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: @yellow;
}

/* tooltipster-arrow-uncropped */

.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}




/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #E0E0E0;
  border-bottom-width: 2px;
  background: #F8F8F8;
  position: relative;
  border-radius: 4px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 30px 0 0;
  padding: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #444;
  min-height: 18px;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOCA1IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjgiIGhlaWdodD0iNSI+PHBhdGggZD0iTTcuNzgxIDEuNDQ0Yy0uMjgxLjMwNy0zLjIzOCAzLjMxMS0zLjIzOCAzLjMxMS0uMTUxLjE2NC0uMzQ3LjI0NS0uNTQ0LjI0NS0uMTk3IDAtLjM5NC0uMDgyLS41NDMtLjI0NSAwIDAtMi45NTctMy4wMDMtMy4yMzktMy4zMTEtLjI4MS0uMzA3LS4zLS44NTkgMC0xLjE4OC4zMDEtLjMyOC43MTktLjM1NCAxLjA4NyAwbDIuNjk1IDIuNzU1IDIuNjk0LTIuNzU1Yy4zNjgtLjM1NC43ODctLjMyOCAxLjA4NyAwIC4zMDEuMzI4LjI4Mi44ODEgMCAxLjE4N3oiIGZpbGw9IiNiYmIiLz48L3N2Zz4K) no-repeat 50%;
  font: 0/0 a;
}

@media \0screen\,screen\9 {
  .selectric .button {
    color: #DDD;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}

.selectric-focus .selectric {
  border-color: #AAA;
}

.selectric-hover .selectric {
  border-color: #CCC;
}

.selectric-hover .selectric .button {
  color: #888;
}

.selectric-hover .selectric .button:after {
  border-top-color: #888;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #CCC;
  background: #F0F0F0;
  margin-top: 1px;
  border-bottom-width: 1px;
}

.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fefefe;
  border: 1px solid #CCC;
  z-index: -1;
  box-shadow: 0 0 10px -6px, inset 0 0 0px 1px #fff;
  margin: 8px 0;
  padding: 10px 0;
  border-radius: 4px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-items:before,
.selectric-items:after {
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #BBB;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}

.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}

.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #BBB;
  margin-top: 0;
}

.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 6px 30px 6px 10px;
  color: #666;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:after {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  right: -10px;
  width: 30px;
  height: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEwIj48cGF0aCBkPSJNMCA1YzAtLjIyNC4wNzItLjQxNS4yMTctLjU3MmwxLjA1My0xLjE0NWMuMTQ0LS4xNTcuMzItLjIzNi41MjYtLjIzNi4yMDYgMCAuMzgyLjA3OS41MjYuMjM2bDIuMjc2IDIuNDgzIDUuMDc5LTUuNTNjLjE0NS0uMTU3LjMyLS4yMzYuNTI2LS4yMzYuMjA2IDAgLjM4Mi4wNzkuNTI2LjIzNmwxLjA1MyAxLjE0NWMuMTQ0LjE1Ny4yMTcuMzQ4LjIxNy41NzIgMCAuMjI0LS4wNzIuNDE1LS4yMTcuNTcybC02LjY1OCA3LjIzOWMtLjE0NC4xNTctLjMyLjIzNi0uNTI2LjIzNi0uMjA2IDAtLjM4Mi0uMDc5LS41MjYtLjIzNmwtMy44NTYtNC4xOTJjLS4xNDUtLjE1Ny0uMjE3LS4zNDgtLjIxNy0uNTcyeiIgZmlsbD0iI2JiYiIvPjwvc3ZnPgo=) no-repeat 50% 50%;
  opacity: 0;
  -webkit-transition: .2s;
  transition: .2s;
}

.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}

.selectric-items li:hover {
  background: #F0F0F0;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}
