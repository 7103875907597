
/* ==========================================================================
	ANIMATIONS
   ========================================================================== */

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.6);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-logo {
  0% {
    transform: translateY(-50%) scale(1);
  }
  25% {
    transform: translateY(-50%) scale(1.05);
  }
  50% {
    transform: translateY(-50%) scale(0.95);
  }
  75% {
    transform: translateY(-50%) scale(1.05);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}


@keyframes FadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes FadeInUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes float-up {
	0% {
		transform: translate(0px);
	}
	33% {
		transform: translate(-15px,-40px);
	}
	66% {
		transform: translate(15px,-60px);
	}
	100% {
		transform: translate(0px);
	}
}

@keyframes float-down {
	0% {
		transform: translate(0,0);
	}
	33% {
		transform: translate(15px,40px);
	}
	66% {
		transform: translate(-15px,60px);
	}
	100% {
		transform: translate(0,0);
	}
}