.tpl-launch {
  min-height: 10vh;
}

.section-launch {

  .st-inner {

    &:before {
      width: 160px;
      height: 160px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/404/1.svg);
      background-position: top left;
      background-repeat: no-repeat;

    }

    &:after {
      width: 100px;
      height: 100px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/404/2.svg);
      background-position: top right;
      background-repeat: no-repeat;

    }

    .before {
      width: 240px;
      height: 200px;
      bottom: -54px;
      right: -120px;

      background-image: url(../img/svg/404/3.svg);
      background-position: bottom right;
      background-repeat: no-repeat;

    }

    .after {
      width: 200px;
      height: 160px;
      bottom: 0;
      left: -100px;

      background-image: url(../img/svg/404/4.svg);
      background-position: bottom left;
      background-repeat: no-repeat;

    }

  }

  .container {
    min-height: 60vh;
    position: relative;
  }

  .content {
    width: 950px;
    text-align: center;
    margin: 40px auto 60px;
  }

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-launch {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 220px;
        height: 200px;
        bottom: -54px;
        right: -120px;
      }

      .after {
        width: 180px;
        height: 160px;
        bottom: 0;
        left: -100px;
      }

    }

    .content {
      width: 850px;
    }

  }

}

@media (max-width: 991px) {

  .section-launch {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 180px;
        height: 180px;
        bottom: -42px;
        right: -90px;
      }

      .after {
        width: 160px;
        height: 100px;
        left: -80px;
      }

    }

    .content {
      width: auto;
    }

  }

}

@media (max-width: 767px) {

  .section-launch {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 180px;
        height: 180px;
        bottom: -42px;
        right: -90px;
      }

      .after {
        width: 160px;
        height: 100px;
        left: -80px;
      }

    }

    .content {

      margin-top: 80px;

      .h2 {
        font-size: 26px;
      }

    }

  }

}


@media (max-width: 600px) {

  .section-launch {

    .st-inner {

      &:before {
        width: 80px;
        height: 80px;
      }

      &:after {
        width: 80px;
        height: 80px;
      }

      .before {
        width: 160px;
        height: 160px;
        bottom: -42px;
        right: -80px;
      }

      .after {
        width: 140px;
        height: 100px;
        left: -70px;
      }

    }



    .content {

      margin-top: 40px;

      .h2 {
        font-size: 24px;
      }

    }

  }

}




.tpl-not-found {
  min-height: 10vh;
}

.section-404 {

  .st-inner {

    &:before {
      width: 160px;
      height: 160px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/404/1.svg);
      background-position: top left;
      background-repeat: no-repeat;

    }

    &:after {
      width: 100px;
      height: 100px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/404/2.svg);
      background-position: top right;
      background-repeat: no-repeat;

    }

    .before {
      width: 240px;
      height: 200px;
      bottom: -54px;
      right: -120px;

      background-image: url(../img/svg/404/3.svg);
      background-position: bottom right;
      background-repeat: no-repeat;

    }

    .after {
      width: 200px;
      height: 160px;
      bottom: 0;
      left: -100px;

      background-image: url(../img/svg/404/4.svg);
      background-position: bottom left;
      background-repeat: no-repeat;

    }

  }

  .container {
    min-height: 60vh;
    position: relative;
  }

  .content {
    width: 950px;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    .button {

      i {
        transform: rotate(180deg);
      }

    }

  }

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-404 {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 220px;
        height: 200px;
        bottom: -54px;
        right: -120px;
      }

      .after {
        width: 180px;
        height: 160px;
        bottom: 0;
        left: -100px;
      }

    }

    .content {
      width: 700px;
    }

  }

}

@media (max-width: 991px) {

  .section-404 {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 180px;
        height: 180px;
        bottom: -42px;
        right: -90px;
      }

      .after {
        width: 160px;
        height: 100px;
        left: -80px;
      }

    }

  }

}

@media (max-width: 767px) {

  .section-404 {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 100px;
        height: 100px;
      }

      .before {
        width: 180px;
        height: 180px;
        bottom: -42px;
        right: -90px;
      }

      .after {
        width: 160px;
        height: 100px;
        left: -80px;
      }

    }

    .container {
      min-height: auto;
    }

    .content {
      width: auto;
      transform: none;
      position: static;

      margin: 100px 20px;

    }

  }

}


@media (max-width: 600px) {

  .section-404 {

    .st-inner {

      &:before {
        width: 80px;
        height: 80px;
      }

      &:after {
        width: 80px;
        height: 80px;
      }

      .before {
        width: 160px;
        height: 160px;
        bottom: -42px;
        right: -80px;
      }

      .after {
        width: 140px;
        height: 100px;
        left: -70px;
      }

    }

    .content {

      margin: 60px 0 30px;

      .h1 {
        font-size: 30px;
      }

    }

  }

}