@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?85260939ab');
  src: url('../font/fontello.eot?85260939ab#iefix') format('embedded-opentype'),
  url('../font/fontello.woff2?85260939ab') format('woff2'),
  url('../font/fontello.woff?85260939ab') format('woff'),
  url('../font/fontello.ttf?85260939ab') format('truetype'),
  url('../font/fontello.svg?85260939ab#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?85260939#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-right:before { content: '\5d'; } /* ']' */
.icon-down-small:before { content: '\e01b'; } /* '' */
.icon-down:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-heart:before { content: '\e803'; } /* '' */
.icon-up-small:before { content: '\e805'; } /* '' */
.icon-up:before { content: '\e807'; } /* '' */
.icon-left:before { content: '\e80b'; } /* '' */
.icon-left-small:before { content: '\e812'; } /* '' */
.icon-right-small:before { content: '\e813'; } /* '' */
.icon-cancel:before { content: '\e82d'; } /* '' */
.icon-sliders:before { content: '\e849'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */