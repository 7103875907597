.tooltip_templates {
  display: none !important;
}

.section-finder {

  max-width: 2500px;

  .map-toggles {
    display: none;
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }

  .col-left,
  .col-right {
      width: 50%;
      float: left;
  }

  .col-left {
    background: @white;
    transition: all 0.3s ease-in-out;
  }

  .col-right {

    float: right;
    position: relative;
    z-index: 10;
    transition: all 0.3s ease-in-out;

    .toggle-map {

      display: block;
      background: @white;
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 200;

      font-size: 40px;
      padding: 20px 20px 20px 10px;
      border-radius: 0 80px 80px 0;

      i {
        display: inline-block;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        opacity: 1 !important;
      }

    }

  }

  .finder-map-wrapper {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;
  }

  .finder-gmap {
      height: calc(100vh - 80px);
  }

  @media (max-width: 550px) {

    .finder-map-wrapper {
      top: 65px;
    }

    .finder-gmap {
      height: calc(100vh - 65px);
    }

  }


  &.fullscreen {

    .col-left {
      width: 0;
    }

    .col-right {

      width: 100%;

      .toggle-map {

        opacity: 1 !important;

        i {
          transform: rotate(180deg);
        }

        &:hover {
          opacity: 1 !important;
        }

      }

    }

  }


  .col-left {

    .box-left {
      width: 280px;
      float: left;
      padding: 30px 15px 30px 30px;
    }

    .box-right {
      width: calc(100% - 280px);
      float: left;
      padding: 30px 30px 30px 30px;
    }

    .breadcrumbs{

      font-size: 14px;
      margin-bottom: 30px;

      & > .spacer {
        margin: 0 1rem;
      }
    }

    .sorting-container {

      width: 100%;
      margin-bottom: 30px;

      .select-box {
        width: 220px;
        float: right;
      }

      .frm-select {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 36px;
        border: 2px solid @black;
        border-radius: 60px;
        background-color: @white;
        padding: 0 15px;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right .75rem center;
        background-size: 16px 12px;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

    }

  }

  .ng2-nouislider {

    margin: 30px 0 50px;

    .noUi-target {
      background: #e2e2e2;
      border-radius: 5px;
      border: none;
      box-shadow: none;
    }

    .noUi-horizontal {

      height: 5px;

      .noUi-handle {
        width: 10px;
        height: 26px;
        right: -5px;
        top: -10px;

        background-color: @black;
        border-color: @black;
        box-shadow: none;
        border-radius: 8px;

        &:before,
        &:after {
          display: none;
        }

      }

    }

    .noUi-connect {
      background: @black;
    }

    .noUi-pips {
      color: @black;
    }

    .noUi-pips-horizontal {
      padding: 12px 0 0 0;
      height: 50px;

      .noUi-marker-large {
        height: 12px;
        background-color: @black;
      }

    }

    .noUi-marker-normal {
      opacity: 0;
    }

    .noUi-value {
      color: @black;
      font-size: 14px;
      .f2;
    }

  }

  .noUi-customlabel {
    font-size: 14px;
    text-align: right;
    margin-right: -13px;
  }

  .filter-layer {
    position: relative;
  }

  .filter-layer-actions {

    display: none;

    .toggle-open {
      display: block;
      position: fixed;
      bottom: 40px;
      left: 25px;
      z-index: 100;
      transition: all 0.3s ease 0s;
    }

    .toggle-close {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 20;
    }

  }

  .finder-filters {

    .filter-box {
      margin-bottom: 15px;
    }

    .filter-title {
      display: inline-block;
      position: relative;

      .infotip {
        position: absolute;
        top: -10px;
        right: -20px;
      }

    }

    .filter-toggle {
      display: inline-block;
      line-height: 1.2em;
      padding: 8px 20px;
      border: 2px solid @black;
      border-radius: 30px;

      position: relative;

      .number-selected {
        margin-left: 6px;
      }

      &.active {
        background-color: @black;
        color: @white;
      }

    }

    .filter-options {

      display: none;
      padding: 15px 20px;

      label {

        display: block;
        padding: 2px 0 2px 30px;
        position: relative;
        font-size: 15px;
        line-height: 1.25em;

        cursor: pointer;
        margin-bottom: 8px;


        &:before {
          display: block;
          content: "";
          width: 18px;
          height: 18px;

          background-image: url("../img/svg/form/check-off.svg");
          background-size: contain;
          background-repeat: no-repeat;

          position: absolute;
          top: 2px;
          left: 0;
        }

        input {
          margin-right: 5px;
          display: none;
        }

        &.checked {

          &:before {
            background-image: url("../img/svg/form/check-on.svg");
          }

        }

      }

      .helptext {
        color: @black;
        font-size: 12px;
        padding: 8px 55px 0 17px;
        opacity: 0.8;
      }

      &.open {
        display: block;
      }

    }

  }

  .finder-results {

    .result {

      margin-bottom: 40px;
      overflow: hidden;

      .result-image {
        width: 160px;
        height: 160px;
        object-fit: cover;

        .bgcover;

        float: left;
        margin-right: 40px;

        img {
          display: none;
        }

      }

      .result-info {
        width: calc(100% - 200px);
        float: left;
      }

      .box {
        padding-left: 30px;
        position: relative;

        .btn-favorite {
          position: absolute;
          top: 0;
          left: 0;
        }

      }

      .title {
        margin: 0;
      }

      .subtitle {
        display: block;
      }

      .info {

        font-size: 14px;
        line-height: 1.4em;
        margin: 15px 0;

        p {

          &:last-child {
            margin-bottom: 0;
          }

        }

      }

      .distance {
        font-size: 14px;
        font-style: italic;
        opacity: 0.7;
      }

    }

  }

  .gm-style .gm-style-iw-c {

    padding: 0 !important;
    border-radius: 0 !important;

  }

  .gm-style-iw {
    min-width: 300px !important;
    max-width: 360px !important;
    min-height: 150px;
  }

}



.popup-box {

  width: 360px;
  padding: 25px 20px 20px;
  position: relative;

  .popup-close {
    display: block;
    width: 20px;
    height: 20px;

    background-color: @white;

    font-size: 13px;
    overflow: hidden;

    position: absolute;
    top: 5px;
    right: 0px;
    z-index: 100;

    i {
      opacity: 1;
    }


    &:hover {
      opacity: 1;
      color: @purple;
    }

  }

  .location-image {
      width: 100%;
      max-width: 100%;
      pointer-events: none;
  }

  .location-info {

  }

  .location-header {

    padding: 15px 0;
    position: relative;

    .btn-favorite {
      font-size: 18px;
      position: absolute;
      top: 40px;
      left: 0;
    }

    .title {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 5px;
    }

    .meta {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 30px;
    }

  }

  .location-address {
    padding-left: 30px;
    font-size: 15px;
    line-height: 1.4em;
  }


}

@media (max-width: 500px) {

  .popup-box {
    width: 100%;
  }

}

@media (max-width: 460px) {

  .section-finder {

    .gm-style-iw {
      min-width: 280px !important;
      max-width: 300px !important;
    }

  }

  .popup-boxxx {
    width: 280px;
  }

}

@media (max-width: 415px) {

  .popup-boxxx {
    width: 250px;
  }

}



@media (max-width: 1480px) {

  .section-finder {

    .col-left {

      .filter-layer-actions {
        display: block;
      }

      .filter-layer {

        position: fixed;
        width: 360px;
        height: calc(100% - 80px);
        top: 80px;
        left: -370px;
        z-index: 400;

        padding: 40px 25px;
        background: @white;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);

        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        transition: all 0.5s ease 0s;

        &.open {
          left: 0;

          .toggle-open {
            opacity: 0;
            left: -20px;
            pointer-events: none;
          }

        }

      }

      .box-right {
        width: 100%;
        float: none;
        padding: 30px 30px 30px 30px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-finder {

    .col-right {

      .toggle-map {
        font-size: 36px;
        padding: 15px 15px 15px 10px;
      }

    }

    .finder-results {

      .result {

        .result-image {
          width: 140px;
          height: 120px;
          margin-right: 30px;
        }

        .result-info {
          width: calc(100% - 170px);
        }

      }

    }

  }

}

@media (max-width: 991px) {


}



@media (max-width: 550px) {

  .section-finder {

    .col-left {

      .filter-layer {
        height: calc(100% - 65px);
        top: 65px;
      }

    }

    .finder-gmap {
      height: calc(100vh - 65px);
    }

  }

}

@media (max-width: 470px) {

  .section-finder {

    .finder-results {

      .result {

        .result-image {
          float: none;
          width: 100%;
          height: 220px;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .result-info {
          width: 100%;
          float: none;
        }

      }

    }

  }

}
