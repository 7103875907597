.section-category-header {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/category/std/1.svg);
      background-position: top left;
      background-repeat: no-repeat;

    }

    &:after {
      width: 300px;
      height: 300px;
      top: 0;
      right: 0;

      background-position: top right;
      background-repeat: no-repeat;

    }

  }

  .st-content {

    width: 50%;
    max-width: 630px;
    margin: 100px 0 100px 42%;
    position: relative;
    z-index: 20;

    &:before {
      display: block;
      content: "";
      position: absolute;

      background-position: center top;
      background-repeat: no-repeat;

      width: 120px;
      height: 120px;
      top: 0;
      left: -160px;
    }

  }

  .school-filter {

    position: relative;

    .help {
      position: absolute;
      bottom: -50px;
      font-size: 15px;
      line-height: 1.2;
    }

    &.type-b {

      label {

        display: block;
        margin-bottom: 5px;

        input {
          margin-right: 8px;
        }

      }

      .button {
        position: absolute;
        bottom: 0;
        left: 140px;
      }

    }

    .frm-checkbox {

      display: block;
      padding: 2px 0 2px 30px;
      position: relative;
      font-size: 15px;
      line-height: 1.25em;

      cursor: pointer;
      margin-bottom: 8px;

      &:before {
        display: block;
        content: "";
        width: 18px;
        height: 18px;

        background-image: url("../img/svg/form/check-off.svg");
        background-size: contain;
        background-repeat: no-repeat;

        position: absolute;
        top: 2px;
        left: 0;
      }

      input {
        margin-right: 5px;
        display: none;
      }

      &.checked {

        &:before {
          background-image: url("../img/svg/form/check-on.svg");
        }

      }

    }

  }

}


@media (max-width: 1480px) {

  .section-category-header {

    .st-inner {

      &:before {
        width: 180px;
        height: 180px;
      }

      &:after {
        width: 260px;
        height: 260px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-category-header {

    .st-content {
      width: 65%;
      margin: 80px 0 80px 35%;

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

        &:after {
          width: 240px;
          height: 240px;
        }

      }

    }

  }

}

@media (max-width: 991px) {

  .section-category-header {

    .st-inner {

      padding-bottom: 0;

      &:before {
        display: none;
      }

      &:after {
        width: 180px;
        height: 180px;
      }

    }

    .st-content {
      width: 65%;
      margin: 40px 0 40px 35%;
    }

  }

}

@media (max-width: 767px) {

  .section-category-header {

    .st-inner {

      padding: 80px 0 20px;

      &:after {
        width: 160px;
        height: 160px;
      }

    }

    .st-content {
      width: 100%;
      margin: 0;

      &:before {
        display: none;
      }

    }

  }

}

@media (max-width: 600px) {

  .section-category-header {

    .st-inner {

      &:after {
        width: 120px;
        height: 120px;
      }

    }

  }

}





.section-category-article {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      bottom: 0;
      left: 0;

      background-position: bottom left;
    }

  }

  .col-left {
    width: 60%;
    float: left;

    min-height: calc(450px - 80px);
  }

  .col-right {
    width: 40%;
    float: right;
    position: relative;

    .st-image {
      width: 792px;
      height: 450px;

      position: absolute;
      top: -80px;
      left: 60px;

      .bgcover;

      img {
        display: none;
      }

      .overlay {
        display: block;
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 4px);
        position: absolute;
        top: -2px;
        left: -2px;

        background-image: url(../img/svg/category/std/foto-overlay.svg);
        background-size: auto 100%;
        background-position: top left;
        background-repeat: no-repeat;

      }

    }

  }

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-category-article {

    .st-inner {
      padding-bottom: 160px;

      &:before {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-category-article {

    .st-inner {
      padding-bottom: 140px;

      &:before {
        width: 120px;
        height: 120px;
      }

    }

    .col-left {
      width: 100%;
      float: none;

      min-height: unset;
    }

    .col-right {

      width: 100%;
      float: none;
      margin-bottom: 40px;

      .st-image {

        width: 100%;
        height: 400px;
        position: static;

        img {
          display: none;
        }

        .overlay {
          display: none;
        }

      }

    }

  }

}

@media (max-width: 767px) {

  .section-category-article {

    .st-inner {

    }

    .col-right {

      .st-image {
        height: 360px;
      }

    }

  }

}


.section-category-article-b {

  position: relative;
  z-index: 20;

  .st-inner {

    padding: 160px 0 100px;

    &:before {
      width: 180px;
      height: 180px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/school/std/4.svg);
      background-position: top left;

    }

    &:after {
      width: 160px;
      height: 160px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/school/std/9.svg);
      background-position: bottom left;

    }

  }

  .col-small {
    max-width: 680px;
  }

  .col-medium {
    max-width: 900px;
  }


  .col-left {
    width: 60%;
    float: left;
    padding-right: 100px;
  }

  .article-extra {
    margin-top: 60px;
  }


  .col-right {

    width: 40%;
    float: left;
    position: relative;

    .st-image {

      position: relative;

      &.has-before {

        &:before {
          width: 100px;
          height: 100px;
          top: 0;
          right: 0;

          background-image: url(../img/svg/school/std/5.svg);
          background-size: contain;
          background-position: top right;
          background-repeat: no-repeat;

        }

      }

      &.has-after {

        &:after {
          width: 100px;
          height: 100px;
          bottom: 0;
          right: 0;

          background-image: url(../img/svg/school/std/11.svg);
          background-size: contain;
          background-position: bottom right;
          background-repeat: no-repeat;
        }

      }

    }

  }

}

@media (max-width: 1280px) {

  .section-category-article-b {

    .st-inner {

      &:before {
        width: 140px;
        height: 140px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-category-article-b {

    .col-left {
      width: 100%;
      float: none;
      padding: 0;
      margin-bottom: 60px;
    }

    .col-right {
      width: 100%;
      float: none;
    }

  }

}


.section-category-text {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/school/std/6.svg);
      background-position: top left;
    }

    &:after {
      width: 160px;
      height: 160px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/school/std/7.svg);
      background-position: bottom right;
    }

  }

}

.section-category-cta {

  .st-inner {

    padding: 120px 0;

    &:before {
      width: 200px;
      height: 200px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/category/std/9.svg);
      background-position: bottom right;

    }

  }

  .container {

  }

  .blocks {

    margin-top: -20px;
    width: 720px;
    margin: 0 auto;
    overflow: hidden;

    &.has-single-block {
      text-align: center;
    }

    .block {
      margin-top: 20px;
      width: 50%;
      float: left;
      padding: 0 20px;
    }

    .single-block {
      display: inline-block;
      text-align: left;
      position: relative;

      padding-right: 200px;

      .button {
        position: absolute;
        right: 0;
        bottom: 30px;

        &.v-middle {
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
        }

      }

    }

  }

}

@media (max-width: 1480px) {

  .section-category-cta {

    .st-inner {
      padding: 100px 0;
    }

  }

}

@media (max-width: 1280px) {

  .section-category-cta {

    .st-inner {
      padding: 80px 0;
    }

  }

}

@media (max-width: 991px) {

  .section-category-cta {

    .st-inner {



      &:before {
        width: 160px;
        height: 160px;
        bottom: 0;
      }

    }

    .blocks {

      width: auto;
      margin: 0 80px 0 0;

      .block {
        padding: 0;
      }

    }

  }

}

@media (max-width: 680px) {

  .section-category-cta {

    .st-inner {
      padding: 50px 0 90px;
    }

    .blocks {

      width: 100%;
      margin: 0;
      text-align: center;

      .block {

        float: none;
        width: auto;
        display: inline-block;
        padding: 30px;

      }

    }

  }

}


@media (max-width: 480px) {

  .section-category-cta {

    .blocks {

      .single-block {

        padding-right: 0;

        .split-title {
          margin-bottom: 20px;
        }

        .button {

          position: static;

          &.v-middle {
            transform: translateY(0);
          }

        }

      }

    }

  }

}




.section-category-articles {

  .st-inner {

  }

  .st-header {
    margin-bottom: 60px;
  }

}


@media (max-width: 1480px) {

  .section-category-articles {

    .st-inner {

    }

    .st-header {
      margin-bottom: 40px;
    }

  }

}

@media (max-width: 1280px) {

  .section-category-articles {

    .st-header {
      margin-bottom: 20px;
    }

  }

}

@media (max-width: 991px) {

  .section-category-articles {

    .st-inner {
      padding: 60px 0 90px;
    }

  }

}




.section-category-faq {

  .st-inner {

    padding: 120px 0;

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/category/std/5.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/category/std/6.svg);
      background-position: top right;
    }

    .before {
      width: 200px;
      height: 200px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/category/std/7.svg);
      background-position: bottom left;
    }

  }

  .container {
    min-height: 300px;
  }

  .col-left {
    width: 50%;
    float: left;
      padding-right: 15px;
  }

  .col-right {
    width: 50%;
    float: left;
    padding: 0 160px 0 40px;
  }

}


@media (max-width: 1480px) {

  .section-category-faq {

    .st-inner {

      &:before {
        width: 180px;
        height: 180px;
      }

      &:after {
        width: 180px;
        height: 180px;
      }

      .before {
        width: 180px;
        height: 180px;
      }

    }

    .col-right {
      padding: 0 0 0 40px;
    }

  }

}

@media (max-width: 1280px) {

  .section-category-faq {

    .st-inner {

      padding: 160px 0 200px;

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 160px;
        height: 160px;
      }

      .before {
        width: 160px;
        height: 160px;
      }

    }

    .col-left {
      clear: both;
      width: 100%;
      float: none;
      margin-bottom: 60px;
    }


    .col-right {
      clear: both;
      width: 100%;
      float: none;
      padding: 0;
    }

  }

}

@media (max-width: 991px) {


}

@media (max-width: 767px) {

  .section-category-faq {

    .st-inner {

      padding: 160px 0 180px;

      &:before {
        width: 140px;
        height: 140px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

      .before {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 560px) {

  .section-category-faq {

    .st-inner {

      padding: 160px 0 140px;

      &:before {

      }

      &:after {

      }

      .before {
        width: 100px;
        height: 100px;
      }

    }

  }

}





.theme-ko {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/ko/1.svg);
      }

    }

  }

  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/ko/foto-overlay.svg);
        }

      }

    }

  }


  .section-category-article-b {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/ko/8.svg);
      }

      &:after {
        background-image: url(../img/svg/category/ko/9.svg);
      }

    }

    .col-right {

      .st-image {

        &.has-after {

          &:after {
            background-image: url(../img/svg/category/ko/10.svg);
          }

        }

      }

    }

  }

  @media (max-width: 767px) {

    .section-category-article-b {

      .st-inner {

        padding-top: 0;

        &:before {
          display: none;
        }

      }

    }

  }


  .section-category-text {

    .st-inner {

      &:before {
        display: none;
      }

      &:after {
        width: 100px;
        height: 200px;
        background-image: url(../img/svg/category/ko/6.svg);
      }

    }

  }

  @media (max-width: 1480px) {

    .section-category-text {

      .st-inner {

        &:after {
          width: 90px;
          height: 200px;
        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-category-text {

      .st-inner {

        &:after {
          width: 80px;
          height: 200px;
        }

      }

    }

  }

  @media (max-width: 767px) {

    .section-category-text {

      .st-inner {

        padding-bottom: 180px;

        &:after {
          width: 80px;
          height: 200px;
        }

      }

    }

  }


  .section-category-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/ko/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/ko/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/ko/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/ko/7.svg);
        width: 240px;
        left: -60px;
      }

    }

  }

}

.theme-bo {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/bo/1.svg);
      }

    }

  }

  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/bo/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/bo/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/bo/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/bo/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/bo/7.svg);
      }

    }

  }

}

/*  */
.theme-vo {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/vo/1.svg);
      }

    }

  }

  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/vo/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/vo/9.svg);
      }

    }

  }

  @media (max-width: 1480px) {

    .section-category-cta {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
        }

      }

    }

  }


  @media (max-width: 1280px) {

    .section-category-cta {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-category-cta {

      .st-inner {

        &:before {
         width: 140px;
          height: 140px;
        }

      }

    }

  }

  @media (max-width: 680px) {

    .section-category-cta {

      .st-inner {
        padding-bottom: 120px;

        &:before {
          width: 120px;
          height: 120px;
        }

      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/vo/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/vo/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/vo/7.svg);
      }

    }

  }

}

.theme-mbo {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/mbo/1.svg);
      }

    }

  }

  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/mbo/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-cta {

    .st-inner {

      &:before {
        // TODO: Found this broken
        // background-image: url(../img/svg/category/mbo/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/mbo/5.svg);
      }

      &:after {
        width: 120px;
        height: 120px;
        background-image: url(../img/svg/category/mbo/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/mbo/7.svg);
      }

    }

  }

}

.theme-hbo {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/hbo/1.svg);
      }

      &:after {
        width: 260px;
        height: 260px;
      }

    }

  }

  @media (max-width: 1480px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 230px;
          height: 230px;
        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 200px;
          height: 200px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 140px;
          height: 140px;
        }

      }

    }

  }

  @media (max-width: 620px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 100px;
          height: 100px;
        }

      }

    }

  }



  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/hbo/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-cta {

    .st-inner {

      &:before {
        // TODO: Found this broken
        //background-image: url(../img/svg/category/hbo/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/hbo/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/hbo/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/hbo/7.svg);
      }

    }

  }

}



.theme-so {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/so/1.svg);
      }

    }

  }

  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/so/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-text {

    .st-inner {

      &:before {
        display: none;
      }

      &:after {
        height: 240px;
        background-image: url(../img/svg/category/so/5.svg);
      }

    }

  }

  @media (max-width: 1280px) {


  }

  @media (max-width: 991px) {

    .section-category-text {

      .st-inner {

        &:after {
          width: 100px;
          height: 200px;
        }

      }

    }

  }

  @media (max-width: 880px) {


    .section-category-text {

      .st-inner {

        padding-bottom: 120px;

        &:after {
          width: 80px;
          height: 160px;
          bottom: -70px;
        }

      }

    }

  }


  .section-category-cta {

    .st-inner {

      &:before {
        //background-image: url(../img/svg/category/so/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/so/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/so/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/so/7.svg);
      }

    }

  }

}


.theme-io {

  .section-category-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/io/1.svg);
      }

      &:after {
        width: 260px;
        height: 260px;
      }

    }

  }

  @media (max-width: 1480px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 230px;
          height: 230px;
        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-category-header {

      .st-inner {

        &:before {
          width: 150px;
          height: 150px;
        }

        &:after {
          width: 200px;
          height: 200px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 180px;
          height: 180px;
        }

      }

    }

  }

  @media (max-width: 650px) {

    .section-category-header {

      .st-inner {

        &:after {
          width: 120px;
          height: 120px;
        }

      }

    }

  }


  .section-category-article {

    .col-right {

      .st-image {

        .overlay {
          background-image: url(../img/svg/category/io/foto-overlay.svg);
        }

      }

    }

  }

  .section-category-cta {

    .st-inner {

      &:before {
        //background-image: url(../img/svg/category/io/9.svg);
      }

    }

  }

  .section-category-faq {

    .st-inner {

      &:before {
        background-image: url(../img/svg/category/io/5.svg);
      }

      &:after {
        background-image: url(../img/svg/category/io/6.svg);
      }

      .before {
        background-image: url(../img/svg/category/io/7.svg);
      }

    }

  }

}
