
@blue_m 	: #316094;
@blue_s 	: #7592b3;

@orange 	: #f39933;
@orange_m 	: #f7cdb5;
@orange_s 	: #fdf0e5;

@purple 		: #8b539c;
@purple_m	: #d9c6df;
@purple_s 	: #f3edf5;

@sky 		: #70abd9;
@sky_l 		: #c6ddf0;
@sky_m 		: #c6ddf0;
@sky_s 		: #e3eef8;


@pink_m		: #f6aed5;
@pink_s		: #fce6f2;


@green_m 	: #edf0c1;
@green_s 	: #f9fbe7;



@dgreen		: #0c5a55;
@yellow 	: #f7d62d;


@blue 		: #68A8DD;
@blue2 		: #0065A7;
@blue3		: #002957;
@blue4 		: #001F41;



@green 		: #80bc00;
@green2 	: #4c7c33;
@green3 	: #799d66;

@grey 	: #d8dada;
@grey2 	: #3c4444;
@grey3 	: #282e2e;
@grey4 	: #a9abab;
@grey5 	: #D5D5D5;

@baby 	: #e8f2fa;
@baby2 	: #cae2f5;

@mint	: #0a939f;
@red 	: #DD271C;

@oker 	: #F7D62D;

@color_ko : #F28700;
@color_bo : #4FA058;
@color_vo : #7C2D7F;

@color_mbo : #D82B84;
@color_hbo : #69aadc;
@color_vavo: #27348B;

@color_so : #E02D16;
@color_io : #F7D62D;


@pink 		: #D82B84;
@green		: #217A3D;
@blue		: #69aadc;
@purple		: #7C2D7F;

@yellow 	: #F7D62D;
@orange 	: #F28700;

@white 	: #fff;
@black 	: #000;
@light 	: #d8dada;
@dark 	: #05334B;

@grey_l : #f5f5f5;
@grey_m : #fafafa;


@font1 	: 'TheSansPlain', sans-serif;
@font2 	: 'TheSansBold', sans-serif;
@font3 	: 'CoreSansM55Medium', sans-serif;
@font4 	: 'CoreSansM65Bold', sans-serif;


@gap1 	: 80px;
@textgap : 40px;
@radius : 30px;


@hd_lg : 60px;
@hd_md : 40px;
@hd_sm : 30px;

@fontsize_xl : 30px;
@fontsize_lg : 25px;
@fontsize_md : 20px;
@fontsize_sm : 18px;

/* uitgangspunten mozaic */
@mzw_lg : 120px;
@mzw_md : 103px;
@mzw_sm : 80px;
@mzw_xs : 70px;


.absolute {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.before-dark {

	display: block;
	content: "";

	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	background: rgba(0, 0, 0, 1);

	opacity: 0.2;

}

.bgcover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.bgcontain {
	background-size: contain;
	background-position: center bottom;
	background-repeat: no-repeat;
}
