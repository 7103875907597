.main-content {
	min-height: 80vh;
}

.comp-logo {

	display: block;
	width: 180px;
	height: 60px;

	background: url(../img/scholenwijzer-logo.svg);
	background-size: contain;
	background-repeat: no-repeat;
}

.site-wrapper {
	overflow: hidden;
	padding-top: 80px;

	&.map {
		overflow: visible;
	}

	&.no-pt {
		padding-top: 0;
	}

}

.site-header {

	display: block;
	width: 100%;

	position: fixed;
	top: 0;
	left: 0;

	z-index: 900;
	background: @white;
	border-bottom: 1px solid rgba(0,0,0,0.2);

	transition: all 0.3s ease-in-out;

	.container {
		height: 80px;
		transition: all 0.3s ease-in-out;
		position: relative;
	}

	.comp-logo {

		width: 320px;
		height: 40px;

		position: absolute;
		top: 50%;
		left: 25px;

		z-index: 5;

		transform: translate(0, -50%);
		transition: all 0.1s ease-out;

		&:hover {
			opacity: 1;
			transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
			transform: translate(3px, -50%);
		}

	}


	.c-search {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.whishlist {

		font-size: 22px;
		position: absolute;
		top: 50%;
		right: 125px;
		transform: translateY(-50%);

		transition: all 0.3s ease-in-out;

		.heart {
			font-size: 18px;
		}

		.text {
			display: inline-block;
			padding-left: 5px;
		}

		&.off-screen {
			right: 100px;
			opacity: 0;
		}

	}

	.toggle-search-open {

		font-size: 36px;

		position: absolute;
		top: 50%;
		right: 25px;
		transform: translateY(-50%);

		transition: all 0.3s ease-in-out;

		&.off-screen {
			right: 50px;
			opacity: 0;
		}

	}

	.nav-toggle {
		display: none;
	}

	.scrolled &,
	.shownav & {

		background: @white;
		box-shadow: 0 0 5px rgba(0,0,0,0.2);

		.container {
			height: 80px;
		}


	}

}

@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

	.site-header {

		.container {
			padding: 0;
			width: 100%;
		}

	}

}

@media (max-width: 1150px) {

	.site-header {

		.whishlist {

			right: 80px;

			.heart {
				font-size: 28px;
			}

			.text {
				display: none;
			}

			.arrow {
				font-size: 28px;
			}

		}

	}

}

@media (max-width: 991px) {

	.site-header {

		.container {
			padding: 0;
			width: 100%;
		}

		.c-search {
			display: none;
		}

		.toggle-search-openxx {
			display: none;
		}

		.whishlist {
			right: 80px;
		}

		.nav-togglexx {
			display: block;
		}

	}

}

@media (max-width: 550px) {

	.site-wrapper {
		padding-top: 65px;
	}

	.tpl-finder {

		.site-wrapper {
			padding-top: 65px;
		}

	}

	.site-header {

		.container {
			height: 65px;
		}

		.whishlist {

			.arrow {
				display: none;
			}

		}

		.comp-logo {
			width: 240px;
			height: 30px;
		}

		.scrolled &,
		.shownav & {

			.container {
				height: 65px;
			}

		}

	}

}

.site-footer {

	position: relative;
	background: @white;

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 50px;
		background: @oker url(../img/bg-footer.svg) center center;
		background-size: cover;
	}

	.denhaag-logo {

		display: block;

		width: 270px;
		height: 90px;

		background: url(../img/gemeente-denhaag-logo.svg) top left no-repeat;
		background-size: contain;

	}

	.primary {

		padding: 30px 0;

		.inner {
			overflow: hidden;
		}

		.footer-nav {

			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);

			li {
				display: inline-block;
				margin-left: 30px;
			}

			a {
				display: block;
				color: @black;
				.f2;
				text-transform: lowercase;
			}

		}

	}

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

	.site-footer {

		.denhaag-logo {

			width: 220px;
			height: 80px;

		}

		.primary {

			.footer-nav {

				top: 43%;

				li {
					margin-left: 20px;
				}

			}

		}

	}

}

@media (max-width: 991px) {

	.site-footer {

		&:before {
			height: 40px;
		}

		.denhaag-logo {

		}

		.primary {

			.footer-nav {

				width: calc(100% - 270px);
				text-align: right;

				li {
					margin-left: 0;
					margin-right: 20px;
				}

				a {
					line-height: 2em;
				}

			}

		}

	}

}

@media (max-width: 650px) {

	.site-footer {

		&:before {
			height: 35px;
		}

		.denhaag-logo {
			width: 200px;
			height: 70px;
		}

		.primary {

			.footer-nav {

				width: calc(100% - 250px);

				li {
					margin-left: 0;
					margin-right: 20px;
				}

			}

		}

	}

}

@media (max-width: 600px) {

	.site-footer {

		&:before {
			height: 30px;
		}

		.denhaag-logo {
			width: 240px;
			height: 80px;
			margin-bottom: 30px;
		}

		.primary {

			.footer-nav {

				position: static;
				top: 0;
				right: 0;
				transform: none;
				text-align: left;

				li {
					margin-left: 0;
					margin-right: 0;
					display: block;
					float: none;
				}

				a {
					display: block;
					line-height: 2em;
				}

			}

		}

	}

}
