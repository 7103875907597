.section-articles-intro {

  .st-inner {

    &:before {
      width: 220px;
      height: 220px;
      top: 0;
      left: 0;

      z-index: 100;

      background-image: url(../img/svg/artikelen/1.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 360px;
      top: 0;
      right: 0;

      z-index: 5;

      background-image: url(../img/svg/artikelen/2.svg);
      background-position: top right;
    }

  }

  .col-right {

    position: relative;
    z-index: 10;

    .inner {
      padding-right: 150px;
    }

  }

}

@media (max-width: 1480px) {

  .section-articles-intro {

    .st-inner {

      &:before {
        width: 200px;
        height: 200px;
      }

      &:after {
        width: 180px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-articles-intro {

    .st-inner {

      &:before {
        width: 180px;
        height: 180px;
      }

      &:after {
        width: 160px;
      }

    }

    .col-right {

      .inner {
        padding-right: 0;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-articles-intro {

    .st-inner {

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 160px;
        z-index: 100;
      }

    }

    .col-left {
      margin-bottom: 60px;
    }

  }

}

@media (max-width: 767px) {

  .section-articles-intro {

    .st-inner {

      &:before {
        width: 200px;
        height: 200px;
        left: -100px;
      }

      &:after {
        width: 140px;
      }

    }

    .col-left {
      margin-bottom: 60px;
    }

  }

}



.section-article-grid {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/artikelen/5.svg);
      background-position: bottom left;

    }

    &:after {
      width: 160px;
      height: 160px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/artikelen/3.svg);
      background-position: top right;
    }

    .before {
      width: 240px;
      height: 240px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/artikelen/4.svg);
      background-position: bottom right;
    }

  }


  .st-header {
    margin-bottom: 60px;
  }

  .col-item {
    margin-bottom: 60px;
  }

}


@media (max-width: 1480px) {

  .section-article-grid {

    .st-inner {

      &:before {
        width: 180px;
        height: 180px;
      }

      .before {
        width: 220px;
        height: 220px;
      }

    }

    .st-header {
      margin-bottom: 40px;
    }

  }

}

@media (max-width: 1280px) {

  .section-article-grid {

    .st-inner {

      &:before {
        width: 160px;
        height: 160px;
      }

      .before {
        width: 200px;
        height: 200px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

    }

    .st-header {
      margin-bottom: 20px;
    }

  }

}

@media (max-width: 991px) {

  .section-article-grid {

    .st-inner {

      padding-top: 80px;

      .before {
        width: 180px;
        height: 180px;
      }

      &:after {
        width: 120px;
        height: 120px;
      }

    }

  }

}

@media (max-width: 640px) {

  .section-article-grid {

    .st-inner {

      .before {
        display: none;
      }

    }

  }

}


@media (max-width: 520px) {

  .section-article-grid {

    .col-item {
      width: 100%;
      float: none;
    }

  }

}




.section-article-a {

  .st-inner {

    &:before {
      width: 180px;
      height: 180px;
      top: 0;
      left: 0;

      z-index: 100;

      background-image: url(../img/svg/artikel/1.svg);
      background-position: top left;

    }

    &:after {
      width: 300px;
      height: 220px;
      top: 0;
      right: -150px;
      z-index: 100;

      background-image: url(../img/svg/artikel/2.svg);
      background-position: top right;

    }

  }

  .col-left {

    .inner {

      position: relative;

      .st-image {

        .bgcover;

        position: absolute;
        top: 0;
        right: 50px;
        bottom: 0;
        left: -200px;

        img {
          display: none;
        }

      }

    }

  }

  .col-right {

    .inner {

      position: relative;
      max-width: 550px;
      padding-top: 120px;
      padding-bottom: 120px;

    }

  }

  &.text-only {

    .col-right {

      .inner {

        max-width: none;
        padding-top: 180px;
        padding-bottom: 120px;

      }

    }

  }

}

@media (max-width: 1480px) {

  .section-article-a {

    .st-inner {

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 250px;
        right: -125px;
      }

    }

    .col-left {

      .inner {

        .st-image {
          left: -200px;
          right: 0;
        }

      }

    }

  }

}

@media (max-width: 1280px) {

  .section-article-a {

    .st-inner {

      &:before {
        width: 140px;
        height: 140px;
      }

      &:after {
        width: 220px;
        right: -110px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-article-a {

    .col-left {

      .inner {

        .st-image {

          position: static;

          img {
            display: block;
          }

        }

      }

    }

    .col-right {

      .inner {

        max-width: none;
        padding-top: 60px;
        padding-bottom: 80px;

      }

    }

  }

}

@media (max-width: 767px) {

  .section-article-a {

    .st-inner {

      &:before {
        width: 100px;
        height: 100px;
      }

      &:after {
        width: 160px;
        right: -80px;
      }

    }

    &.text-only {

      .col-right {

        .inner {
          padding-top: 130px;
        }

      }

    }

  }

}



.section-article-b {

  position: relative;
  z-index: 10;

  .st-inner {

    padding: 100px 0;

    &:before {
      width: 180px;
      height: 180px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/artikel/3.svg);
      background-position: top left;

    }

    &:after {
      width: 180px;
      height: 360px;
      top: -100px;
      right: 0;

      background-image: url(../img/svg/artikel/12.svg);
      background-position: top right;
    }

    .before {
      width: 260px;
      height: 300px;
      bottom: -87px;
      right: 0;

      background-image: url(../img/svg/artikel/5.svg);
      background-position: top right;
    }

  }

}

@media (max-width: 1480px) {

  .section-article-b {

    .st-inner {

      padding: 100px 0;

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        height: 320px;
        top: -80px;
      }

      .before {
        height: 260px;
        bottom: -76px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-article-b {

    .st-inner {

      padding: 120px 0 100px;

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        height: 300px;
        top: -80px;
      }

      .before {
        height: 240px;
        bottom: -70px;
      }

    }

  }

}

@media (max-width: 900px) {

  .section-article-b {

    .st-inner {

      padding: 140px 0 140px;

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        display: none;
      }

      .before {
        height: 200px;
        bottom: -58px;
      }

    }

  }

}


.section-article-c {

  .st-inner {

    &:before {
      width: 240px;
      height: 240px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/artikel/6.svg);
      background-position: top left;

    }

    &:after {
      width: 240px;
      height: 240px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/artikel/7.svg);
      background-position: bottom right;
    }

  }

}

@media (max-width: 1480px) {

  .section-article-c {

    .st-inner {

      &:before {
        width: 220px;
        height: 220px;
      }

      &:after {
        width: 220px;
        height: 220px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-article-c {

    .st-inner {

      &:before {
        width: 200px;
        height: 200px;
      }

      &:after {
        width: 200px;
        height: 200px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-article-c {

    .st-inner {

      padding: 100px 0;

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 180px;
        height: 180px;
      }

    }

  }

}

@media (max-width: 850px) {

  .section-article-c {

    .st-inner {

      padding: 100px 0 40px;

      &:after {
        display: none;
      }

    }

  }

}



.section-article-d {

  .st-inner {

    &:before {
      width: 160px;
      height: 160px;
      top: 0;
      left: 0;
      z-index: 100;

      background-image: url(../img/svg/artikel/8.svg);
      background-position: top left;

    }

    &:after {
      width: 500px;
      height: 320px;
      bottom: 0;
      right: -250px;

      background-image: url(../img/svg/artikel/10.svg);
      background-position: bottom right;
    }

    .before {
      width: 160px;
      height: 160px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/artikel/11.svg);
      background-position: top left;
    }

  }

  .st-image {

    position: relative;

    &.has-before {

      &:before {
        width: 100px;
        height: 200px;
        top: 0;
        right: 0;

        background-image: url(../img/svg/artikel/9.svg);
        background-position: top right;
        background-repeat: no-repeat;

      }

    }

  }

  .col-left {
    float: right;
  }

}

@media (max-width: 1480px) {

  .section-article-d {

    .st-inner {

      &:before {
        width: 140px;
        height: 140px;
      }

      &:after {
        width: 450px;
        right: -225px;
      }

      .before {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-article-d {

    .st-inner {

      padding: 140px 0 160px;

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 400px;
        right: -200px;
      }

      .before {
        width: 120px;
        height: 120px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-article-d {

    .st-inner {

      padding: 60px 0 160px;

      &:after {
        width: 300px;
        right: -150px;
      }

    }

    .col-left {
      margin-bottom: 60px;
    }

  }

}

@media (max-width: 767px) {


}



.section-article-banner {

  .st-inner {
    .bgcover;
  }

  .container {
    height: 300px;
  }

}




.section-opendagen {

  .st-inner {
    padding: 120px 0 200px;

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/opendagen/2.svg);
      background-position: top right;
    }

    .before {
      width: 180px;
      height: 180px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/opendagen/3.svg);
      background-position: bottom right;
    }

    .before-2 {
      width: 160px;
      height: 160px;
      top: 20%;
      right: 0;

      background-image: url(../img/svg/opendagen/5.svg);
      background-position: top right;
    }

    .after {
      width: 200px;
      height: 200px;
      bottom: 30%;
      left: -45px;

      background-image: url(../img/svg/opendagen/4.svg);
      background-position: bottom left;
    }

  }

  .page-header {
    margin-bottom: 80px;
  }

  .list-view-title {
    font-size: 26px;
    margin: 30px 0 15px 0;
  }

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-opendagen {

    .st-inner {

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 160px;
        height: 160px;
      }

      .before {
        width: 140px;
        height: 140px;
      }

      .before-2 {
        width: 120px;
        height: 120px;
      }

      .after {
        width: 200px;
        height: 200px;
        left: -45px;
      }

    }

  }

}

@media (max-width: 1200px) {

  .section-opendagen {

    .st-inner {

      .before-2 {
        display: none;
      }

      .after {
        width: 180px;
        height: 180px;
        left: -45px;
        bottom: 0;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-opendagen {

    .st-inner {

      padding: 80px 0 120px;

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

      .before {
        width: 100px;
        height: 100px;
      }

      .after {
        width: 140px;
        height: 140px;
      }

    }

    .page-header {
      margin-bottom: 60px;
      margin-left: 25px;
    }

    .list-view-title {
      font-size: 22px;
      margin-left: 25px;
    }

  }

}

@media (max-width: 767px) {

  .section-opendagen {

    .c-list-view {

      .list-row {

        .cell {

          width: 50%;
          float: left;

          &.cell-brd {
            border-left: none;
            padding-left: 0;
          }

          &.cell-title {
            width: 100%;
            float: none;
            padding-bottom: 0;
          }

          &.cell-2,
          &.cell-3 {
            padding-top: 0;
            font-size: 80%;
            opacity: 0.8;
          }

        }

      }

    }

  }

}

@media (max-width: 500px) {

  .section-opendagen {

    .st-inner {

      padding: 80px 0 120px;

      &:before {
        display: none;
      }

      .after {
        width: 110px;
        height: 110px;
        left: -25px;
      }

    }

    .page-header {
      margin-left: 10px;
    }

    .list-view-title {
      margin-left: 10px;
    }

    .c-list-view {

      .list-row {

        &.row-pull-left {
          margin-left: -15px;
          margin-right: -15px;
        }

      }

    }

  }

}



.section-page {

  .st-inner {

    padding: 120px 0 200px;

    &:before {
      width: 180px;
      height: 180px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/page/1.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/page/2.svg);
      background-position: top right;
    }

    .before {
      width: 350px;
      height: 300px;
      bottom: 0;
      right: -115px;

      background-image: url(../img/svg/page/3.svg);
      background-position: bottom right;
    }

    .after {
      width: 180px;
      height: 180px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/page/4.svg);
      background-position: bottom left;
    }

  }

}
