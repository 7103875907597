.section-school-header {

  position: relative;
  z-index: 5;

  .st-inner {

    &:before {
      width: 120px;
      height: 200px;
      top: 0;
      right: 0;

      //background-image: url(../img/svg/school/std/2.svg);
      background-position: top right;
    }

    &:after {
      width: 220px;
      height: 220px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/school/std/3.svg);
      background-position: bottom right;
    }

    .after {
      width: 200px;
      height: 200px;
      bottom: -100px;
      left: 0;
      z-index: 300;

      background-position: center left;

    }

  }


  .col-left {

    width: 50%;
    float: left;
    position: relative;

    .overlay {
      display: block;
      width: 100%;
      height: calc(100% + 6px);

      position: absolute;
      top: -3px;
      right: -1px;
      z-index: 50;

      pointer-events: none;

      background-image: url(../img/svg/school/std/foto-overlay.svg);
      background-size: auto 100%;
      background-position: top right;
      background-repeat: no-repeat;
    }

    .slick-wrapper {
      position: relative;
      margin-left: -200px;
    }

    .slick-images {

      margin-bottom: 0;

      .item {
        .bgcover;
        display: block !important;
      }

      img {
        display: none;
      }

      .slick-dots {
        bottom: 15px;
      }

    }

  }

  .col-right {
    width: 50%;
    float: left;

    .inner {
      padding: 120px 0 120px 120px;
    }

  }

  .school-header {

    position: relative;
    margin-bottom: 40px;

    .btn-favorite {

      color: @pink;
      font-size: 28px;

      position: absolute;
      bottom: 0;
      left: -50px;

    }

    .title {
      margin-bottom: 5px;
    }

    .meta {

    }

  }

  .school-details {

    overflow: hidden;

    .school-info {
      width: 50%;
      float: left;
    }

    .school-actions {
      width: 50%;
      float: left;
    }

  }


  .school-info-address {
    .f2;
  }

  .school-attributes {

    margin-top: 40px;
    border-top: 2px solid @blue;
    padding-top: 20px;

  }

  .attribute {

    overflow: hidden;
    padding: 3px 0;

    .key {
      width: 50%;
      float: left;
      .f2;
    }

    .value {
      float: left;
    }

    a {

      i {
        color: @pink;
      }

    }

  }

}

@media (max-width: 1480px) {

  .section-school-header {

    .st-inner {

      &:after {
        width: 200px;
        height: 200px;
      }

      .after {
        width: 180px;
        height: 180px;
        bottom: -90px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-school-header {

    .st-inner {

      &:after {
        width: 180px;
        height: 180px;
      }

      .after {
        width: 160px;
        height: 160px;
        bottom: -80px;
      }

    }

    .col-left {
      width: 40%;
    }

    .col-right {

      width: 60%;

      .inner {
        padding: 80px 0 80px 60px;
      }

    }

    .school-details {

      .school-info {
        width: 55%;
      }

      .school-actions {
        width: 45%;
      }

    }

    .attribute {

      .key {
        width: 55%;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-school-header {

    .st-inner {

      &:before {
        display: none;
      }

      &:after {
        width: 160px;
        height: 160px;
      }

      .after {
        width: 140px;
        height: 140px;
        bottom: -70px;
      }

    }

    .col-left {
      width: 100%;
      float: none;

      .slick-wrapper {
        margin-left: -25px;
        margin-right: 0;
      }

      .slick-images {

        .item {
          height: 400px;
        }

      }

    }

    .col-right {

      width: 100%;
      float: none;

      .inner {
        padding: 80px 0 100px 45px;
      }

    }

  }

}

@media (max-width: 620px) {

  .section-school-header {

    .school-details {

      .school-info {
        width: 100%;
        float: none;
      }

      .school-actions {
        width: 100%;
        float: none;
        margin-top: 30px;
      }

    }

    .school-attributes {
      margin-top: 30px;
      padding-top: 30px;
    }

  }

}


@media (max-width: 560px) {

  .section-school-header {

    .st-inner {

      &:after {
        width: 140px;
        height: 140px;
        bottom: auto;
        top: 230px;
        z-index: 200;
        background-position: top right;
      }

    }

    .col-left {

      .slick-wrapper {

        margin-left: -25px;
        margin-right: -25px;

        .overlay {
          display: none;
        }

      }

      .slick-images {

        .item {
          height: 300px;
        }

      }

    }

    .col-right {

      .inner {
        padding: 80px 0 100px 0;
      }

    }

    .school-header {

      .btn-favorite {
        bottom: auto;
        top: 5px;
        left: 0;
      }

      .title {
        text-indent: 42px;
      }

    }

    .school-attributes {

      .key {
        width: calc(100% - 80px);
      }

    }

  }

}




.section-school-article {

  &.type-a {

    position: relative;

    .st-inner {



    }

  }

  &.type-b {

    .st-inner {

      &:before {
        width: 120px;
        height: 120px;
        top: 0;
        left: 0;

        background-image: url(../img/svg/school/std/10.svg);
        background-position: top left;
      }

      &:after {
        width: 200px;
        height: 200px;
        bottom: 0;
        left: 0;

        background-image: url(../img/svg/school/std/12.svg);
        background-position: bottom left;
      }

      .after {
        width: 200px;
        height: 200px;
        bottom: 0;
        left: 0;

        background-position: bottom right;
      }

    }

  }

  .col-small {
    max-width: 680px;
  }

  .col-medium {
    max-width: 900px;
  }


  .col-left {
    width: 60%;
    float: left;

    padding-top: 40px;
    padding-right: 100px;
  }

  .article-extra {
    margin-top: 60px;
  }


  .col-right {

    width: 40%;
    float: left;
    position: relative;

    .st-image {

      position: relative;

      &.has-before {

        &:before {
          width: 100px;
          height: 100px;
          top: 0;
          right: 0;

          background-image: url(../img/svg/school/std/5.svg);
          background-position: top right;
          background-repeat: no-repeat;

        }

      }

      &.has-after {

        &:after {
          width: 100px;
          height: 100px;
          bottom: 0;
          right: 0;

          background-image: url(../img/svg/school/std/11.svg);
          background-position: bottom right;
          background-repeat: no-repeat;
        }

      }

    }

  }

}

@media (max-width: 1280px) {

  .section-school-article {

      &.type-b {

        .st-inner {
          padding-top: 150px;
        }

      }

  }

}

@media (max-width: 991px) {

  .section-school-article {

    &.type-a {

      .st-inner {
        padding-top: 120px;
      }

    }

    .col-left {
      width: 100%;
      float: none;
      padding: 0;
      margin-bottom: 60px;
    }

    .col-right {
      width: 100%;
      float: none;
    }

  }

}


.section-school-text {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: 0;

      background-position: top left;
    }

    &:after {
      width: 160px;
      height: 160px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/school/std/7.svg);
      background-position: bottom right;
    }

  }

  &.type-a {


  }

  &.type-b {

    .st-inner {

      padding-top: 120px;

      &:after {
        bottom: auto;
        top: 20%;
      }

      .after {
        width: 200px;
        height: 200px;
        bottom: 0;
        left: 0;

        background-position: bottom left;
      }

    }

  }

}

@media (max-width: 1480px) {

  .section-school-text {

    &.type-a {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
        }

        &:after {

        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
        }

        &:after {

        }

        .after {
          width: 180px;
          height: 180px;
        }

      }

    }

  }

}

@media (max-width: 1280px) {

  .section-school-text {

    &.type-a {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

        &:after {

        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

        &:after {

        }

        .after {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

}


@media (max-width: 991px) {

  .section-school-text {

    &.type-a {

      .st-inner {

        padding-top: 160px;

        &:before {
          width: 140px;
          height: 140px;
        }

        &:after {

        }

      }

    }

    &.type-b {

      .st-inner {

        padding-top: 160px;
        padding-bottom: 140px;

        &:before {
          width: 140px;
          height: 140px;
        }

        &:after {
          display: none;
        }

        .after {
          width: 120px;
          height: 120px;
        }

      }

    }

  }

}

@media (max-width: 540px) {

  .section-school-text {

    &.type-a {

      .st-inner {

        padding-bottom: 160px;

        &:before {

        }

        &:after {

        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {

        }

        &:after {

        }

      }

    }

  }

}



.section-school-cta {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/school/std/8.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 200px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/school/std/9.svg);
      background-position: top right;
    }

  }

  .container {

  }

  .blocks {

    width: 400px;
    margin: 0 auto;
    overflow: hidden;

    .block {

    }

  }

}

@media (max-width: 1480px) {

  .section-school-cta {

    .st-inner {

      padding: 120px 0;

      &:before {
        width: 180px;
        height: 180px;
      }

      &:after {
        width: 180px;
        height: 180px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-school-cta {

    .st-inner {

      padding: 120px 0;

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 160px;
        height: 160px;
      }

    }

  }

}

@media (max-width: 991px) {

  .section-school-cta {

    .st-inner {

      padding: 120px 0;

      &:before {
        width: 140px;
        height: 140px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 767px) {

  .section-school-cta {

    .st-inner {

      padding: 120px 0 80px;

      &:before {
        width: 100px;
        height: 100px;
      }

      &:after {
        width: 120px;
        height: 120px;
      }

    }

  }

}

@media (max-width: 650px) {

  .section-school-cta {

    &.cta-bekijkjetoekomst {

      .c-blocks {

        .c-block {

          padding: 0;

          .split-title {
            margin-bottom: 20px;
          }

          .button {
            position: static;
            transform: none !important;
          }

        }

      }

    }

  }

}

@media (max-width: 540px) {

  .section-school-cta {

    .c-blocks {

      .c-block {

        padding: 0;

        .split-title {
          margin-bottom: 20px;
        }

        .button {
          position: static;
          transform: none !important;
        }

      }

    }

  }

}



.section-school-map {

  .gmap {
    height: 500px;
  }

}

@media (max-width: 991px) {

  .section-school-map {

    .gmap {
      height: 450px;
    }

  }

}

@media (max-width: 767px) {

  .section-school-map {

    .gmap {
      height: 400px;
    }

  }

}


.section-school-articles {

  .st-inner {

    padding: 100px 0 180px;

    &:before {
      width: 120px;
      height: 120px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/school/std/14.svg);
      background-position: bottom left;
    }

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/school/std/13.svg);
      background-position: top right;
    }

  }

  .st-header {
    margin-bottom: 60px;
  }

}

@media (max-width: 1280px) {

  .section-school-articles {

    .st-inner {

      padding: 120px 0 160px;

      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 160px;
        height: 160px;
      }

    }

    .st-header {
      margin-bottom: 30px;
    }

  }

}

@media (max-width: 600px) {

  .section-school-articles {

    .st-inner {


      &:before {
        width: 120px;
        height: 120px;
      }

      &:after {
        width: 140px;
        height: 140px;
      }

    }

  }

}

@media (max-width: 767px) {


}



.theme-ko {

  .section-school-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/ko/1.svg);
      }

      &:after {
        background-image: url(../img/svg/school/ko/2.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/ko/foto-overlay.svg);
      }

    }

  }


  .section-school-article {

    &.type-a {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/ko/3.svg);
        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/ko/9.svg);
        }

        &:after {
          background-image: url(../img/svg/school/ko/11.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/ko/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/ko/10.svg);
          }

        }

      }

    }

  }


  .section-school-text {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/ko/5.svg);
      }

      &:after {
        background-image: url(../img/svg/school/ko/6.svg);
      }

    }

  }

  .section-school-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/ko/7.svg);
      }

      &:after {
        background-image: url(../img/svg/school/ko/8.svg);
      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/ko/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/ko/12.svg);
      }

    }

  }

}


.theme-bo {

  .section-school-header {

    .st-inner {

      &:after {
        background-image: url(../img/svg/school/bo/2.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/bo/foto-overlay.svg);
      }

    }

  }


  .section-school-article {

    &.type-a {

      .st-inner {



      }

    }

    &.type-b {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/bo/9.svg);
        }

        &:after {
          display: none;
          background-image: url(../img/svg/school/bo/11.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/bo/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/bo/10.svg);
          }

        }

      }

    }

    .c-article-data {

    }

  }


  @media (max-width: 1280px) {

    .c-article-data {

      .col-data-left {
        margin-bottom: 60px;
      }

    }

  }

  @media (max-width: 991px) {


  }

  @media (max-width: 767px) {


  }


  .section-school-text {

    .st-inner {

      &:before {
        //background-image: url(../img/svg/school/bo/5.svg);
      }

      &:after {
        width: 120px;
        height: 120px;
        background-image: url(../img/svg/school/bo/6.svg);
      }

    }

    &.type-b {

      .st-inner {

        .after {
          background-image: url(../img/svg/school/bo/14.svg);
        }

      }

    }

  }

  .section-school-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/bo/7.svg);
      }

      &:after {
        background-image: url(../img/svg/school/bo/8.svg);
      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/bo/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/bo/12.svg);
      }

    }

  }

}


.theme-vo {

  .section-school-header {

    .st-inner {

      &:after {
        background-image: url(../img/svg/school/vo/2.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/vo/foto-overlay.svg);
      }

    }

    .attribute {

      a {

        i {
          color: @purple;
        }

      }

    }

  }


  .section-school-article {

    &.type-a {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/vo/9.svg);
        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
          background-image: url(../img/svg/school/vo/5.svg);
        }

        &:after {
          bottom: 0;
          left: auto;
          right: 0;
          background-image: url(../img/svg/school/vo/14.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/vo/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/vo/10.svg);
          }

        }

      }

    }

  }


  @media (max-width: 1480px) {

    .section-school-article {

      &.type-b {

        .st-inner {

          padding-top: 100px;

          &:before {
            width: 160px;
            height: 160px;
          }

          &:after {
            width: 180px;
            height: 180px;
          }

        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-school-article {

      &.type-b {

        .st-inner {

          &:after {
            width: 160px;
            height: 160px;
          }

        }

      }

    }

  }

  @media (max-width: 900px) {

    .section-school-article {

      &.type-b {

        .st-inner {

          padding-top: 160px;

          &:before {
            width: 140px;
            height: 140px;
          }

        }

      }

    }

  }

  @media (max-width: 767px) {


  }


  .section-school-text {

    .st-inner {

      &:after {
        background-image: url(../img/svg/school/vo/6.svg);
      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-text {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-school-text {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 960px) {

    .section-school-text {

      .st-inner {

        padding-bottom: 140px;

        &:before {
          display: none;
        }

        &:after {

        }

      }

    }

  }

  @media (max-width: 900px) {

    .section-school-text {

      .st-inner {

        padding-bottom: 40px;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }

      }

    }

  }



  .section-school-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/vo/7.svg);
      }

      &:after {
        background-image: url(../img/svg/school/vo/14.svg);
      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/vo/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/vo/12.svg);
      }

    }

  }

}


.theme-mbo {

  .section-school-header {

    .st-inner {

      &:after {
        width: 180px;
        height: 180px;
        background-image: url(../img/svg/school/mbo/2.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/mbo/foto-overlay.svg);
      }

    }

    .attribute {

      a {

        i {
          color: @orange;
        }

      }

    }

    .col-right {

      .inner {
        padding-bottom: 180px;
      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-header {

      .st-inner {

        &:after {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-header {

      .st-inner {

        &:after {
          width: 140px;
          height: 140px;
        }

      }

    }

  }

  @media (max-width: 620px) {

    .section-school-header {

      .col-right {

        .inner {
          padding-bottom: 100px;
        }

      }

    }

  }



  .section-school-article {

    &.type-a {

      .st-inner {

        &:before {
          width: 200px;
          height: 200px;
          top: 0;
          left: 0;

          //background-image: url(../img/svg/school/mbo/7.svg);
          background-position: center left;
        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/mbo/9.svg);
        }

        &:after {
          background-image: url(../img/svg/school/mbo/11.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/mbo/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/mbo/10.svg);
          }

        }

      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          &:before {
            width: 180px;
            height: 180px;
          }

        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          padding-top: 170px;

          &:before {
            width: 140px;
            height: 140px;
          }

        }

        .col-left {
          padding-top: 0;
        }

      }

    }

  }


  @media (max-width: 767px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          padding-top: 150px;

          &:before {
            width: 120px;
            height: 120px;
          }

        }

      }

    }

  }



  .section-school-text {

    .st-inner {
      &:after {
        background-image: url(../img/svg/school/mbo/6.svg);
      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-text {

     .st-inner {

       &:before {
         width: 180px;
         height: 180px;
       }

     }

    }

  }

  @media (max-width: 1280px) {

    .section-school-text {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-text {

      .st-inner {

        padding-top: 160px;
        padding-bottom: 100px;

        &:before {
          width: 140px;
          height: 140px;
        }

        &:after {
          width: 120px;
          height: 120px;
        }

      }

    }

  }

  @media (max-width: 767px) {

    .section-school-text {

      .st-inner {

        padding-top: 140px;

        &:before {
          width: 120px;
          height: 120px;
        }

      }

    }

  }



  .section-school-cta {

    &.type-a {

      .st-inner {

        padding: 120px 0;

        &:before {
          display: none;
        }

        &:after {
          width: 120px;
          height: 120px;
          background-image: url(../img/svg/school/mbo/8.svg);
        }

      }

    }

    &.type-b {

      .st-inner {

        padding: 120px 0;

        &:before {
          width: 100px;
          background-image: url(../img/svg/school/mbo/1.svg);
        }

        &:after {
          background-image: url(../img/svg/school/mbo/14.svg);
          background-position: bottom right;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-cta {

      &.type-a {

        .st-inner {

          &:after {
            width: 100px;
            height: 100px;
          }

        }

      }

      &.type-b {

        .st-inner {

          padding: 120px 0 80px;

          &:before {

          }

          &:after {

          }

        }

      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/mbo/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/mbo/12.svg);
      }

    }

  }

}


.theme-hbo {

  .section-school-header {

    .st-inner {

      &:after {
        background-image: url(../img/svg/school/hbo/2.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/hbo/foto-overlay.svg);
      }

    }

    .col-right {

      .inner {
        padding-bottom: 180px;
      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-header {

      .st-inner {

        &:after {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-header {

      .st-inner {

        &:after {
          width: 140px;
          height: 140px;
        }

      }

    }

  }

  @media (max-width: 620px) {

    .section-school-header {

      .col-right {

        .inner {
          padding-bottom: 100px;
        }

      }

    }

  }



  .section-school-article {

    &.type-a {

      .st-inner {

        &:before {
          width: 200px;
          height: 200px;
          top: 0;
          background-position: center left;
        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/hbo/9.svg);
        }

        &:after {
          background-image: url(../img/svg/school/hbo/11.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/hbo/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/hbo/10.svg);
          }

        }

      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          &:before {
            width: 180px;
            height: 180px;
          }

        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          padding-top: 170px;

          &:before {
            width: 140px;
            height: 140px;
          }

        }

        .col-left {
          padding-top: 0;
        }

      }

    }

  }

  @media (max-width: 767px) {

    .section-school-article {

      &.type-a {

        .st-inner {

          padding-top: 150px;

          &:before {
            width: 120px;
            height: 120px;
          }

        }

      }

    }

  }


  .section-school-text {

    .st-inner {

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        background-image: url(../img/svg/school/hbo/8.svg);
      }

      .after {
        width: 200px;
        height: 200px;
        top: auto;
        bottom: 0;
        background-image: url(../img/svg/school/hbo/7.svg);
      }

    }

  }

  @media (max-width: 1480px) {

    .section-school-text {

      .st-inner {

        &:before {
          width: 180px;
          height: 180px;
        }

        .after {
          width: 180px;
          height: 180px;
        }

      }

    }

  }

  @media (max-width: 1280px) {

    .section-school-text {

      .st-inner {

        &:before {
          width: 160px;
          height: 160px;
        }

        .after {
          width: 160px;
          height: 160px;
        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-text {

      .st-inner {

        padding-top: 100px;
        padding-bottom: 160px;

        &:before {
          width: 140px;
          height: 140px;
        }

        &:after {
          width: 120px;
          height: 120px;
        }

        .after {
          width: 120px;
          height: 120px;
        }

      }

    }

  }

  @media (max-width: 767px) {

    .section-school-text {

      .st-inner {

        padding-top: 140px;

        &:before {
          width: 120px;
          height: 120px;
        }

      }

    }

  }


  .section-school-cta {

    &.type-a {

      .st-inner {

        padding: 120px 0;

        &:before {
          display: none;
        }

        &:after {
          width: 120px;
          height: 120px;
          background-image: url(../img/svg/school/hbo/6.svg);
        }

      }

    }

    &.type-b {

      .st-inner {

        padding: 120px 0;

        &:before {
          width: 180px;
          height: 180px;
          top: auto;
          bottom: 0;
          background-image: url(../img/svg/school/hbo/11.svg);
          background-position: bottom left;
        }

        &:after {
          display: none;
        }

      }

    }

  }


  @media (max-width: 1280px) {

    .section-school-cta {

      &.type-b {

        .st-inner {

          &:before {
            width: 160px;
            height: 160px;
          }

        }

      }

    }

  }

  @media (max-width: 991px) {

    .section-school-cta {

      &.type-a {

        .st-inner {

          &:after {
            width: 100px;
            height: 100px;
          }

        }

      }

      &.type-b {

        .st-inner {

          padding: 80px 0 120px;

          &:before {
            width: 120px;
            height: 120px;
          }

          &:after {

          }

        }

      }

    }

  }

  @media (max-width: 620px) {

    .section-school-cta {

      &.type-b {

        .st-inner {

          &:before {
            width: 100px;
            height: 100px;
          }

        }

      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/hbo/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/hbo/12.svg);
      }

    }

  }

}


.theme-so {

  .section-school-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/so/1.svg);
      }

      &:after {
        width: 160px;
        height: 160px;
        background-image: url(../img/svg/school/so/2.svg);
      }

      .after {
        background-image: url(../img/svg/school/so/3.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/so/foto-overlay.svg);
      }

    }

  }


  .section-school-article {

    &.type-a {

      .st-inner {



      }

    }

    &.type-b {

      .st-inner {

        &:before {
          width: 200px;
          height: 200px;
          top: auto;
          bottom: 0;
          background-image: url(../img/svg/school/so/11.svg);
          background-position: bottom left;
        }

        &:after {
          width: 140px;
          height: 140px;
          left: auto;
          right: 0;
          background-image: url(../img/svg/school/so/8.svg);
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/so/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/so/10.svg);
          }

        }

      }

    }

  }


  .section-school-text {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/so/15.svg);
      }

      &:after {
        height: 220px;
        background-image: url(../img/svg/school/so/6.svg);
      }

      .after {
        width: 180px;
        height: 180px;
        bottom: 0;
        left: 0;
        background-image: url(../img/svg/school/so/7.svg);
        background-position: bottom left;
      }

    }

  }

  .section-school-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/so/7.svg);
      }

      &:after {
        background-image: url(../img/svg/school/so/8.svg);
      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        width: 180px;
        background-image: url(../img/svg/school/so/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/so/12.svg);
      }

    }

  }

}


.theme-io {

  .section-school-header {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/io/1.svg);
      }

      &:after {
        background-image: url(../img/svg/school/io/2.svg);
      }

      .after {
        background-image: url(../img/svg/school/io/3.svg);
      }

    }

    .col-left {

      .overlay {
        background-image: url(../img/svg/school/io/foto-overlay.svg);
      }

    }

  }


  .section-school-article {

    &.type-a {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/io/3.svg);
        }

      }

    }

    &.type-b {

      .st-inner {

        &:before {
          background-image: url(../img/svg/school/io/9.svg);
        }

        &:after {
          bottom: auto;
          left: auto;
          top: 0;
          right: 0;
          background-image: url(../img/svg/school/io/14.svg);
          background-position: top right;
        }

        .after {
          width: 180px;
          height: 180px;
          bottom: 0;
          left: 0;
          background-image: url(../img/svg/school/io/11.svg);
          background-position: bottom left;
        }

      }

    }

    .col-right {

      .st-image {

        &.has-before {

          &:before {
            background-image: url(../img/svg/school/io/4.svg);
          }

        }

        &.has-after {

          &:after {
            background-image: url(../img/svg/school/io/10.svg);
          }

        }

      }

    }

  }


  .section-school-text {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/io/5.svg);
      }

      &:after {
        bottom: auto;
        top: -50px;
        background-image: url(../img/svg/school/io/6.svg);
      }

    }

  }


  .section-school-cta {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/io/7.svg);
      }

      &:after {
        background-image: url(../img/svg/school/io/8.svg);
      }

    }

  }


  .section-school-articles {

    .st-inner {

      &:before {
        background-image: url(../img/svg/school/io/13.svg);
      }

      &:after {
        background-image: url(../img/svg/school/io/12.svg);
      }

    }

  }

}
