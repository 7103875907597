
/* ==========================================================================
	BUTTONS
   ========================================================================== */

a.button,
.form button,
.rsform button {
	
	display: inline-block;
	
	font-family: @font1;
	font-size: 18px;
	line-height: 36px;
	text-transform: lowercase;
	white-space: nowrap;
	padding: 0 20px;

	position: relative;
	overflow: hidden;

	color: @white;
	background: @black;
	border: 3px solid @black;

	border-radius: 30px;

	&.outline {
		background: none;
		color: @black;

		&.active,
		&:hover {
			color: @white;
			background: @black;
		}

	}

	&.small {

		line-height: 36px;

		.btn-inner {
			line-height: 36px;
			padding: 0 70px 0 15px;
		}

	}

	&.large {

		line-height: 46px;

		.btn-inner {
			line-height: 46px;
		}

	}

	&.xl {

		line-height: 60px;

		.btn-inner {
			line-height: 60px;
		}

	}


	&.b-blue {
		border-color: @blue;

		&:hover {
			background-color: @blue;
			color: @white;
			opacity: 1;
		}
	}

	&.b-purple {
		border-color: @purple;

		&:hover {
			background-color: @purple;
			color: @white;
			opacity: 1;
		}
	}

	&.b-green {
		border-color: @green;

		&:hover {
			background-color: @green;
			color: @white;
			opacity: 1;
		}

	}


	&.bg-blue2 {
		background-color: @blue2;
	}

	&.bg-blue3 {
		background-color: @blue3;
	}

	&.bg-blue4 {
		background-color: @blue4;
	}

	&.bg-red {
		background-color: @red;
	}


	&.bg-white {

		&:before {
			background: @blue;
			transform: skewX(10deg);
			width: 100px;
			right: -42px;
		}

		&:after {
			background: @blue2;
			transform: skewX(25deg);
			mix-blend-mode: normal;
			right: -45px;
		}

		.btn-inner {
			color: @blue2;
		}

	}

}

.school-actions {

	.btn-a,
	.btn-b,
	.btn-c {

		&:hover {
			color: @white;
			opacity: 1;
		}

	}

}


.theme-bo {

	a.button {

		&.btn-a {

			border-color: @blue;

			&:hover {
				background-color: @blue;
			}

		}

		&.btn-b {

			border-color: @green;

			&:hover {
				background-color: @green;
			}

		}

		&.btn-c {

			border-color: @pink;

			&:hover {
				background-color: @pink;
			}

		}

        &.btn-d {

            border-color: @purple;

            &:hover {
                background-color: @purple;
                cursor: pointer;
            }

        }

	}

}


.theme-vo {

	a.button {

		&.btn-a {

			border-color: @yellow;

			&:hover {
				background-color: @yellow;
			}

		}

		&.btn-b {

			border-color: @purple;

			&:hover {
				background-color: @purple;
			}

		}

		&.btn-c {

			border-color: @blue;

			&:hover {
				background-color: @blue;
			}

		}

        &.btn-d {

            border-color: @green;

            &:hover {
                background-color: @green;
                cursor: pointer;
            }

        }

	}

}


.theme-mbo {

	a.button {

		&.btn-a {

			border-color: @yellow;

			&:hover {
				background-color: @yellow;
			}

		}

		&.btn-b {

			border-color: @pink;

			&:hover {
				background-color: @pink;
			}

		}

		&.btn-c {

			border-color: @orange;

			&:hover {
				background-color: @orange;
			}

		}

        &.btn-d {

            border-color: @blue;

            &:hover {
                background-color: @blue;
                cursor: pointer;
            }

        }

	}

}

.theme-hbo {

	a.button {

		&.btn-a {

			border-color: @yellow;

			&:hover {
				background-color: @yellow;
			}

		}

		&.btn-b {

			border-color: @green;

			&:hover {
				background-color: @green;
			}

		}

		&.btn-c {

			border-color: @blue;

			&:hover {
				background-color: @blue;
			}

		}

        &.btn-d {

            border-color: @purple;

            &:hover {
                background-color: @purple;
                cursor: pointer;
            }

        }

	}

}


.theme-so {

	a.button {

		&.btn-a {

			border-color: @blue;

			&:hover {
				background-color: @blue;
			}

		}

		&.btn-b {

			border-color: @green;

			&:hover {
				background-color: @green;
			}

		}

		&.btn-c {

			border-color: @pink;

			&:hover {
				background-color: @pink;
			}
		}

        &.btn-d {

            border-color: @orange;

            &:hover {
                background-color: @orange;
                cursor: pointer;
            }

        }

	}

}

.theme-io {

	a.button {

		&.btn-a {

			border-color: @yellow;

			&:hover {
				background-color: @yellow;
			}

		}

		&.btn-b {

			border-color: @green;

			&:hover {
				background-color: @green;
			}

		}

		&.btn-c {

			border-color: @blue;

			&:hover {
				background-color: @blue;
			}
		}

        &.btn-d {

            border-color: @pink;

            &:hover {
                background-color: @pink;
                cursor: pointer;
            }

        }

	}

}


.btn-arrow {

	display: inline-block;
	width: 55px;
	height: 35px;
	overflow: hidden;
	position: relative;
	padding: 0;

	&:before {
		display: block;
		content: "";
		width: 55px;
		height: 100%;

		background-color: @blue;

		transition: all 0.1s ease-in-out;
		transform-origin: top left;
		transform: skewX(25deg);

		position: absolute;
		top: 0;
		right: -10px;
	}

	&:after {
		display: block;
		content: "";
		width: 55px;
		height: 100%;

		background-color: @green;
		mix-blend-mode: multiply;

		transition: all 0.1s ease-in-out;
		transform-origin: top left;
		transform: skewX(10deg);

		position: absolute;
		top: 0;
		right: 0;
	}

	i {
		display: block;
		color: @white;
		font-size: 22px;
		line-height: 1em;

		position: absolute;
		top: 50%;
		right: 3px;
		transform: translateY(-47%);
		z-index: 5;
	}

	&:hover {

		&:before {
			background-color: @blue2;
		}

		&:after {
			background-color: @blue;
		}

	}

}


@media (max-width: 991px) {

	a.button,
	form button {

		
	}

}

@media (max-width: 767px) {

	a.button,
	form button {
		

		
	}


}


/* ==========================================================================
	BUTTON FAVOURITE
   ========================================================================== */
a.btn-favorite {

	color: @pink;

	&.do-pulse {
		animation: pulse 1.2s 1;
	}

}



/* ==========================================================================
	BUTTON COLORS
   ========================================================================== */




/* ==========================================================================
	BUTTON GROUP
   ========================================================================== */



/* ==========================================================================
	ICON ANIMATION IN BUTTON
   ========================================================================== */

.button,
.btn-small,
.match-c-search,
.whishlist {
	
	[class^="icon-"],
	[class*=" icon-"] {
		display: inline-block;
		transform: translate(0, 0);
		transition: all 0.1s ease-out;
	}

	.hover &,
	&:hover {
		[class^="icon-"],
		[class*=" icon-"] {
			transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
		}

		.icon-arrow-left{ transform: translate(-3px, 0); }
		.icon-right-small { transform: translate(3px, 0); }
		.icon-arrow-up{ transform: translate(0, -3px); }
		.icon-arrow-down{ transform: translate(0, 3px); }
	}
	
}
