/* uitgangspunten mozaic */
.absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.before-dark {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.2;
}
.bgcover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bgcontain {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}
/* ==========================================================================
	BASE
   ========================================================================== */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  box-sizing: border-box;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'TheSansPlain', sans-serif;
  font-size: 17px;
  line-height: 1.6em;
  color: #000;
  font-weight: normal;
}
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
/* @end */
/* @group default links */
a:link,
a:active,
a:visited {
  outline: none;
  color: #000;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:hover {
  opacity: 0.7;
}
a:active,
a:focus,
*:focus {
  outline: none !important;
  text-decoration: none;
}
.noclick {
  cursor: default;
}
.bold {
  font-weight: bold;
}
img {
  max-width: 100%;
}
.align-right {
  text-align: right;
}
.container {
  position: relative;
}
.containerxx {
  background: rgba(75, 125, 88, 0.1);
}
.plx {
  pointer-events: none;
}
.container {
  position: relative;
}
.indented {
  margin: 0 20px;
}
.bg-blue {
  background-color: #69aadc;
}
.bg-blue-2 {
  background-color: #0065A7;
}
.bg-blue-3 {
  background-color: #002957;
}
.bg-green {
  background-color: #217A3D;
}
/* ==========================================================================
	HEADINGS
   ========================================================================== */
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: 'TheSansBold', sans-serif;
  line-height: 1.1em;
  margin: 0 0 15px;
  color: #000;
  font-weight: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a {
  color: inherit;
}
h1 .duotone,
h2 .duotone,
h3 .duotone,
h4 .duotone,
.h1 .duotone,
.h2 .duotone,
.h3 .duotone,
.h4 .duotone {
  font-weight: bold;
}
.split-title span {
  display: block;
}
.f1 {
  font-family: 'TheSansPlain', sans-serif;
}
.f2 {
  font-family: 'TheSansBold', sans-serif;
}
.f3 {
  font-family: 'CoreSansM55Medium', sans-serif;
}
.f4 {
  font-family: 'CoreSansM65Bold', sans-serif;
  line-height: 1.2em;
}
.t-upper {
  text-transform: uppercase;
}
.t-lower {
  text-transform: lowercase;
}
.t-intro {
  text-transform: uppercase;
  font-family: 'TheSansBold', sans-serif;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 50px;
}
.mb-5 {
  margin-bottom: 50px;
}
.mb-6 {
  margin-bottom: 60px;
}
h0,
.h0 {
  font-size: 60px;
}
h1,
.h1 {
  font-size: 58px;
}
h2,
.h2 {
  font-size: 38px;
}
h3,
.h3 {
  font-size: 34px;
}
h4,
.h4 {
  font-size: 20px;
}
h5,
.h5 {
  font-size: 16px;
}
@media (max-width: 1281px) {
  h0,
  .h0 {
    font-size: 40px;
  }
  h1,
  .h1 {
    font-size: 40px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 26px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  h0,
  .h0 {
    font-size: 36px;
  }
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
}
.h-white {
  color: #fff;
}
.h-dark {
  color: #05334B;
}
.h-green {
  color: #217A3D;
}
.h-red {
  color: #DD271C;
}
.h-blue {
  color: #69aadc;
}
.h-blue2 {
  color: #0065A7;
}
.h-blue3 {
  color: #002957;
}
.h-blue4 {
  color: #001F41;
}
.h-inline {
  display: inline-block;
}
.a-left {
  text-align: left;
}
.a-center {
  text-align: center;
}
.a-right {
  text-align: right;
}
/* ==========================================================================
	ARTICLE OUTPUT  DEFAULTS
   ========================================================================== */
.article-intro {
  font-family: 'CoreSansM55Medium', sans-serif;
  color: #69aadc;
  margin-bottom: 30px;
}
.article-output h1,
.article-output h2,
.article-output h3,
.article-output h4,
.article-output h5 {
  margin-bottom: 8px;
  color: #000;
}
.article-output p {
  margin-bottom: 30px;
}
.article-output p:last-child {
  margin-bottom: 0;
}
.article-output a {
  font-weight: 700;
  border-bottom: 2px solid #05334B;
}
.article-output ul,
.article-output ol,
.article-output li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.article-output ul {
  margin: 0 0 20px;
}
.article-output ul li {
  margin-left: 30px;
  list-style: disc;
}
.article-output ol {
  margin: 0 0 20px;
}
.article-output ol li {
  margin-left: 30px;
  list-style: decimal;
}
.article-output table {
  width: 100%;
  margin-bottom: 30px;
}
.article-output table td {
  padding: 5px 8px;
}
.article-output table td:last-child {
  width: auto;
}
.article-output table tr {
  background: #e8f2fa;
}
.article-output table tr:nth-child(odd) {
  background: #fff;
}
.article-output table tr:first-child {
  background: #76a7d8;
}
.article-output table tr:first-child td {
  font-family: 'TheSansBold', sans-serif;
  color: #000;
  font-weight: bold;
}
.article-output table tbody th {
  background: #05334B;
  color: #fff;
  text-align: left;
  padding: 5px 8px;
}
.article-output table tbody th:last-child {
  width: auto;
}
.article-output table.large-table {
  width: 100%;
  max-width: 1600px;
}
.article-output table.large-table tbody th,
.article-output table.large-table tbody td {
  white-space: normal;
}
.article-output .scrollpointer {
  display: none;
}
@media (max-width: 991px) {
  .article-output .table-scroll-container {
    overflow-y: scroll;
    padding-bottom: 10px;
  }
  .article-output .table-scroll-container table {
    width: 1000px;
    margin-bottom: 0 !important;
  }
  .article-output .scrollpointer {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: #05334B;
    background: #ccc;
    line-height: 30px;
  }
}
@media (max-width: 776px) {
  .article-output .table-scroll-container table {
    width: 1000px;
  }
}
@media (max-width: 1281px) {
  .article-output h1 {
    font-size: 32px;
  }
  .article-output h2 {
    font-size: 28px;
  }
  .article-output h3 {
    font-size: 22px;
  }
  .article-output h4 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .article-output h1 {
    font-size: 30px;
  }
  .article-output h2 {
    font-size: 26px;
  }
  .article-output h3 {
    font-size: 20px;
  }
  .article-output h4 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .article-output h1 {
    font-size: 28px;
  }
  .article-output h2 {
    font-size: 24px;
  }
  .article-output h3 {
    font-size: 20px;
  }
  .article-output h4 {
    font-size: 18px;
  }
}
.main-content {
  min-height: 80vh;
}
.comp-logo {
  display: block;
  width: 180px;
  height: 60px;
  background: url(./img/scholenwijzer-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.site-wrapper {
  overflow: hidden;
  padding-top: 80px;
}
.site-wrapper.map {
  overflow: visible;
}
.site-wrapper.no-pt {
  padding-top: 0;
}
.site-header {
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}
.site-header .container {
  height: 80px;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.site-header .comp-logo {
  width: 320px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 5;
  transform: translate(0, -50%);
  transition: all 0.1s ease-out;
}
.site-header .comp-logo:hover {
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
  transform: translate(3px, -50%);
}
.site-header .c-search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.site-header .whishlist {
  font-size: 22px;
  position: absolute;
  top: 50%;
  right: 125px;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.site-header .whishlist .heart {
  font-size: 18px;
}
.site-header .whishlist .text {
  display: inline-block;
  padding-left: 5px;
}
.site-header .whishlist.off-screen {
  right: 100px;
  opacity: 0;
}
.site-header .toggle-search-open {
  font-size: 36px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.site-header .toggle-search-open.off-screen {
  right: 50px;
  opacity: 0;
}
.site-header .nav-toggle {
  display: none;
}
.scrolled .site-header,
.shownav .site-header {
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.scrolled .site-header .container,
.shownav .site-header .container {
  height: 80px;
}
@media (max-width: 1280px) {
  .site-header .container {
    padding: 0;
    width: 100%;
  }
}
@media (max-width: 1150px) {
  .site-header .whishlist {
    right: 80px;
  }
  .site-header .whishlist .heart {
    font-size: 28px;
  }
  .site-header .whishlist .text {
    display: none;
  }
  .site-header .whishlist .arrow {
    font-size: 28px;
  }
}
@media (max-width: 991px) {
  .site-header .container {
    padding: 0;
    width: 100%;
  }
  .site-header .c-search {
    display: none;
  }
  .site-header .toggle-search-openxx {
    display: none;
  }
  .site-header .whishlist {
    right: 80px;
  }
  .site-header .nav-togglexx {
    display: block;
  }
}
@media (max-width: 550px) {
  .site-wrapper {
    padding-top: 65px;
  }
  .tpl-finder .site-wrapper {
    padding-top: 65px;
  }
  .site-header .container {
    height: 65px;
  }
  .site-header .whishlist .arrow {
    display: none;
  }
  .site-header .comp-logo {
    width: 240px;
    height: 30px;
  }
  .scrolled .site-header .container,
  .shownav .site-header .container {
    height: 65px;
  }
}
.site-footer {
  position: relative;
  background: #fff;
}
.site-footer:before {
  display: block;
  content: "";
  width: 100%;
  height: 50px;
  background: #F7D62D url(./img/bg-footer.svg) center center;
  background-size: cover;
}
.site-footer .denhaag-logo {
  display: block;
  width: 270px;
  height: 90px;
  background: url(./img/gemeente-denhaag-logo.svg) top left no-repeat;
  background-size: contain;
}
.site-footer .primary {
  padding: 30px 0;
}
.site-footer .primary .inner {
  overflow: hidden;
}
.site-footer .primary .footer-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.site-footer .primary .footer-nav li {
  display: inline-block;
  margin-left: 30px;
}
.site-footer .primary .footer-nav a {
  display: block;
  color: #000;
  font-family: 'TheSansBold', sans-serif;
  text-transform: lowercase;
}
@media (max-width: 1280px) {
  .site-footer .denhaag-logo {
    width: 220px;
    height: 80px;
  }
  .site-footer .primary .footer-nav {
    top: 43%;
  }
  .site-footer .primary .footer-nav li {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .site-footer:before {
    height: 40px;
  }
  .site-footer .primary .footer-nav {
    width: calc(100% - 270px);
    text-align: right;
  }
  .site-footer .primary .footer-nav li {
    margin-left: 0;
    margin-right: 20px;
  }
  .site-footer .primary .footer-nav a {
    line-height: 2em;
  }
}
@media (max-width: 650px) {
  .site-footer:before {
    height: 35px;
  }
  .site-footer .denhaag-logo {
    width: 200px;
    height: 70px;
  }
  .site-footer .primary .footer-nav {
    width: calc(100% - 250px);
  }
  .site-footer .primary .footer-nav li {
    margin-left: 0;
    margin-right: 20px;
  }
}
@media (max-width: 600px) {
  .site-footer:before {
    height: 30px;
  }
  .site-footer .denhaag-logo {
    width: 240px;
    height: 80px;
    margin-bottom: 30px;
  }
  .site-footer .primary .footer-nav {
    position: static;
    top: 0;
    right: 0;
    transform: none;
    text-align: left;
  }
  .site-footer .primary .footer-nav li {
    margin-left: 0;
    margin-right: 0;
    display: block;
    float: none;
  }
  .site-footer .primary .footer-nav a {
    display: block;
    line-height: 2em;
  }
}
/* ==========================================================================
	MOBILE MENU
   ========================================================================== */
.nav-toggle {
  width: 36px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}
.nav-icon {
  display: block;
  width: 36px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.nav-icon:before,
.nav-icon:after,
.nav-icon div {
  background-color: #000;
  content: '';
  display: block;
  position: relative;
  height: 3px;
  margin: 6px 0;
  transition: all 0.3s ease-in-out;
}
.nav-icon.i-white:before,
.nav-icon.i-white:after,
.nav-icon.i-white div {
  background-color: #000;
}
.shownav .nav-icon:before,
.shownav .nav-icon:after,
.shownav .nav-icon div {
  left: 0;
}
.shownav .nav-icon:before {
  transform: translateY(9px) rotate(135deg);
  -moz-transform: translateY(9px) rotate(135deg);
  -webkit-transform: translateY(9px) rotate(135deg);
}
.shownav .nav-icon:after {
  transform: translateY(-9px) rotate(-135deg);
  -moz-transform: translateY(-9px) rotate(-135deg);
  -webkit-transform: translateY(-9px) rotate(-135deg);
}
.shownav .nav-icon div {
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.mobile-nav {
  width: 100%;
  height: 100%;
  padding: 0;
  color: #000;
  overflow: hidden;
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 800;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s ease 0s;
  background: #fff;
}
.mobile-nav .container {
  height: 100vh;
  z-index: 200;
  font-size: 16px;
  line-height: 2.2em;
  position: relative;
}
.mobile-nav .container h2,
.mobile-nav .container h3,
.mobile-nav .container h4 {
  font-size: 18px;
}
.mobile-nav .top-bar {
  padding-top: 140px;
}
.mobile-nav a {
  color: #000;
}
.mobile-nav a:hover {
  color: #000;
  opacity: 0.8;
}
.mobile-nav .mainnav a {
  display: block;
  text-align: center;
  font-size: 22px;
  line-height: 2.4em;
}
.mobile-nav .mainnav ul {
  padding: 0 0 20px;
}
.mobile-nav .mainnav ul a {
  font-size: 18px;
  line-height: 2.2em;
  color: #69aadc;
}
.shownav .mobile-nav {
  top: 0;
  transition: all 0.6s ease 0s;
}
.shownav .mobile-nav .before {
  top: 0;
  transition: all 0.5s ease 0s;
}
.search-layer {
  width: 100%;
  height: 100%;
  padding: 0;
  color: #000;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 800;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s ease 0s;
  background: #fff;
  padding-top: 80px;
}
.search-layer.show {
  left: 0;
}
.search-layer .container {
  min-height: calc(100vh - 320px);
  /* padding-top 80 +  padding: 100px 0 140px; = 320px */
  z-index: 200;
}
.search-layer .inner {
  position: relative;
  padding: 100px 0 140px;
  overflow: hidden;
}
.search-layer .inner:before,
.search-layer .inner:after,
.search-layer .inner .before,
.search-layer .inner .after {
  content: "";
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
}
.search-layer .inner:before {
  display: block;
  width: 160px;
  height: 160px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/zoeken/1.svg);
  background-position: top left;
}
.search-layer .inner:after {
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/zoeken/2.svg);
  background-position: top right;
}
.search-layer .inner .before {
  width: 240px;
  height: 200px;
  bottom: -54px;
  right: -120px;
  background-image: url(./img/svg/zoeken/3.svg);
  background-position: bottom right;
}
.search-layer .inner .after {
  width: 200px;
  height: 160px;
  bottom: 0;
  left: -100px;
  background-image: url(./img/svg/zoeken/4.svg);
  background-position: bottom left;
}
/* ==========================================================================
	ANIMATIONS
   ========================================================================== */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.6);
  }
  50% {
    transform: scale(0.4);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse-logo {
  0% {
    transform: translateY(-50%) scale(1);
  }
  25% {
    transform: translateY(-50%) scale(1.05);
  }
  50% {
    transform: translateY(-50%) scale(0.95);
  }
  75% {
    transform: translateY(-50%) scale(1.05);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}
@keyframes FadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes FadeInUp {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes float-up {
  0% {
    transform: translate(0px);
  }
  33% {
    transform: translate(-15px, -40px);
  }
  66% {
    transform: translate(15px, -60px);
  }
  100% {
    transform: translate(0px);
  }
}
@keyframes float-down {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(15px, 40px);
  }
  66% {
    transform: translate(-15px, 60px);
  }
  100% {
    transform: translate(0, 0);
  }
}
/* ==========================================================================
	BUTTONS
   ========================================================================== */
a.button,
.form button,
.rsform button {
  display: inline-block;
  font-family: 'TheSansPlain', sans-serif;
  font-size: 18px;
  line-height: 36px;
  text-transform: lowercase;
  white-space: nowrap;
  padding: 0 20px;
  position: relative;
  overflow: hidden;
  color: #fff;
  background: #000;
  border: 3px solid #000;
  border-radius: 30px;
}
a.button.outline,
.form button.outline,
.rsform button.outline {
  background: none;
  color: #000;
}
a.button.outline.active,
.form button.outline.active,
.rsform button.outline.active,
a.button.outline:hover,
.form button.outline:hover,
.rsform button.outline:hover {
  color: #fff;
  background: #000;
}
a.button.small,
.form button.small,
.rsform button.small {
  line-height: 36px;
}
a.button.small .btn-inner,
.form button.small .btn-inner,
.rsform button.small .btn-inner {
  line-height: 36px;
  padding: 0 70px 0 15px;
}
a.button.large,
.form button.large,
.rsform button.large {
  line-height: 46px;
}
a.button.large .btn-inner,
.form button.large .btn-inner,
.rsform button.large .btn-inner {
  line-height: 46px;
}
a.button.xl,
.form button.xl,
.rsform button.xl {
  line-height: 60px;
}
a.button.xl .btn-inner,
.form button.xl .btn-inner,
.rsform button.xl .btn-inner {
  line-height: 60px;
}
a.button.b-blue,
.form button.b-blue,
.rsform button.b-blue {
  border-color: #69aadc;
}
a.button.b-blue:hover,
.form button.b-blue:hover,
.rsform button.b-blue:hover {
  background-color: #69aadc;
  color: #fff;
  opacity: 1;
}
a.button.b-purple,
.form button.b-purple,
.rsform button.b-purple {
  border-color: #7C2D7F;
}
a.button.b-purple:hover,
.form button.b-purple:hover,
.rsform button.b-purple:hover {
  background-color: #7C2D7F;
  color: #fff;
  opacity: 1;
}
a.button.b-green,
.form button.b-green,
.rsform button.b-green {
  border-color: #217A3D;
}
a.button.b-green:hover,
.form button.b-green:hover,
.rsform button.b-green:hover {
  background-color: #217A3D;
  color: #fff;
  opacity: 1;
}
a.button.bg-blue2,
.form button.bg-blue2,
.rsform button.bg-blue2 {
  background-color: #0065A7;
}
a.button.bg-blue3,
.form button.bg-blue3,
.rsform button.bg-blue3 {
  background-color: #002957;
}
a.button.bg-blue4,
.form button.bg-blue4,
.rsform button.bg-blue4 {
  background-color: #001F41;
}
a.button.bg-red,
.form button.bg-red,
.rsform button.bg-red {
  background-color: #DD271C;
}
a.button.bg-white:before,
.form button.bg-white:before,
.rsform button.bg-white:before {
  background: #69aadc;
  transform: skewX(10deg);
  width: 100px;
  right: -42px;
}
a.button.bg-white:after,
.form button.bg-white:after,
.rsform button.bg-white:after {
  background: #0065A7;
  transform: skewX(25deg);
  mix-blend-mode: normal;
  right: -45px;
}
a.button.bg-white .btn-inner,
.form button.bg-white .btn-inner,
.rsform button.bg-white .btn-inner {
  color: #0065A7;
}
.school-actions .btn-a:hover,
.school-actions .btn-b:hover,
.school-actions .btn-c:hover {
  color: #fff;
  opacity: 1;
}
.theme-bo a.button.btn-a {
  border-color: #69aadc;
}
.theme-bo a.button.btn-a:hover {
  background-color: #69aadc;
}
.theme-bo a.button.btn-b {
  border-color: #217A3D;
}
.theme-bo a.button.btn-b:hover {
  background-color: #217A3D;
}
.theme-bo a.button.btn-c {
  border-color: #D82B84;
}
.theme-bo a.button.btn-c:hover {
  background-color: #D82B84;
}
.theme-bo a.button.btn-d {
  border-color: #7C2D7F;
}
.theme-bo a.button.btn-d:hover {
  background-color: #7C2D7F;
  cursor: pointer;
}
.theme-vo a.button.btn-a {
  border-color: #F7D62D;
}
.theme-vo a.button.btn-a:hover {
  background-color: #F7D62D;
}
.theme-vo a.button.btn-b {
  border-color: #7C2D7F;
}
.theme-vo a.button.btn-b:hover {
  background-color: #7C2D7F;
}
.theme-vo a.button.btn-c {
  border-color: #69aadc;
}
.theme-vo a.button.btn-c:hover {
  background-color: #69aadc;
}
.theme-vo a.button.btn-d {
  border-color: #217A3D;
}
.theme-vo a.button.btn-d:hover {
  background-color: #217A3D;
  cursor: pointer;
}
.theme-mbo a.button.btn-a {
  border-color: #F7D62D;
}
.theme-mbo a.button.btn-a:hover {
  background-color: #F7D62D;
}
.theme-mbo a.button.btn-b {
  border-color: #D82B84;
}
.theme-mbo a.button.btn-b:hover {
  background-color: #D82B84;
}
.theme-mbo a.button.btn-c {
  border-color: #F28700;
}
.theme-mbo a.button.btn-c:hover {
  background-color: #F28700;
}
.theme-mbo a.button.btn-d {
  border-color: #69aadc;
}
.theme-mbo a.button.btn-d:hover {
  background-color: #69aadc;
  cursor: pointer;
}
.theme-hbo a.button.btn-a {
  border-color: #F7D62D;
}
.theme-hbo a.button.btn-a:hover {
  background-color: #F7D62D;
}
.theme-hbo a.button.btn-b {
  border-color: #217A3D;
}
.theme-hbo a.button.btn-b:hover {
  background-color: #217A3D;
}
.theme-hbo a.button.btn-c {
  border-color: #69aadc;
}
.theme-hbo a.button.btn-c:hover {
  background-color: #69aadc;
}
.theme-hbo a.button.btn-d {
  border-color: #7C2D7F;
}
.theme-hbo a.button.btn-d:hover {
  background-color: #7C2D7F;
  cursor: pointer;
}
.theme-so a.button.btn-a {
  border-color: #69aadc;
}
.theme-so a.button.btn-a:hover {
  background-color: #69aadc;
}
.theme-so a.button.btn-b {
  border-color: #217A3D;
}
.theme-so a.button.btn-b:hover {
  background-color: #217A3D;
}
.theme-so a.button.btn-c {
  border-color: #D82B84;
}
.theme-so a.button.btn-c:hover {
  background-color: #D82B84;
}
.theme-so a.button.btn-d {
  border-color: #F28700;
}
.theme-so a.button.btn-d:hover {
  background-color: #F28700;
  cursor: pointer;
}
.theme-io a.button.btn-a {
  border-color: #F7D62D;
}
.theme-io a.button.btn-a:hover {
  background-color: #F7D62D;
}
.theme-io a.button.btn-b {
  border-color: #217A3D;
}
.theme-io a.button.btn-b:hover {
  background-color: #217A3D;
}
.theme-io a.button.btn-c {
  border-color: #69aadc;
}
.theme-io a.button.btn-c:hover {
  background-color: #69aadc;
}
.theme-io a.button.btn-d {
  border-color: #D82B84;
}
.theme-io a.button.btn-d:hover {
  background-color: #D82B84;
  cursor: pointer;
}
.btn-arrow {
  display: inline-block;
  width: 55px;
  height: 35px;
  overflow: hidden;
  position: relative;
  padding: 0;
}
.btn-arrow:before {
  display: block;
  content: "";
  width: 55px;
  height: 100%;
  background-color: #69aadc;
  transition: all 0.1s ease-in-out;
  transform-origin: top left;
  transform: skewX(25deg);
  position: absolute;
  top: 0;
  right: -10px;
}
.btn-arrow:after {
  display: block;
  content: "";
  width: 55px;
  height: 100%;
  background-color: #217A3D;
  mix-blend-mode: multiply;
  transition: all 0.1s ease-in-out;
  transform-origin: top left;
  transform: skewX(10deg);
  position: absolute;
  top: 0;
  right: 0;
}
.btn-arrow i {
  display: block;
  color: #fff;
  font-size: 22px;
  line-height: 1em;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-47%);
  z-index: 5;
}
.btn-arrow:hover:before {
  background-color: #0065A7;
}
.btn-arrow:hover:after {
  background-color: #69aadc;
}
/* ==========================================================================
	BUTTON FAVOURITE
   ========================================================================== */
a.btn-favorite {
  color: #D82B84;
}
a.btn-favorite.do-pulse {
  animation: pulse 1.2s 1;
}
/* ==========================================================================
	BUTTON COLORS
   ========================================================================== */
/* ==========================================================================
	BUTTON GROUP
   ========================================================================== */
/* ==========================================================================
	ICON ANIMATION IN BUTTON
   ========================================================================== */
.button [class^="icon-"],
.btn-small [class^="icon-"],
.match-c-search [class^="icon-"],
.whishlist [class^="icon-"],
.button [class*=" icon-"],
.btn-small [class*=" icon-"],
.match-c-search [class*=" icon-"],
.whishlist [class*=" icon-"] {
  display: inline-block;
  transform: translate(0, 0);
  transition: all 0.1s ease-out;
}
.hover .button [class^="icon-"],
.hover .btn-small [class^="icon-"],
.hover .match-c-search [class^="icon-"],
.hover .whishlist [class^="icon-"],
.button:hover [class^="icon-"],
.btn-small:hover [class^="icon-"],
.match-c-search:hover [class^="icon-"],
.whishlist:hover [class^="icon-"],
.hover .button [class*=" icon-"],
.hover .btn-small [class*=" icon-"],
.hover .match-c-search [class*=" icon-"],
.hover .whishlist [class*=" icon-"],
.button:hover [class*=" icon-"],
.btn-small:hover [class*=" icon-"],
.match-c-search:hover [class*=" icon-"],
.whishlist:hover [class*=" icon-"] {
  transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
}
.hover .button .icon-arrow-left,
.hover .btn-small .icon-arrow-left,
.hover .match-c-search .icon-arrow-left,
.hover .whishlist .icon-arrow-left,
.button:hover .icon-arrow-left,
.btn-small:hover .icon-arrow-left,
.match-c-search:hover .icon-arrow-left,
.whishlist:hover .icon-arrow-left {
  transform: translate(-3px, 0);
}
.hover .button .icon-right-small,
.hover .btn-small .icon-right-small,
.hover .match-c-search .icon-right-small,
.hover .whishlist .icon-right-small,
.button:hover .icon-right-small,
.btn-small:hover .icon-right-small,
.match-c-search:hover .icon-right-small,
.whishlist:hover .icon-right-small {
  transform: translate(3px, 0);
}
.hover .button .icon-arrow-up,
.hover .btn-small .icon-arrow-up,
.hover .match-c-search .icon-arrow-up,
.hover .whishlist .icon-arrow-up,
.button:hover .icon-arrow-up,
.btn-small:hover .icon-arrow-up,
.match-c-search:hover .icon-arrow-up,
.whishlist:hover .icon-arrow-up {
  transform: translate(0, -3px);
}
.hover .button .icon-arrow-down,
.hover .btn-small .icon-arrow-down,
.hover .match-c-search .icon-arrow-down,
.hover .whishlist .icon-arrow-down,
.button:hover .icon-arrow-down,
.btn-small:hover .icon-arrow-down,
.match-c-search:hover .icon-arrow-down,
.whishlist:hover .icon-arrow-down {
  transform: translate(0, 3px);
}
@font-face {
  font-family: 'fontello';
  src: url('./font/fontello.eot?85260939ab');
  src: url('./font/fontello.eot?85260939ab#iefix') format('embedded-opentype'), url('./font/fontello.woff2?85260939ab') format('woff2'), url('./font/fontello.woff?85260939ab') format('woff'), url('./font/fontello.ttf?85260939ab') format('truetype'), url('./font/fontello.svg?85260939ab#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?85260939#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-right:before {
  content: '\5d';
}
/* ']' */
.icon-down-small:before {
  content: '\e01b';
}
/* '' */
.icon-down:before {
  content: '\e800';
}
/* '' */
.icon-search:before {
  content: '\e801';
}
/* '' */
.icon-heart-empty:before {
  content: '\e802';
}
/* '' */
.icon-heart:before {
  content: '\e803';
}
/* '' */
.icon-up-small:before {
  content: '\e805';
}
/* '' */
.icon-up:before {
  content: '\e807';
}
/* '' */
.icon-left:before {
  content: '\e80b';
}
/* '' */
.icon-left-small:before {
  content: '\e812';
}
/* '' */
.icon-right-small:before {
  content: '\e813';
}
/* '' */
.icon-cancel:before {
  content: '\e82d';
}
/* '' */
.icon-sliders:before {
  content: '\e849';
}
/* '' */
.icon-info-circled-alt:before {
  content: '\f086';
}
/* '' */
.icon-direction:before {
  content: '\f124';
}
/* '' */
@font-face {
  font-family: "TheSansPlain";
  src: url('./font/TheSans-B5Plain.woff') format('woff');
}
@font-face {
  font-family: "TheSansBold";
  src: url('./font/TheSans-B7Bold.woff') format('woff');
}
/* ==========================================================================
	FORM CONTROL
   ========================================================================== */
form.formResponsive ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
form.formResponsive :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
form.formResponsive ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
form.formResponsive :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
form.formResponsive ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}
form.formResponsive ::placeholder {
  /* Most modern browsers support this now. */
  color: #fff;
}
form.formResponsive fieldset {
  padding: 0;
  margin: 0;
  border: none;
}
form.formResponsive textarea,
form.formResponsive input[type="text"] {
  width: 100%;
  height: 50px;
  padding: 3px 8px;
  background: none;
  color: #fff;
  border: 2px solid #316094;
  border-radius: 1px;
}
form.formResponsive textarea {
  height: 150px;
  padding: 8px;
}
form.formResponsive label .formRequired {
  display: inline-block;
  color: #F28700;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  top: -5px;
}
form.formResponsive .formError {
  color: #F28700;
}
form.formResponsive .rsform-block {
  width: 100%;
  clear: both;
  float: none;
  margin-bottom: 20px;
}
form.formResponsive .rsform-block .formDescription {
  display: none;
}
form.formResponsive .rsform-block.rsform-error-parent {
  background: rgba(229, 102, 38, 0.1);
}
form.formResponsive .rsform-block.rsform-error-parent textarea,
form.formResponsive .rsform-block.rsform-error-parent input[type="text"] {
  border-color: #F28700;
}
form.formResponsive .rsform-block.rsform-submit {
  background: none;
  padding: 0;
  border-radius: 0;
}
form.formResponsive .rsform-block.rsform-submit .formBody {
  text-align: right;
}
form.formResponsive .rsform-block .formControlLabel {
  display: none;
  color: #05334B;
  font-weight: bold;
}
form.formResponsive .rsform-block.rsform-block-veiligheidscode {
  height: 1px;
  background: none !important;
  padding: 0;
  margin: 0;
}
form.formResponsive .rsform-select {
  background: rgba(0, 0, 0, 0.03);
  padding: 20px;
}
form.formResponsive .rsform-select .formControlLabel {
  margin-bottom: 10px;
}
form.formResponsive .rsform-select select {
  width: 100%;
}
form.formResponsive .rsform-checkboxes,
form.formResponsive .rsform-radios {
  background: rgba(0, 0, 0, 0.03);
  padding: 20px;
}
form.formResponsive .rsform-checkboxes .formControlLabel,
form.formResponsive .rsform-radios .formControlLabel {
  margin-bottom: 10px;
}
form.formResponsive .formBody {
  width: 100%;
  clear: both;
  float: none;
}
form.formResponsive .formBody.frm-checkboxes label,
form.formResponsive .formBody.frm-radios label {
  display: block;
  width: 100%;
  float: none;
  margin-bottom: 10px;
}
form.formResponsive .formBody.frm-checkboxes input,
form.formResponsive .formBody.frm-radios input {
  margin-right: 10px;
}
.mod-article-images {
  margin-bottom: 20px;
}
.mod-article-images .title {
  margin-bottom: 20px;
}
.mod-article-images .article-images {
  overflow: hidden;
}
.mod-article-images .article-images a {
  float: left;
  width: 18%;
  margin: 0 2% 20px 0;
}
.mod-article-images .article-images a:hover {
  opacity: 0.8;
}
@media (max-width: 1480px) {
  .mod-article-images .article-images a {
    width: 23%;
    margin: 0 2% 20px 0;
  }
}
@media (max-width: 1281px) {
  .mod-article-images .article-images a {
    width: 31%;
    margin: 0 2% 20px 0;
  }
}
@media (max-width: 620px) {
  .mod-article-images .article-images a {
    width: 48%;
    margin: 0 2% 20px 0;
  }
}
/* ==========================================================================
	VIDEO CONTAINER
   ========================================================================== */
.video-container {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ==========================================================================
	PAGINATION
   ========================================================================== */
.section .st-inner {
  padding: 80px 0;
  max-width: 5000px;
  margin: 0 auto;
  position: relative;
}
.section .st-inner:before,
.section .st-inner:after,
.section .st-inner .before,
.section .st-inner .before-2,
.section .st-inner .after,
.section .st-inner .after-2 {
  display: block;
  content: "";
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
}
.section .st-inner.py-md {
  padding: 100px 0;
}
.section .st-inner.py-lg {
  padding: 120px 0;
}
.section .st-inner.py-0 {
  padding: 0;
}
.section .st-inner.pb-8 {
  padding-bottom: 80px;
}
.section .st-inner.pb-9 {
  padding-bottom: 90px;
}
.section .st-inner.pb-10 {
  padding-bottom: 100px;
}
.section .st-inner.pb-11 {
  padding-bottom: 110px;
}
.section .st-inner.pb-12 {
  padding-bottom: 120px;
}
.section .container {
  position: relative;
  z-index: 10;
}
.section .indent {
  padding-left: 160px;
}
.section .st-image:before,
.section .st-image:after {
  display: block;
  content: "";
  position: absolute;
  pointer-events: none;
}
.section.no-bff .st-inner:before,
.section.no-bff .st-inner:after,
.section.no-bff .st-inner .before,
.section.no-bff .st-inner .before-2,
.section.no-bff .st-inner .after,
.section.no-bff .st-inner .after-2 {
  display: none !important;
}
.debug .section {
  border-bottom: 1px solid red;
}
.debug .section .st-inner:before,
.debug .section .st-inner:after,
.debug .section .st-inner .before,
.debug .section .st-inner .after {
  background-color: deeppink;
}
.debug .section .st-inner:before {
  background-color: #4c7c33;
}
.debug .section .st-inner:after {
  background-color: #DD271C;
}
.debug .section .st-inner.before {
  background-color: pink;
}
.debug .section .st-image:before,
.debug .section .st-image:after {
  background-color: deeppink;
}
@media (max-width: 1280px) {
  .section .indent {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .section .st-inner {
    padding: 60px 0;
  }
  .section .st-inner.py-lg {
    padding: 100px 0;
  }
}
.section-home-header {
  position: relative;
  z-index: 20;
}
.section-home-header .st-inner {
  padding-top: 100px;
  padding-bottom: 160px;
}
.section-home-header .st-inner:before {
  width: 140px;
  height: 140px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/home/1.svg);
  background-position: top left;
}
.section-home-header .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/home/2.svg);
  background-position: top right;
}
.section-home-header .st-inner .before {
  width: 240px;
  height: 240px;
  bottom: -83px;
  left: 0;
  background-image: url(./img/svg/home/3.svg);
  background-position: top left;
}
.section-home-header .content {
  padding-left: 160px;
  padding-right: 160px;
}
.section-home-header .st-header {
  margin-bottom: 60px;
}
.section-home-header .category-list {
  overflow: hidden;
}
.section-home-header .category-list .cat-button {
  display: inline-block;
  font-size: 26px;
  text-transform: lowercase;
  padding: 12px 60px 12px 20px;
  border: 2px solid #05334B;
  border-radius: 50px;
  position: relative;
}
.section-home-header .category-list .cat-button .arrow {
  font-size: 40px;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
}
.section-home-header .category-list.primary {
  border-bottom: 2px solid #000;
}
.section-home-header .category-list.primary li {
  width: 33.3334%;
  float: left;
  margin-bottom: 30px;
}
.section-home-header .category-list.primary li a.ko i {
  color: #F28700;
}
.section-home-header .category-list.primary li a.ko:hover {
  color: #fff;
  background-color: #F28700;
  border-color: #F28700;
}
.section-home-header .category-list.primary li a.ko:hover i {
  color: #fff;
}
.section-home-header .category-list.primary li a.bo i {
  color: #4FA058;
}
.section-home-header .category-list.primary li a.bo:hover {
  color: #fff;
  background-color: #4FA058;
  border-color: #4FA058;
}
.section-home-header .category-list.primary li a.bo:hover i {
  color: #fff;
}
.section-home-header .category-list.primary li a.vo i {
  color: #7C2D7F;
}
.section-home-header .category-list.primary li a.vo:hover {
  color: #fff;
  background-color: #7C2D7F;
  border-color: #7C2D7F;
}
.section-home-header .category-list.primary li a.vo:hover i {
  color: #fff;
}
.section-home-header .category-list.primary li a.mbo i {
  color: #D82B84;
}
.section-home-header .category-list.primary li a.mbo:hover {
  color: #fff;
  background-color: #D82B84;
  border-color: #D82B84;
}
.section-home-header .category-list.primary li a.mbo:hover i {
  color: #fff;
}
.section-home-header .category-list.primary li a.hbo i {
  color: #69aadc;
}
.section-home-header .category-list.primary li a.hbo:hover {
  color: #fff;
  background-color: #69aadc;
  border-color: #69aadc;
}
.section-home-header .category-list.primary li a.hbo:hover i {
  color: #fff;
}
.section-home-header .category-list.secondary li {
  width: 33.3334%;
  float: left;
  margin-top: 30px;
}
.section-home-header .category-list.secondary li a.vavo i {
  color: #27348B;
}
.section-home-header .category-list.secondary li a.vavo:hover {
  color: #fff;
  background-color: #27348B;
  border-color: #27348B;
}
.section-home-header .category-list.secondary li a.vavo:hover i {
  color: #fff;
}
.section-home-header .category-list.secondary li a.so i {
  color: #E02D16;
}
.section-home-header .category-list.secondary li a.so:hover {
  color: #fff;
  background-color: #E02D16;
  border-color: #E02D16;
}
.section-home-header .category-list.secondary li a.so:hover i {
  color: #fff;
}
.section-home-header .category-list.secondary li a.io i {
  color: #F7D62D;
}
.section-home-header .category-list.secondary li a.io:hover {
  color: #fff;
  background-color: #F7D62D;
  border-color: #F7D62D;
}
.section-home-header .category-list.secondary li a.io:hover i {
  color: #fff;
}
.section-home-header .category-list a [class^="icon-"],
.section-home-header .category-list a [class*=" icon-"] {
  display: inline-block;
  transform: translate(0, 0);
  transition: all 0.1s ease-out;
}
.hover .section-home-header .category-list a [class^="icon-"],
.section-home-header .category-list a:hover [class^="icon-"],
.hover .section-home-header .category-list a [class*=" icon-"],
.section-home-header .category-list a:hover [class*=" icon-"] {
  transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
}
.hover .section-home-header .category-list a .icon-right-small,
.section-home-header .category-list a:hover .icon-right-small {
  transform: translate(3px, 0);
}
@media (max-width: 1480px) {
  .section-home-header .content {
    padding-left: 80px;
    padding-right: 80px;
  }
  .section-home-header .content .category-nav .category-list.secondary li:nth-last-child(1) {
    width: 50%;
  }
}
@media (max-width: 1280px) {
  .section-home-header .content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .section-home-header .st-inner {
    padding-top: 60px;
    padding-bottom: 160px;
  }
  .section-home-header .st-inner:before {
    display: none;
  }
  .section-home-header .st-inner .before {
    width: 180px;
    height: 180px;
    bottom: -62px;
  }
  .section-home-header .category-list.primary li {
    width: 50%;
    margin-bottom: 20px;
  }
  .section-home-header .category-list.primary li a {
    font-size: 24px;
    max-width: none;
  }
  .section-home-header .category-list.secondary li {
    width: 50%;
    margin-top: 20px;
  }
  .section-home-header .category-list.secondary li:first-child {
    width: 50%;
  }
  .section-home-header .category-list.secondary li a {
    font-size: 24px;
    max-width: none;
  }
}
@media (max-width: 740px) {
  .section-home-header .category-list.primary li {
    width: 100%;
    float: none;
  }
  .section-home-header .category-list.secondary li {
    width: 100%;
    float: none;
  }
}
.section-home-article-a .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/home/4.svg);
  background-position: top right;
}
.section-home-article-a .col-left {
  position: relative;
}
.section-home-article-a .col-left .st-image {
  width: 150%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-home-article-a .col-left .st-image img {
  display: none;
}
.section-home-article-a .col-right .inner {
  padding: 160px 0 160px 160px;
  position: relative;
}
.section-home-article-a .col-right .inner:before {
  display: block;
  content: "";
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  height: 160px;
  bottom: 0;
  left: -25px;
  background-image: url(./img/svg/home/5.svg);
  background-position: bottom left;
}
@media (max-width: 1480px) {
  .section-home-article-a .col-right .inner {
    padding: 120px 0 180px 80px;
  }
}
@media (max-width: 1280px) {
  .section-home-article-a .st-inner:after {
    width: 110px;
    height: 160px;
  }
  .section-home-article-a .col-right .inner {
    padding: 60px 0 140px 40px;
  }
  .section-home-article-a .col-right .inner:before {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 991px) {
  .section-home-article-a .st-inner:after {
    display: none;
  }
  .section-home-article-a .col-left .st-image {
    width: auto;
    height: auto;
    position: static;
  }
  .section-home-article-a .col-left .st-image img {
    display: block;
  }
  .section-home-article-a .col-right .inner {
    padding: 60px 0 60px 0;
  }
  .section-home-article-a .col-right .inner:before {
    display: none;
  }
}
.section-home-article-b .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: -100px;
  background-image: url(./img/svg/home/6.svg);
  background-position: top left;
}
.section-home-article-b .col-left {
  float: right;
}
.section-home-article-b .st-image {
  position: relative;
}
.section-home-article-b .st-image.has-before:before {
  width: 160px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/home/10.svg);
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}
@media (max-width: 1280px) {
  .section-home-article-b .st-inner {
    padding-top: 120px;
  }
}
@media (max-width: 991px) {
  .section-home-article-b .col-left {
    margin-bottom: 60px;
  }
}
.section-home-article-c .st-inner:before {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: -65px;
  background-image: url(./img/svg/home/9.svg);
  background-position: bottom left;
}
.section-home-article-c .st-inner:after {
  width: 200px;
  height: 200px;
  top: -90px;
  right: 0;
  background-image: url(./img/svg/home/8.svg);
  background-position: top right;
}
.section-home-article-c .col-right .inner {
  max-width: 600px;
}
@media (max-width: 1480px) {
  .section-home-article-c .col-right .inner {
    max-width: 540px;
  }
}
@media (max-width: 991px) {
  .section-home-article-c .st-inner {
    padding-top: 40px;
    padding-bottom: 160px;
  }
  .section-home-article-c .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-home-article-c .st-inner:after {
    width: 160px;
    height: 160px;
    top: -45px;
  }
  .section-home-article-c .col-left .st-header {
    text-align: left;
  }
  .section-home-article-c .col-right .inner {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .section-home-article-c .st-inner:before {
    width: 160px;
    height: 100px;
    left: -45px;
  }
  .section-home-article-c .st-inner:after {
    width: 80px;
    height: 140px;
    top: -30px;
  }
}
@media (max-width: 540px) {
  .section-home-article-c .st-inner:before {
    width: 130px;
    height: 85px;
    left: -40px;
  }
}
.section-category-header .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/category/std/1.svg);
  background-position: top left;
  background-repeat: no-repeat;
}
.section-category-header .st-inner:after {
  width: 300px;
  height: 300px;
  top: 0;
  right: 0;
  background-position: top right;
  background-repeat: no-repeat;
}
.section-category-header .st-content {
  width: 50%;
  max-width: 630px;
  margin: 100px 0 100px 42%;
  position: relative;
  z-index: 20;
}
.section-category-header .st-content:before {
  display: block;
  content: "";
  position: absolute;
  background-position: center top;
  background-repeat: no-repeat;
  width: 120px;
  height: 120px;
  top: 0;
  left: -160px;
}
.section-category-header .school-filter {
  position: relative;
}
.section-category-header .school-filter .help {
  position: absolute;
  bottom: -50px;
  font-size: 15px;
  line-height: 1.2;
}
.section-category-header .school-filter.type-b label {
  display: block;
  margin-bottom: 5px;
}
.section-category-header .school-filter.type-b label input {
  margin-right: 8px;
}
.section-category-header .school-filter.type-b .button {
  position: absolute;
  bottom: 0;
  left: 140px;
}
.section-category-header .school-filter .frm-checkbox {
  display: block;
  padding: 2px 0 2px 30px;
  position: relative;
  font-size: 15px;
  line-height: 1.25em;
  cursor: pointer;
  margin-bottom: 8px;
}
.section-category-header .school-filter .frm-checkbox:before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("./img/svg/form/check-off.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
}
.section-category-header .school-filter .frm-checkbox input {
  margin-right: 5px;
  display: none;
}
.section-category-header .school-filter .frm-checkbox.checked:before {
  background-image: url("./img/svg/form/check-on.svg");
}
@media (max-width: 1480px) {
  .section-category-header .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-category-header .st-inner:after {
    width: 260px;
    height: 260px;
  }
}
@media (max-width: 1280px) {
  .section-category-header .st-content {
    width: 65%;
    margin: 80px 0 80px 35%;
  }
  .section-category-header .st-content .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-category-header .st-content .st-inner:after {
    width: 240px;
    height: 240px;
  }
}
@media (max-width: 991px) {
  .section-category-header .st-inner {
    padding-bottom: 0;
  }
  .section-category-header .st-inner:before {
    display: none;
  }
  .section-category-header .st-inner:after {
    width: 180px;
    height: 180px;
  }
  .section-category-header .st-content {
    width: 65%;
    margin: 40px 0 40px 35%;
  }
}
@media (max-width: 767px) {
  .section-category-header .st-inner {
    padding: 80px 0 20px;
  }
  .section-category-header .st-inner:after {
    width: 160px;
    height: 160px;
  }
  .section-category-header .st-content {
    width: 100%;
    margin: 0;
  }
  .section-category-header .st-content:before {
    display: none;
  }
}
@media (max-width: 600px) {
  .section-category-header .st-inner:after {
    width: 120px;
    height: 120px;
  }
}
.section-category-article .st-inner:before {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-position: bottom left;
}
.section-category-article .col-left {
  width: 60%;
  float: left;
  min-height: calc(450px - 80px);
}
.section-category-article .col-right {
  width: 40%;
  float: right;
  position: relative;
}
.section-category-article .col-right .st-image {
  width: 792px;
  height: 450px;
  position: absolute;
  top: -80px;
  left: 60px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-category-article .col-right .st-image img {
  display: none;
}
.section-category-article .col-right .st-image .overlay {
  display: block;
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 4px);
  position: absolute;
  top: -2px;
  left: -2px;
  background-image: url(./img/svg/category/std/foto-overlay.svg);
  background-size: auto 100%;
  background-position: top left;
  background-repeat: no-repeat;
}
@media (max-width: 1280px) {
  .section-category-article .st-inner {
    padding-bottom: 160px;
  }
  .section-category-article .st-inner:before {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 991px) {
  .section-category-article .st-inner {
    padding-bottom: 140px;
  }
  .section-category-article .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-category-article .col-left {
    width: 100%;
    float: none;
    min-height: unset;
  }
  .section-category-article .col-right {
    width: 100%;
    float: none;
    margin-bottom: 40px;
  }
  .section-category-article .col-right .st-image {
    width: 100%;
    height: 400px;
    position: static;
  }
  .section-category-article .col-right .st-image img {
    display: none;
  }
  .section-category-article .col-right .st-image .overlay {
    display: none;
  }
}
@media (max-width: 767px) {
  .section-category-article .col-right .st-image {
    height: 360px;
  }
}
.section-category-article-b {
  position: relative;
  z-index: 20;
}
.section-category-article-b .st-inner {
  padding: 160px 0 100px;
}
.section-category-article-b .st-inner:before {
  width: 180px;
  height: 180px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/school/std/4.svg);
  background-position: top left;
}
.section-category-article-b .st-inner:after {
  width: 160px;
  height: 160px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/school/std/9.svg);
  background-position: bottom left;
}
.section-category-article-b .col-small {
  max-width: 680px;
}
.section-category-article-b .col-medium {
  max-width: 900px;
}
.section-category-article-b .col-left {
  width: 60%;
  float: left;
  padding-right: 100px;
}
.section-category-article-b .article-extra {
  margin-top: 60px;
}
.section-category-article-b .col-right {
  width: 40%;
  float: left;
  position: relative;
}
.section-category-article-b .col-right .st-image {
  position: relative;
}
.section-category-article-b .col-right .st-image.has-before:before {
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/school/std/5.svg);
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}
.section-category-article-b .col-right .st-image.has-after:after {
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/11.svg);
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}
@media (max-width: 1280px) {
  .section-category-article-b .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-category-article-b .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 991px) {
  .section-category-article-b .col-left {
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom: 60px;
  }
  .section-category-article-b .col-right {
    width: 100%;
    float: none;
  }
}
.section-category-text .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/school/std/6.svg);
  background-position: top left;
}
.section-category-text .st-inner:after {
  width: 160px;
  height: 160px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/7.svg);
  background-position: bottom right;
}
.section-category-cta .st-inner {
  padding: 120px 0;
}
.section-category-cta .st-inner:before {
  width: 200px;
  height: 200px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/category/std/9.svg);
  background-position: bottom right;
}
.section-category-cta .blocks {
  margin-top: -20px;
  width: 720px;
  margin: 0 auto;
  overflow: hidden;
}
.section-category-cta .blocks.has-single-block {
  text-align: center;
}
.section-category-cta .blocks .block {
  margin-top: 20px;
  width: 50%;
  float: left;
  padding: 0 20px;
}
.section-category-cta .blocks .single-block {
  display: inline-block;
  text-align: left;
  position: relative;
  padding-right: 200px;
}
.section-category-cta .blocks .single-block .button {
  position: absolute;
  right: 0;
  bottom: 30px;
}
.section-category-cta .blocks .single-block .button.v-middle {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1480px) {
  .section-category-cta .st-inner {
    padding: 100px 0;
  }
}
@media (max-width: 1280px) {
  .section-category-cta .st-inner {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  .section-category-cta .st-inner:before {
    width: 160px;
    height: 160px;
    bottom: 0;
  }
  .section-category-cta .blocks {
    width: auto;
    margin: 0 80px 0 0;
  }
  .section-category-cta .blocks .block {
    padding: 0;
  }
}
@media (max-width: 680px) {
  .section-category-cta .st-inner {
    padding: 50px 0 90px;
  }
  .section-category-cta .blocks {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .section-category-cta .blocks .block {
    float: none;
    width: auto;
    display: inline-block;
    padding: 30px;
  }
}
@media (max-width: 480px) {
  .section-category-cta .blocks .single-block {
    padding-right: 0;
  }
  .section-category-cta .blocks .single-block .split-title {
    margin-bottom: 20px;
  }
  .section-category-cta .blocks .single-block .button {
    position: static;
  }
  .section-category-cta .blocks .single-block .button.v-middle {
    transform: translateY(0);
  }
}
.section-category-articles .st-header {
  margin-bottom: 60px;
}
@media (max-width: 1480px) {
  .section-category-articles .st-header {
    margin-bottom: 40px;
  }
}
@media (max-width: 1280px) {
  .section-category-articles .st-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .section-category-articles .st-inner {
    padding: 60px 0 90px;
  }
}
.section-category-faq .st-inner {
  padding: 120px 0;
}
.section-category-faq .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/category/std/5.svg);
  background-position: top left;
}
.section-category-faq .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/category/std/6.svg);
  background-position: top right;
}
.section-category-faq .st-inner .before {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/category/std/7.svg);
  background-position: bottom left;
}
.section-category-faq .container {
  min-height: 300px;
}
.section-category-faq .col-left {
  width: 50%;
  float: left;
  padding-right: 15px;
}
.section-category-faq .col-right {
  width: 50%;
  float: left;
  padding: 0 160px 0 40px;
}
@media (max-width: 1480px) {
  .section-category-faq .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-category-faq .st-inner:after {
    width: 180px;
    height: 180px;
  }
  .section-category-faq .st-inner .before {
    width: 180px;
    height: 180px;
  }
  .section-category-faq .col-right {
    padding: 0 0 0 40px;
  }
}
@media (max-width: 1280px) {
  .section-category-faq .st-inner {
    padding: 160px 0 200px;
  }
  .section-category-faq .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-category-faq .st-inner:after {
    width: 160px;
    height: 160px;
  }
  .section-category-faq .st-inner .before {
    width: 160px;
    height: 160px;
  }
  .section-category-faq .col-left {
    clear: both;
    width: 100%;
    float: none;
    margin-bottom: 60px;
  }
  .section-category-faq .col-right {
    clear: both;
    width: 100%;
    float: none;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .section-category-faq .st-inner {
    padding: 160px 0 180px;
  }
  .section-category-faq .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-category-faq .st-inner:after {
    width: 140px;
    height: 140px;
  }
  .section-category-faq .st-inner .before {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 560px) {
  .section-category-faq .st-inner {
    padding: 160px 0 140px;
  }
  .section-category-faq .st-inner .before {
    width: 100px;
    height: 100px;
  }
}
.theme-ko .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/ko/1.svg);
}
.theme-ko .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/ko/foto-overlay.svg);
}
.theme-ko .section-category-article-b .st-inner:before {
  background-image: url(./img/svg/category/ko/8.svg);
}
.theme-ko .section-category-article-b .st-inner:after {
  background-image: url(./img/svg/category/ko/9.svg);
}
.theme-ko .section-category-article-b .col-right .st-image.has-after:after {
  background-image: url(./img/svg/category/ko/10.svg);
}
@media (max-width: 767px) {
  .theme-ko .section-category-article-b .st-inner {
    padding-top: 0;
  }
  .theme-ko .section-category-article-b .st-inner:before {
    display: none;
  }
}
.theme-ko .section-category-text .st-inner:before {
  display: none;
}
.theme-ko .section-category-text .st-inner:after {
  width: 100px;
  height: 200px;
  background-image: url(./img/svg/category/ko/6.svg);
}
@media (max-width: 1480px) {
  .theme-ko .section-category-text .st-inner:after {
    width: 90px;
    height: 200px;
  }
}
@media (max-width: 1280px) {
  .theme-ko .section-category-text .st-inner:after {
    width: 80px;
    height: 200px;
  }
}
@media (max-width: 767px) {
  .theme-ko .section-category-text .st-inner {
    padding-bottom: 180px;
  }
  .theme-ko .section-category-text .st-inner:after {
    width: 80px;
    height: 200px;
  }
}
.theme-ko .section-category-cta .st-inner:before {
  background-image: url(./img/svg/category/ko/9.svg);
}
.theme-ko .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/ko/5.svg);
}
.theme-ko .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/ko/6.svg);
}
.theme-ko .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/ko/7.svg);
  width: 240px;
  left: -60px;
}
.theme-bo .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/bo/1.svg);
}
.theme-bo .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/bo/foto-overlay.svg);
}
.theme-bo .section-category-cta .st-inner:before {
  background-image: url(./img/svg/category/bo/9.svg);
}
.theme-bo .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/bo/5.svg);
}
.theme-bo .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/bo/6.svg);
}
.theme-bo .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/bo/7.svg);
}
/*  */
.theme-vo .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/vo/1.svg);
}
.theme-vo .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/vo/foto-overlay.svg);
}
.theme-vo .section-category-cta .st-inner:before {
  background-image: url(./img/svg/category/vo/9.svg);
}
@media (max-width: 1480px) {
  .theme-vo .section-category-cta .st-inner:before {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-vo .section-category-cta .st-inner:before {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-vo .section-category-cta .st-inner:before {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 680px) {
  .theme-vo .section-category-cta .st-inner {
    padding-bottom: 120px;
  }
  .theme-vo .section-category-cta .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
.theme-vo .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/vo/5.svg);
}
.theme-vo .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/vo/6.svg);
}
.theme-vo .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/vo/7.svg);
}
.theme-mbo .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/mbo/1.svg);
}
.theme-mbo .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/mbo/foto-overlay.svg);
}
.theme-mbo .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/mbo/5.svg);
}
.theme-mbo .section-category-faq .st-inner:after {
  width: 120px;
  height: 120px;
  background-image: url(./img/svg/category/mbo/6.svg);
}
.theme-mbo .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/mbo/7.svg);
}
.theme-hbo .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/hbo/1.svg);
}
.theme-hbo .section-category-header .st-inner:after {
  width: 260px;
  height: 260px;
}
@media (max-width: 1480px) {
  .theme-hbo .section-category-header .st-inner:after {
    width: 230px;
    height: 230px;
  }
}
@media (max-width: 1280px) {
  .theme-hbo .section-category-header .st-inner:after {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 991px) {
  .theme-hbo .section-category-header .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 620px) {
  .theme-hbo .section-category-header .st-inner:after {
    width: 100px;
    height: 100px;
  }
}
.theme-hbo .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/hbo/foto-overlay.svg);
}
.theme-hbo .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/hbo/5.svg);
}
.theme-hbo .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/hbo/6.svg);
}
.theme-hbo .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/hbo/7.svg);
}
.theme-so .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/so/1.svg);
}
.theme-so .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/so/foto-overlay.svg);
}
.theme-so .section-category-text .st-inner:before {
  display: none;
}
.theme-so .section-category-text .st-inner:after {
  height: 240px;
  background-image: url(./img/svg/category/so/5.svg);
}
@media (max-width: 991px) {
  .theme-so .section-category-text .st-inner:after {
    width: 100px;
    height: 200px;
  }
}
@media (max-width: 880px) {
  .theme-so .section-category-text .st-inner {
    padding-bottom: 120px;
  }
  .theme-so .section-category-text .st-inner:after {
    width: 80px;
    height: 160px;
    bottom: -70px;
  }
}
.theme-so .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/so/5.svg);
}
.theme-so .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/so/6.svg);
}
.theme-so .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/so/7.svg);
}
.theme-io .section-category-header .st-inner:before {
  background-image: url(./img/svg/category/io/1.svg);
}
.theme-io .section-category-header .st-inner:after {
  width: 260px;
  height: 260px;
}
@media (max-width: 1480px) {
  .theme-io .section-category-header .st-inner:after {
    width: 230px;
    height: 230px;
  }
}
@media (max-width: 1280px) {
  .theme-io .section-category-header .st-inner:before {
    width: 150px;
    height: 150px;
  }
  .theme-io .section-category-header .st-inner:after {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 991px) {
  .theme-io .section-category-header .st-inner:after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 650px) {
  .theme-io .section-category-header .st-inner:after {
    width: 120px;
    height: 120px;
  }
}
.theme-io .section-category-article .col-right .st-image .overlay {
  background-image: url(./img/svg/category/io/foto-overlay.svg);
}
.theme-io .section-category-faq .st-inner:before {
  background-image: url(./img/svg/category/io/5.svg);
}
.theme-io .section-category-faq .st-inner:after {
  background-image: url(./img/svg/category/io/6.svg);
}
.theme-io .section-category-faq .st-inner .before {
  background-image: url(./img/svg/category/io/7.svg);
}
.section-school-header {
  position: relative;
  z-index: 5;
}
.section-school-header .st-inner:before {
  width: 120px;
  height: 200px;
  top: 0;
  right: 0;
  background-position: top right;
}
.section-school-header .st-inner:after {
  width: 220px;
  height: 220px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/3.svg);
  background-position: bottom right;
}
.section-school-header .st-inner .after {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: 0;
  z-index: 300;
  background-position: center left;
}
.section-school-header .col-left {
  width: 50%;
  float: left;
  position: relative;
}
.section-school-header .col-left .overlay {
  display: block;
  width: 100%;
  height: calc(100% + 6px);
  position: absolute;
  top: -3px;
  right: -1px;
  z-index: 50;
  pointer-events: none;
  background-image: url(./img/svg/school/std/foto-overlay.svg);
  background-size: auto 100%;
  background-position: top right;
  background-repeat: no-repeat;
}
.section-school-header .col-left .slick-wrapper {
  position: relative;
  margin-left: -200px;
}
.section-school-header .col-left .slick-images {
  margin-bottom: 0;
}
.section-school-header .col-left .slick-images .item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block !important;
}
.section-school-header .col-left .slick-images img {
  display: none;
}
.section-school-header .col-left .slick-images .slick-dots {
  bottom: 15px;
}
.section-school-header .col-right {
  width: 50%;
  float: left;
}
.section-school-header .col-right .inner {
  padding: 120px 0 120px 120px;
}
.section-school-header .school-header {
  position: relative;
  margin-bottom: 40px;
}
.section-school-header .school-header .btn-favorite {
  color: #D82B84;
  font-size: 28px;
  position: absolute;
  bottom: 0;
  left: -50px;
}
.section-school-header .school-header .title {
  margin-bottom: 5px;
}
.section-school-header .school-details {
  overflow: hidden;
}
.section-school-header .school-details .school-info {
  width: 50%;
  float: left;
}
.section-school-header .school-details .school-actions {
  width: 50%;
  float: left;
}
.section-school-header .school-info-address {
  font-family: 'TheSansBold', sans-serif;
}
.section-school-header .school-attributes {
  margin-top: 40px;
  border-top: 2px solid #69aadc;
  padding-top: 20px;
}
.section-school-header .attribute {
  overflow: hidden;
  padding: 3px 0;
}
.section-school-header .attribute .key {
  width: 50%;
  float: left;
  font-family: 'TheSansBold', sans-serif;
}
.section-school-header .attribute .value {
  float: left;
}
.section-school-header .attribute a i {
  color: #D82B84;
}
@media (max-width: 1480px) {
  .section-school-header .st-inner:after {
    width: 200px;
    height: 200px;
  }
  .section-school-header .st-inner .after {
    width: 180px;
    height: 180px;
    bottom: -90px;
  }
}
@media (max-width: 1280px) {
  .section-school-header .st-inner:after {
    width: 180px;
    height: 180px;
  }
  .section-school-header .st-inner .after {
    width: 160px;
    height: 160px;
    bottom: -80px;
  }
  .section-school-header .col-left {
    width: 40%;
  }
  .section-school-header .col-right {
    width: 60%;
  }
  .section-school-header .col-right .inner {
    padding: 80px 0 80px 60px;
  }
  .section-school-header .school-details .school-info {
    width: 55%;
  }
  .section-school-header .school-details .school-actions {
    width: 45%;
  }
  .section-school-header .attribute .key {
    width: 55%;
  }
}
@media (max-width: 991px) {
  .section-school-header .st-inner:before {
    display: none;
  }
  .section-school-header .st-inner:after {
    width: 160px;
    height: 160px;
  }
  .section-school-header .st-inner .after {
    width: 140px;
    height: 140px;
    bottom: -70px;
  }
  .section-school-header .col-left {
    width: 100%;
    float: none;
  }
  .section-school-header .col-left .slick-wrapper {
    margin-left: -25px;
    margin-right: 0;
  }
  .section-school-header .col-left .slick-images .item {
    height: 400px;
  }
  .section-school-header .col-right {
    width: 100%;
    float: none;
  }
  .section-school-header .col-right .inner {
    padding: 80px 0 100px 45px;
  }
}
@media (max-width: 620px) {
  .section-school-header .school-details .school-info {
    width: 100%;
    float: none;
  }
  .section-school-header .school-details .school-actions {
    width: 100%;
    float: none;
    margin-top: 30px;
  }
  .section-school-header .school-attributes {
    margin-top: 30px;
    padding-top: 30px;
  }
}
@media (max-width: 560px) {
  .section-school-header .st-inner:after {
    width: 140px;
    height: 140px;
    bottom: auto;
    top: 230px;
    z-index: 200;
    background-position: top right;
  }
  .section-school-header .col-left .slick-wrapper {
    margin-left: -25px;
    margin-right: -25px;
  }
  .section-school-header .col-left .slick-wrapper .overlay {
    display: none;
  }
  .section-school-header .col-left .slick-images .item {
    height: 300px;
  }
  .section-school-header .col-right .inner {
    padding: 80px 0 100px 0;
  }
  .section-school-header .school-header .btn-favorite {
    bottom: auto;
    top: 5px;
    left: 0;
  }
  .section-school-header .school-header .title {
    text-indent: 42px;
  }
  .section-school-header .school-attributes .key {
    width: calc(100% - 80px);
  }
}
.section-school-article.type-a {
  position: relative;
}
.section-school-article.type-b .st-inner:before {
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/school/std/10.svg);
  background-position: top left;
}
.section-school-article.type-b .st-inner:after {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/school/std/12.svg);
  background-position: bottom left;
}
.section-school-article.type-b .st-inner .after {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-position: bottom right;
}
.section-school-article .col-small {
  max-width: 680px;
}
.section-school-article .col-medium {
  max-width: 900px;
}
.section-school-article .col-left {
  width: 60%;
  float: left;
  padding-top: 40px;
  padding-right: 100px;
}
.section-school-article .article-extra {
  margin-top: 60px;
}
.section-school-article .col-right {
  width: 40%;
  float: left;
  position: relative;
}
.section-school-article .col-right .st-image {
  position: relative;
}
.section-school-article .col-right .st-image.has-before:before {
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/school/std/5.svg);
  background-position: top right;
  background-repeat: no-repeat;
}
.section-school-article .col-right .st-image.has-after:after {
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/11.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
}
@media (max-width: 1280px) {
  .section-school-article.type-b .st-inner {
    padding-top: 150px;
  }
}
@media (max-width: 991px) {
  .section-school-article.type-a .st-inner {
    padding-top: 120px;
  }
  .section-school-article .col-left {
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom: 60px;
  }
  .section-school-article .col-right {
    width: 100%;
    float: none;
  }
}
.section-school-text .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-position: top left;
}
.section-school-text .st-inner:after {
  width: 160px;
  height: 160px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/7.svg);
  background-position: bottom right;
}
.section-school-text.type-b .st-inner {
  padding-top: 120px;
}
.section-school-text.type-b .st-inner:after {
  bottom: auto;
  top: 20%;
}
.section-school-text.type-b .st-inner .after {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-position: bottom left;
}
@media (max-width: 1480px) {
  .section-school-text.type-a .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-school-text.type-b .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-school-text.type-b .st-inner .after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .section-school-text.type-a .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-school-text.type-b .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-school-text.type-b .st-inner .after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .section-school-text.type-a .st-inner {
    padding-top: 160px;
  }
  .section-school-text.type-a .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-school-text.type-b .st-inner {
    padding-top: 160px;
    padding-bottom: 140px;
  }
  .section-school-text.type-b .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-school-text.type-b .st-inner:after {
    display: none;
  }
  .section-school-text.type-b .st-inner .after {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 540px) {
  .section-school-text.type-a .st-inner {
    padding-bottom: 160px;
  }
}
.section-school-cta .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/school/std/8.svg);
  background-position: top left;
}
.section-school-cta .st-inner:after {
  width: 200px;
  height: 200px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/school/std/9.svg);
  background-position: top right;
}
.section-school-cta .blocks {
  width: 400px;
  margin: 0 auto;
  overflow: hidden;
}
@media (max-width: 1480px) {
  .section-school-cta .st-inner {
    padding: 120px 0;
  }
  .section-school-cta .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-school-cta .st-inner:after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .section-school-cta .st-inner {
    padding: 120px 0;
  }
  .section-school-cta .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-school-cta .st-inner:after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .section-school-cta .st-inner {
    padding: 120px 0;
  }
  .section-school-cta .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-school-cta .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 767px) {
  .section-school-cta .st-inner {
    padding: 120px 0 80px;
  }
  .section-school-cta .st-inner:before {
    width: 100px;
    height: 100px;
  }
  .section-school-cta .st-inner:after {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 650px) {
  .section-school-cta.cta-bekijkjetoekomst .c-blocks .c-block {
    padding: 0;
  }
  .section-school-cta.cta-bekijkjetoekomst .c-blocks .c-block .split-title {
    margin-bottom: 20px;
  }
  .section-school-cta.cta-bekijkjetoekomst .c-blocks .c-block .button {
    position: static;
    transform: none !important;
  }
}
@media (max-width: 540px) {
  .section-school-cta .c-blocks .c-block {
    padding: 0;
  }
  .section-school-cta .c-blocks .c-block .split-title {
    margin-bottom: 20px;
  }
  .section-school-cta .c-blocks .c-block .button {
    position: static;
    transform: none !important;
  }
}
.section-school-map .gmap {
  height: 500px;
}
@media (max-width: 991px) {
  .section-school-map .gmap {
    height: 450px;
  }
}
@media (max-width: 767px) {
  .section-school-map .gmap {
    height: 400px;
  }
}
.section-school-articles .st-inner {
  padding: 100px 0 180px;
}
.section-school-articles .st-inner:before {
  width: 120px;
  height: 120px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/school/std/14.svg);
  background-position: bottom left;
}
.section-school-articles .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/school/std/13.svg);
  background-position: top right;
}
.section-school-articles .st-header {
  margin-bottom: 60px;
}
@media (max-width: 1280px) {
  .section-school-articles .st-inner {
    padding: 120px 0 160px;
  }
  .section-school-articles .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-school-articles .st-inner:after {
    width: 160px;
    height: 160px;
  }
  .section-school-articles .st-header {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .section-school-articles .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-school-articles .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
.theme-ko .section-school-header .st-inner:before {
  background-image: url(./img/svg/school/ko/1.svg);
}
.theme-ko .section-school-header .st-inner:after {
  background-image: url(./img/svg/school/ko/2.svg);
}
.theme-ko .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/ko/foto-overlay.svg);
}
.theme-ko .section-school-article.type-a .st-inner:before {
  background-image: url(./img/svg/school/ko/3.svg);
}
.theme-ko .section-school-article.type-b .st-inner:before {
  background-image: url(./img/svg/school/ko/9.svg);
}
.theme-ko .section-school-article.type-b .st-inner:after {
  background-image: url(./img/svg/school/ko/11.svg);
}
.theme-ko .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/ko/4.svg);
}
.theme-ko .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/ko/10.svg);
}
.theme-ko .section-school-text .st-inner:before {
  background-image: url(./img/svg/school/ko/5.svg);
}
.theme-ko .section-school-text .st-inner:after {
  background-image: url(./img/svg/school/ko/6.svg);
}
.theme-ko .section-school-cta .st-inner:before {
  background-image: url(./img/svg/school/ko/7.svg);
}
.theme-ko .section-school-cta .st-inner:after {
  background-image: url(./img/svg/school/ko/8.svg);
}
.theme-ko .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/ko/13.svg);
}
.theme-ko .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/ko/12.svg);
}
.theme-bo .section-school-header .st-inner:after {
  background-image: url(./img/svg/school/bo/2.svg);
}
.theme-bo .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/bo/foto-overlay.svg);
}
.theme-bo .section-school-article.type-b .st-inner:before {
  background-image: url(./img/svg/school/bo/9.svg);
}
.theme-bo .section-school-article.type-b .st-inner:after {
  display: none;
  background-image: url(./img/svg/school/bo/11.svg);
}
.theme-bo .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/bo/4.svg);
}
.theme-bo .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/bo/10.svg);
}
@media (max-width: 1280px) {
  .theme-bo .c-article-data .col-data-left {
    margin-bottom: 60px;
  }
}
.theme-bo .section-school-text .st-inner:after {
  width: 120px;
  height: 120px;
  background-image: url(./img/svg/school/bo/6.svg);
}
.theme-bo .section-school-text.type-b .st-inner .after {
  background-image: url(./img/svg/school/bo/14.svg);
}
.theme-bo .section-school-cta .st-inner:before {
  background-image: url(./img/svg/school/bo/7.svg);
}
.theme-bo .section-school-cta .st-inner:after {
  background-image: url(./img/svg/school/bo/8.svg);
}
.theme-bo .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/bo/13.svg);
}
.theme-bo .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/bo/12.svg);
}
.theme-vo .section-school-header .st-inner:after {
  background-image: url(./img/svg/school/vo/2.svg);
}
.theme-vo .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/vo/foto-overlay.svg);
}
.theme-vo .section-school-header .attribute a i {
  color: #7C2D7F;
}
.theme-vo .section-school-article.type-a .st-inner:before {
  background-image: url(./img/svg/school/vo/9.svg);
}
.theme-vo .section-school-article.type-b .st-inner:before {
  width: 180px;
  height: 180px;
  background-image: url(./img/svg/school/vo/5.svg);
}
.theme-vo .section-school-article.type-b .st-inner:after {
  bottom: 0;
  left: auto;
  right: 0;
  background-image: url(./img/svg/school/vo/14.svg);
}
.theme-vo .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/vo/4.svg);
}
.theme-vo .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/vo/10.svg);
}
@media (max-width: 1480px) {
  .theme-vo .section-school-article.type-b .st-inner {
    padding-top: 100px;
  }
  .theme-vo .section-school-article.type-b .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .theme-vo .section-school-article.type-b .st-inner:after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-vo .section-school-article.type-b .st-inner:after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 900px) {
  .theme-vo .section-school-article.type-b .st-inner {
    padding-top: 160px;
  }
  .theme-vo .section-school-article.type-b .st-inner:before {
    width: 140px;
    height: 140px;
  }
}
.theme-vo .section-school-text .st-inner:after {
  background-image: url(./img/svg/school/vo/6.svg);
}
@media (max-width: 1480px) {
  .theme-vo .section-school-text .st-inner:before {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-vo .section-school-text .st-inner:before {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 960px) {
  .theme-vo .section-school-text .st-inner {
    padding-bottom: 140px;
  }
  .theme-vo .section-school-text .st-inner:before {
    display: none;
  }
}
@media (max-width: 900px) {
  .theme-vo .section-school-text .st-inner {
    padding-bottom: 40px;
  }
  .theme-vo .section-school-text .st-inner:before {
    display: none;
  }
  .theme-vo .section-school-text .st-inner:after {
    display: none;
  }
}
.theme-vo .section-school-cta .st-inner:before {
  background-image: url(./img/svg/school/vo/7.svg);
}
.theme-vo .section-school-cta .st-inner:after {
  background-image: url(./img/svg/school/vo/14.svg);
}
.theme-vo .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/vo/13.svg);
}
.theme-vo .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/vo/12.svg);
}
.theme-mbo .section-school-header .st-inner:after {
  width: 180px;
  height: 180px;
  background-image: url(./img/svg/school/mbo/2.svg);
}
.theme-mbo .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/mbo/foto-overlay.svg);
}
.theme-mbo .section-school-header .attribute a i {
  color: #F28700;
}
.theme-mbo .section-school-header .col-right .inner {
  padding-bottom: 180px;
}
@media (max-width: 1480px) {
  .theme-mbo .section-school-header .st-inner:after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-mbo .section-school-header .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 620px) {
  .theme-mbo .section-school-header .col-right .inner {
    padding-bottom: 100px;
  }
}
.theme-mbo .section-school-article.type-a .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  background-position: center left;
}
.theme-mbo .section-school-article.type-b .st-inner:before {
  background-image: url(./img/svg/school/mbo/9.svg);
}
.theme-mbo .section-school-article.type-b .st-inner:after {
  background-image: url(./img/svg/school/mbo/11.svg);
}
.theme-mbo .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/mbo/4.svg);
}
.theme-mbo .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/mbo/10.svg);
}
@media (max-width: 1480px) {
  .theme-mbo .section-school-article.type-a .st-inner:before {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-mbo .section-school-article.type-a .st-inner {
    padding-top: 170px;
  }
  .theme-mbo .section-school-article.type-a .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .theme-mbo .section-school-article.type-a .col-left {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .theme-mbo .section-school-article.type-a .st-inner {
    padding-top: 150px;
  }
  .theme-mbo .section-school-article.type-a .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
.theme-mbo .section-school-text .st-inner:after {
  background-image: url(./img/svg/school/mbo/6.svg);
}
@media (max-width: 1480px) {
  .theme-mbo .section-school-text .st-inner:before {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-mbo .section-school-text .st-inner:before {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-mbo .section-school-text .st-inner {
    padding-top: 160px;
    padding-bottom: 100px;
  }
  .theme-mbo .section-school-text .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .theme-mbo .section-school-text .st-inner:after {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .theme-mbo .section-school-text .st-inner {
    padding-top: 140px;
  }
  .theme-mbo .section-school-text .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
.theme-mbo .section-school-cta.type-a .st-inner {
  padding: 120px 0;
}
.theme-mbo .section-school-cta.type-a .st-inner:before {
  display: none;
}
.theme-mbo .section-school-cta.type-a .st-inner:after {
  width: 120px;
  height: 120px;
  background-image: url(./img/svg/school/mbo/8.svg);
}
.theme-mbo .section-school-cta.type-b .st-inner {
  padding: 120px 0;
}
.theme-mbo .section-school-cta.type-b .st-inner:before {
  width: 100px;
  background-image: url(./img/svg/school/mbo/1.svg);
}
.theme-mbo .section-school-cta.type-b .st-inner:after {
  background-image: url(./img/svg/school/mbo/14.svg);
  background-position: bottom right;
}
@media (max-width: 991px) {
  .theme-mbo .section-school-cta.type-a .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .theme-mbo .section-school-cta.type-b .st-inner {
    padding: 120px 0 80px;
  }
}
.theme-mbo .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/mbo/13.svg);
}
.theme-mbo .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/mbo/12.svg);
}
.theme-hbo .section-school-header .st-inner:after {
  background-image: url(./img/svg/school/hbo/2.svg);
}
.theme-hbo .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/hbo/foto-overlay.svg);
}
.theme-hbo .section-school-header .col-right .inner {
  padding-bottom: 180px;
}
@media (max-width: 1480px) {
  .theme-hbo .section-school-header .st-inner:after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-hbo .section-school-header .st-inner:after {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 620px) {
  .theme-hbo .section-school-header .col-right .inner {
    padding-bottom: 100px;
  }
}
.theme-hbo .section-school-article.type-a .st-inner:before {
  width: 200px;
  height: 200px;
  top: 0;
  background-position: center left;
}
.theme-hbo .section-school-article.type-b .st-inner:before {
  background-image: url(./img/svg/school/hbo/9.svg);
}
.theme-hbo .section-school-article.type-b .st-inner:after {
  background-image: url(./img/svg/school/hbo/11.svg);
}
.theme-hbo .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/hbo/4.svg);
}
.theme-hbo .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/hbo/10.svg);
}
@media (max-width: 1480px) {
  .theme-hbo .section-school-article.type-a .st-inner:before {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-hbo .section-school-article.type-a .st-inner {
    padding-top: 170px;
  }
  .theme-hbo .section-school-article.type-a .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .theme-hbo .section-school-article.type-a .col-left {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .theme-hbo .section-school-article.type-a .st-inner {
    padding-top: 150px;
  }
  .theme-hbo .section-school-article.type-a .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
.theme-hbo .section-school-text .st-inner:before {
  width: 160px;
  height: 160px;
}
.theme-hbo .section-school-text .st-inner:after {
  background-image: url(./img/svg/school/hbo/8.svg);
}
.theme-hbo .section-school-text .st-inner .after {
  width: 200px;
  height: 200px;
  top: auto;
  bottom: 0;
  background-image: url(./img/svg/school/hbo/7.svg);
}
@media (max-width: 1480px) {
  .theme-hbo .section-school-text .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .theme-hbo .section-school-text .st-inner .after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 1280px) {
  .theme-hbo .section-school-text .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .theme-hbo .section-school-text .st-inner .after {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-hbo .section-school-text .st-inner {
    padding-top: 100px;
    padding-bottom: 160px;
  }
  .theme-hbo .section-school-text .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .theme-hbo .section-school-text .st-inner:after {
    width: 120px;
    height: 120px;
  }
  .theme-hbo .section-school-text .st-inner .after {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 767px) {
  .theme-hbo .section-school-text .st-inner {
    padding-top: 140px;
  }
  .theme-hbo .section-school-text .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
.theme-hbo .section-school-cta.type-a .st-inner {
  padding: 120px 0;
}
.theme-hbo .section-school-cta.type-a .st-inner:before {
  display: none;
}
.theme-hbo .section-school-cta.type-a .st-inner:after {
  width: 120px;
  height: 120px;
  background-image: url(./img/svg/school/hbo/6.svg);
}
.theme-hbo .section-school-cta.type-b .st-inner {
  padding: 120px 0;
}
.theme-hbo .section-school-cta.type-b .st-inner:before {
  width: 180px;
  height: 180px;
  top: auto;
  bottom: 0;
  background-image: url(./img/svg/school/hbo/11.svg);
  background-position: bottom left;
}
.theme-hbo .section-school-cta.type-b .st-inner:after {
  display: none;
}
@media (max-width: 1280px) {
  .theme-hbo .section-school-cta.type-b .st-inner:before {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 991px) {
  .theme-hbo .section-school-cta.type-a .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .theme-hbo .section-school-cta.type-b .st-inner {
    padding: 80px 0 120px;
  }
  .theme-hbo .section-school-cta.type-b .st-inner:before {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 620px) {
  .theme-hbo .section-school-cta.type-b .st-inner:before {
    width: 100px;
    height: 100px;
  }
}
.theme-hbo .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/hbo/13.svg);
}
.theme-hbo .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/hbo/12.svg);
}
.theme-so .section-school-header .st-inner:before {
  background-image: url(./img/svg/school/so/1.svg);
}
.theme-so .section-school-header .st-inner:after {
  width: 160px;
  height: 160px;
  background-image: url(./img/svg/school/so/2.svg);
}
.theme-so .section-school-header .st-inner .after {
  background-image: url(./img/svg/school/so/3.svg);
}
.theme-so .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/so/foto-overlay.svg);
}
.theme-so .section-school-article.type-b .st-inner:before {
  width: 200px;
  height: 200px;
  top: auto;
  bottom: 0;
  background-image: url(./img/svg/school/so/11.svg);
  background-position: bottom left;
}
.theme-so .section-school-article.type-b .st-inner:after {
  width: 140px;
  height: 140px;
  left: auto;
  right: 0;
  background-image: url(./img/svg/school/so/8.svg);
}
.theme-so .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/so/4.svg);
}
.theme-so .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/so/10.svg);
}
.theme-so .section-school-text .st-inner:before {
  background-image: url(./img/svg/school/so/15.svg);
}
.theme-so .section-school-text .st-inner:after {
  height: 220px;
  background-image: url(./img/svg/school/so/6.svg);
}
.theme-so .section-school-text .st-inner .after {
  width: 180px;
  height: 180px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/school/so/7.svg);
  background-position: bottom left;
}
.theme-so .section-school-cta .st-inner:before {
  background-image: url(./img/svg/school/so/7.svg);
}
.theme-so .section-school-cta .st-inner:after {
  background-image: url(./img/svg/school/so/8.svg);
}
.theme-so .section-school-articles .st-inner:before {
  width: 180px;
  background-image: url(./img/svg/school/so/13.svg);
}
.theme-so .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/so/12.svg);
}
.theme-io .section-school-header .st-inner:before {
  background-image: url(./img/svg/school/io/1.svg);
}
.theme-io .section-school-header .st-inner:after {
  background-image: url(./img/svg/school/io/2.svg);
}
.theme-io .section-school-header .st-inner .after {
  background-image: url(./img/svg/school/io/3.svg);
}
.theme-io .section-school-header .col-left .overlay {
  background-image: url(./img/svg/school/io/foto-overlay.svg);
}
.theme-io .section-school-article.type-a .st-inner:before {
  background-image: url(./img/svg/school/io/3.svg);
}
.theme-io .section-school-article.type-b .st-inner:before {
  background-image: url(./img/svg/school/io/9.svg);
}
.theme-io .section-school-article.type-b .st-inner:after {
  bottom: auto;
  left: auto;
  top: 0;
  right: 0;
  background-image: url(./img/svg/school/io/14.svg);
  background-position: top right;
}
.theme-io .section-school-article.type-b .st-inner .after {
  width: 180px;
  height: 180px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/school/io/11.svg);
  background-position: bottom left;
}
.theme-io .section-school-article .col-right .st-image.has-before:before {
  background-image: url(./img/svg/school/io/4.svg);
}
.theme-io .section-school-article .col-right .st-image.has-after:after {
  background-image: url(./img/svg/school/io/10.svg);
}
.theme-io .section-school-text .st-inner:before {
  background-image: url(./img/svg/school/io/5.svg);
}
.theme-io .section-school-text .st-inner:after {
  bottom: auto;
  top: -50px;
  background-image: url(./img/svg/school/io/6.svg);
}
.theme-io .section-school-cta .st-inner:before {
  background-image: url(./img/svg/school/io/7.svg);
}
.theme-io .section-school-cta .st-inner:after {
  background-image: url(./img/svg/school/io/8.svg);
}
.theme-io .section-school-articles .st-inner:before {
  background-image: url(./img/svg/school/io/13.svg);
}
.theme-io .section-school-articles .st-inner:after {
  background-image: url(./img/svg/school/io/12.svg);
}
.section-articles-intro .st-inner:before {
  width: 220px;
  height: 220px;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: url(./img/svg/artikelen/1.svg);
  background-position: top left;
}
.section-articles-intro .st-inner:after {
  width: 200px;
  height: 360px;
  top: 0;
  right: 0;
  z-index: 5;
  background-image: url(./img/svg/artikelen/2.svg);
  background-position: top right;
}
.section-articles-intro .col-right {
  position: relative;
  z-index: 10;
}
.section-articles-intro .col-right .inner {
  padding-right: 150px;
}
@media (max-width: 1480px) {
  .section-articles-intro .st-inner:before {
    width: 200px;
    height: 200px;
  }
  .section-articles-intro .st-inner:after {
    width: 180px;
  }
}
@media (max-width: 1280px) {
  .section-articles-intro .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-articles-intro .st-inner:after {
    width: 160px;
  }
  .section-articles-intro .col-right .inner {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .section-articles-intro .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-articles-intro .st-inner:after {
    width: 160px;
    z-index: 100;
  }
  .section-articles-intro .col-left {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section-articles-intro .st-inner:before {
    width: 200px;
    height: 200px;
    left: -100px;
  }
  .section-articles-intro .st-inner:after {
    width: 140px;
  }
  .section-articles-intro .col-left {
    margin-bottom: 60px;
  }
}
.section-article-grid .st-inner:before {
  width: 200px;
  height: 200px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/artikelen/5.svg);
  background-position: bottom left;
}
.section-article-grid .st-inner:after {
  width: 160px;
  height: 160px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/artikelen/3.svg);
  background-position: top right;
}
.section-article-grid .st-inner .before {
  width: 240px;
  height: 240px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/artikelen/4.svg);
  background-position: bottom right;
}
.section-article-grid .st-header {
  margin-bottom: 60px;
}
.section-article-grid .col-item {
  margin-bottom: 60px;
}
@media (max-width: 1480px) {
  .section-article-grid .st-inner:before {
    width: 180px;
    height: 180px;
  }
  .section-article-grid .st-inner .before {
    width: 220px;
    height: 220px;
  }
  .section-article-grid .st-header {
    margin-bottom: 40px;
  }
}
@media (max-width: 1280px) {
  .section-article-grid .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-article-grid .st-inner .before {
    width: 200px;
    height: 200px;
  }
  .section-article-grid .st-inner:after {
    width: 140px;
    height: 140px;
  }
  .section-article-grid .st-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .section-article-grid .st-inner {
    padding-top: 80px;
  }
  .section-article-grid .st-inner .before {
    width: 180px;
    height: 180px;
  }
  .section-article-grid .st-inner:after {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 640px) {
  .section-article-grid .st-inner .before {
    display: none;
  }
}
@media (max-width: 520px) {
  .section-article-grid .col-item {
    width: 100%;
    float: none;
  }
}
.section-article-a .st-inner:before {
  width: 180px;
  height: 180px;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: url(./img/svg/artikel/1.svg);
  background-position: top left;
}
.section-article-a .st-inner:after {
  width: 300px;
  height: 220px;
  top: 0;
  right: -150px;
  z-index: 100;
  background-image: url(./img/svg/artikel/2.svg);
  background-position: top right;
}
.section-article-a .col-left .inner {
  position: relative;
}
.section-article-a .col-left .inner .st-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 50px;
  bottom: 0;
  left: -200px;
}
.section-article-a .col-left .inner .st-image img {
  display: none;
}
.section-article-a .col-right .inner {
  position: relative;
  max-width: 550px;
  padding-top: 120px;
  padding-bottom: 120px;
}
.section-article-a.text-only .col-right .inner {
  max-width: none;
  padding-top: 180px;
  padding-bottom: 120px;
}
@media (max-width: 1480px) {
  .section-article-a .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-article-a .st-inner:after {
    width: 250px;
    right: -125px;
  }
  .section-article-a .col-left .inner .st-image {
    left: -200px;
    right: 0;
  }
}
@media (max-width: 1280px) {
  .section-article-a .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-article-a .st-inner:after {
    width: 220px;
    right: -110px;
  }
}
@media (max-width: 991px) {
  .section-article-a .col-left .inner .st-image {
    position: static;
  }
  .section-article-a .col-left .inner .st-image img {
    display: block;
  }
  .section-article-a .col-right .inner {
    max-width: none;
    padding-top: 60px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section-article-a .st-inner:before {
    width: 100px;
    height: 100px;
  }
  .section-article-a .st-inner:after {
    width: 160px;
    right: -80px;
  }
  .section-article-a.text-only .col-right .inner {
    padding-top: 130px;
  }
}
.section-article-b {
  position: relative;
  z-index: 10;
}
.section-article-b .st-inner {
  padding: 100px 0;
}
.section-article-b .st-inner:before {
  width: 180px;
  height: 180px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/artikel/3.svg);
  background-position: top left;
}
.section-article-b .st-inner:after {
  width: 180px;
  height: 360px;
  top: -100px;
  right: 0;
  background-image: url(./img/svg/artikel/12.svg);
  background-position: top right;
}
.section-article-b .st-inner .before {
  width: 260px;
  height: 300px;
  bottom: -87px;
  right: 0;
  background-image: url(./img/svg/artikel/5.svg);
  background-position: top right;
}
@media (max-width: 1480px) {
  .section-article-b .st-inner {
    padding: 100px 0;
  }
  .section-article-b .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-article-b .st-inner:after {
    height: 320px;
    top: -80px;
  }
  .section-article-b .st-inner .before {
    height: 260px;
    bottom: -76px;
  }
}
@media (max-width: 1280px) {
  .section-article-b .st-inner {
    padding: 120px 0 100px;
  }
  .section-article-b .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-article-b .st-inner:after {
    height: 300px;
    top: -80px;
  }
  .section-article-b .st-inner .before {
    height: 240px;
    bottom: -70px;
  }
}
@media (max-width: 900px) {
  .section-article-b .st-inner {
    padding: 140px 0 140px;
  }
  .section-article-b .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-article-b .st-inner:after {
    display: none;
  }
  .section-article-b .st-inner .before {
    height: 200px;
    bottom: -58px;
  }
}
.section-article-c .st-inner:before {
  width: 240px;
  height: 240px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/artikel/6.svg);
  background-position: top left;
}
.section-article-c .st-inner:after {
  width: 240px;
  height: 240px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/artikel/7.svg);
  background-position: bottom right;
}
@media (max-width: 1480px) {
  .section-article-c .st-inner:before {
    width: 220px;
    height: 220px;
  }
  .section-article-c .st-inner:after {
    width: 220px;
    height: 220px;
  }
}
@media (max-width: 1280px) {
  .section-article-c .st-inner:before {
    width: 200px;
    height: 200px;
  }
  .section-article-c .st-inner:after {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 991px) {
  .section-article-c .st-inner {
    padding: 100px 0;
  }
  .section-article-c .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-article-c .st-inner:after {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 850px) {
  .section-article-c .st-inner {
    padding: 100px 0 40px;
  }
  .section-article-c .st-inner:after {
    display: none;
  }
}
.section-article-d .st-inner:before {
  width: 160px;
  height: 160px;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: url(./img/svg/artikel/8.svg);
  background-position: top left;
}
.section-article-d .st-inner:after {
  width: 500px;
  height: 320px;
  bottom: 0;
  right: -250px;
  background-image: url(./img/svg/artikel/10.svg);
  background-position: bottom right;
}
.section-article-d .st-inner .before {
  width: 160px;
  height: 160px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/artikel/11.svg);
  background-position: top left;
}
.section-article-d .st-image {
  position: relative;
}
.section-article-d .st-image.has-before:before {
  width: 100px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/artikel/9.svg);
  background-position: top right;
  background-repeat: no-repeat;
}
.section-article-d .col-left {
  float: right;
}
@media (max-width: 1480px) {
  .section-article-d .st-inner:before {
    width: 140px;
    height: 140px;
  }
  .section-article-d .st-inner:after {
    width: 450px;
    right: -225px;
  }
  .section-article-d .st-inner .before {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 1280px) {
  .section-article-d .st-inner {
    padding: 140px 0 160px;
  }
  .section-article-d .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-article-d .st-inner:after {
    width: 400px;
    right: -200px;
  }
  .section-article-d .st-inner .before {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 991px) {
  .section-article-d .st-inner {
    padding: 60px 0 160px;
  }
  .section-article-d .st-inner:after {
    width: 300px;
    right: -150px;
  }
  .section-article-d .col-left {
    margin-bottom: 60px;
  }
}
.section-article-banner .st-inner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-article-banner .container {
  height: 300px;
}
.section-opendagen .st-inner {
  padding: 120px 0 200px;
}
.section-opendagen .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/opendagen/2.svg);
  background-position: top right;
}
.section-opendagen .st-inner .before {
  width: 180px;
  height: 180px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/opendagen/3.svg);
  background-position: bottom right;
}
.section-opendagen .st-inner .before-2 {
  width: 160px;
  height: 160px;
  top: 20%;
  right: 0;
  background-image: url(./img/svg/opendagen/5.svg);
  background-position: top right;
}
.section-opendagen .st-inner .after {
  width: 200px;
  height: 200px;
  bottom: 30%;
  left: -45px;
  background-image: url(./img/svg/opendagen/4.svg);
  background-position: bottom left;
}
.section-opendagen .page-header {
  margin-bottom: 80px;
}
.section-opendagen .list-view-title {
  font-size: 26px;
  margin: 30px 0 15px 0;
}
@media (max-width: 1280px) {
  .section-opendagen .st-inner:before {
    width: 160px;
    height: 160px;
  }
  .section-opendagen .st-inner:after {
    width: 160px;
    height: 160px;
  }
  .section-opendagen .st-inner .before {
    width: 140px;
    height: 140px;
  }
  .section-opendagen .st-inner .before-2 {
    width: 120px;
    height: 120px;
  }
  .section-opendagen .st-inner .after {
    width: 200px;
    height: 200px;
    left: -45px;
  }
}
@media (max-width: 1200px) {
  .section-opendagen .st-inner .before-2 {
    display: none;
  }
  .section-opendagen .st-inner .after {
    width: 180px;
    height: 180px;
    left: -45px;
    bottom: 0;
  }
}
@media (max-width: 991px) {
  .section-opendagen .st-inner {
    padding: 80px 0 120px;
  }
  .section-opendagen .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-opendagen .st-inner:after {
    width: 140px;
    height: 140px;
  }
  .section-opendagen .st-inner .before {
    width: 100px;
    height: 100px;
  }
  .section-opendagen .st-inner .after {
    width: 140px;
    height: 140px;
  }
  .section-opendagen .page-header {
    margin-bottom: 60px;
    margin-left: 25px;
  }
  .section-opendagen .list-view-title {
    font-size: 22px;
    margin-left: 25px;
  }
}
@media (max-width: 767px) {
  .section-opendagen .c-list-view .list-row .cell {
    width: 50%;
    float: left;
  }
  .section-opendagen .c-list-view .list-row .cell.cell-brd {
    border-left: none;
    padding-left: 0;
  }
  .section-opendagen .c-list-view .list-row .cell.cell-title {
    width: 100%;
    float: none;
    padding-bottom: 0;
  }
  .section-opendagen .c-list-view .list-row .cell.cell-2,
  .section-opendagen .c-list-view .list-row .cell.cell-3 {
    padding-top: 0;
    font-size: 80%;
    opacity: 0.8;
  }
}
@media (max-width: 500px) {
  .section-opendagen .st-inner {
    padding: 80px 0 120px;
  }
  .section-opendagen .st-inner:before {
    display: none;
  }
  .section-opendagen .st-inner .after {
    width: 110px;
    height: 110px;
    left: -25px;
  }
  .section-opendagen .page-header {
    margin-left: 10px;
  }
  .section-opendagen .list-view-title {
    margin-left: 10px;
  }
  .section-opendagen .c-list-view .list-row.row-pull-left {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.section-page .st-inner {
  padding: 120px 0 200px;
}
.section-page .st-inner:before {
  width: 180px;
  height: 180px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/page/1.svg);
  background-position: top left;
}
.section-page .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/page/2.svg);
  background-position: top right;
}
.section-page .st-inner .before {
  width: 350px;
  height: 300px;
  bottom: 0;
  right: -115px;
  background-image: url(./img/svg/page/3.svg);
  background-position: bottom right;
}
.section-page .st-inner .after {
  width: 180px;
  height: 180px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/page/4.svg);
  background-position: bottom left;
}
.tpl-launch {
  min-height: 10vh;
}
.section-launch .st-inner:before {
  width: 160px;
  height: 160px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/404/1.svg);
  background-position: top left;
  background-repeat: no-repeat;
}
.section-launch .st-inner:after {
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/404/2.svg);
  background-position: top right;
  background-repeat: no-repeat;
}
.section-launch .st-inner .before {
  width: 240px;
  height: 200px;
  bottom: -54px;
  right: -120px;
  background-image: url(./img/svg/404/3.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
}
.section-launch .st-inner .after {
  width: 200px;
  height: 160px;
  bottom: 0;
  left: -100px;
  background-image: url(./img/svg/404/4.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
}
.section-launch .container {
  min-height: 60vh;
  position: relative;
}
.section-launch .content {
  width: 950px;
  text-align: center;
  margin: 40px auto 60px;
}
@media (max-width: 1280px) {
  .section-launch .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-launch .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-launch .st-inner .before {
    width: 220px;
    height: 200px;
    bottom: -54px;
    right: -120px;
  }
  .section-launch .st-inner .after {
    width: 180px;
    height: 160px;
    bottom: 0;
    left: -100px;
  }
  .section-launch .content {
    width: 850px;
  }
}
@media (max-width: 991px) {
  .section-launch .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-launch .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-launch .st-inner .before {
    width: 180px;
    height: 180px;
    bottom: -42px;
    right: -90px;
  }
  .section-launch .st-inner .after {
    width: 160px;
    height: 100px;
    left: -80px;
  }
  .section-launch .content {
    width: auto;
  }
}
@media (max-width: 767px) {
  .section-launch .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-launch .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-launch .st-inner .before {
    width: 180px;
    height: 180px;
    bottom: -42px;
    right: -90px;
  }
  .section-launch .st-inner .after {
    width: 160px;
    height: 100px;
    left: -80px;
  }
  .section-launch .content {
    margin-top: 80px;
  }
  .section-launch .content .h2 {
    font-size: 26px;
  }
}
@media (max-width: 600px) {
  .section-launch .st-inner:before {
    width: 80px;
    height: 80px;
  }
  .section-launch .st-inner:after {
    width: 80px;
    height: 80px;
  }
  .section-launch .st-inner .before {
    width: 160px;
    height: 160px;
    bottom: -42px;
    right: -80px;
  }
  .section-launch .st-inner .after {
    width: 140px;
    height: 100px;
    left: -70px;
  }
  .section-launch .content {
    margin-top: 40px;
  }
  .section-launch .content .h2 {
    font-size: 24px;
  }
}
.tpl-not-found {
  min-height: 10vh;
}
.section-404 .st-inner:before {
  width: 160px;
  height: 160px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/404/1.svg);
  background-position: top left;
  background-repeat: no-repeat;
}
.section-404 .st-inner:after {
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/404/2.svg);
  background-position: top right;
  background-repeat: no-repeat;
}
.section-404 .st-inner .before {
  width: 240px;
  height: 200px;
  bottom: -54px;
  right: -120px;
  background-image: url(./img/svg/404/3.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
}
.section-404 .st-inner .after {
  width: 200px;
  height: 160px;
  bottom: 0;
  left: -100px;
  background-image: url(./img/svg/404/4.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
}
.section-404 .container {
  min-height: 60vh;
  position: relative;
}
.section-404 .content {
  width: 950px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.section-404 .content .button i {
  transform: rotate(180deg);
}
@media (max-width: 1280px) {
  .section-404 .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-404 .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-404 .st-inner .before {
    width: 220px;
    height: 200px;
    bottom: -54px;
    right: -120px;
  }
  .section-404 .st-inner .after {
    width: 180px;
    height: 160px;
    bottom: 0;
    left: -100px;
  }
  .section-404 .content {
    width: 700px;
  }
}
@media (max-width: 991px) {
  .section-404 .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-404 .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-404 .st-inner .before {
    width: 180px;
    height: 180px;
    bottom: -42px;
    right: -90px;
  }
  .section-404 .st-inner .after {
    width: 160px;
    height: 100px;
    left: -80px;
  }
}
@media (max-width: 767px) {
  .section-404 .st-inner:before {
    width: 120px;
    height: 120px;
  }
  .section-404 .st-inner:after {
    width: 100px;
    height: 100px;
  }
  .section-404 .st-inner .before {
    width: 180px;
    height: 180px;
    bottom: -42px;
    right: -90px;
  }
  .section-404 .st-inner .after {
    width: 160px;
    height: 100px;
    left: -80px;
  }
  .section-404 .container {
    min-height: auto;
  }
  .section-404 .content {
    width: auto;
    transform: none;
    position: static;
    margin: 100px 20px;
  }
}
@media (max-width: 600px) {
  .section-404 .st-inner:before {
    width: 80px;
    height: 80px;
  }
  .section-404 .st-inner:after {
    width: 80px;
    height: 80px;
  }
  .section-404 .st-inner .before {
    width: 160px;
    height: 160px;
    bottom: -42px;
    right: -80px;
  }
  .section-404 .st-inner .after {
    width: 140px;
    height: 100px;
    left: -70px;
  }
  .section-404 .content {
    margin: 60px 0 30px;
  }
  .section-404 .content .h1 {
    font-size: 30px;
  }
}
.slick-articles {
  width: calc(100% + 30px);
}
.slick-articles .item .inner {
  margin-right: 30px;
}
.slick-open-days-outer {
  padding: 0 145px;
  margin-bottom: 80px;
}
.slick-open-days .slick-dots {
  left: 0;
  bottom: -40px;
}
.slick-open-days .item {
  padding: 0 15px;
}
.slick-open-days .inner {
  padding: 20px;
  border: 1px solid #05334B;
  border-radius: 15px;
}
.slick-open-days .inner .date {
  font-family: 'TheSansBold', sans-serif;
  font-size: 22px;
}
.slick-open-days .inner .time {
  font-family: 'TheSansBold', sans-serif;
}
.slick-open-days .inner .info {
  margin-top: 10px;
}
.slick-open-days .inner .info a {
  word-break: break-all;
}
.slick-open-days .inner .info p {
  margin-bottom: 0;
}
@media (max-width: 1280px) {
  .slick-open-days-outer {
    padding: 0;
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .slick-open-days-outer {
    margin-top: 30px;
  }
}
a.back-to-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #000;
  position: fixed;
  bottom: 80px;
  z-index: 500;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  right: -45px;
  pointer-events: none;
}
a.back-to-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  font-size: 30px;
  color: #fff;
}
a.back-to-top.show {
  opacity: 1;
  right: 30px;
  pointer-events: auto;
}
.c-search .input {
  width: 190px;
  height: 40px;
  float: left;
  margin-right: 10px;
  border: 2px solid #000;
  border-radius: 40px;
  padding-left: 15px;
  font-size: 14px;
}
.c-search a,
.c-search button {
  display: block;
  float: left;
  background: #000;
  line-height: 40px;
  padding: 0 25px;
  border-radius: 40px;
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
}
.c-search button {
  border: none;
}
.c-search button:hover {
  opacity: 0.7;
}
.c-search-box {
  background-color: #fff;
  position: absolute;
  top: -50%;
  right: 40px;
  z-index: 50;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  padding-right: 30px;
}
.c-search-box.show {
  top: 50%;
}
.c-search-box form {
  position: relative;
}
.c-search-box form .input {
  width: 300px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 40px;
  padding-left: 15px;
  font-size: 14px;
}
.c-search-box form a {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.c-search-box .toggle-search-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.c-accordeon .panel {
  padding-right: 40px;
}
.c-accordeon .question {
  font-size: 20px;
  line-height: 1.2em;
  padding: 12px 0;
  margin: 0;
  border-bottom: 2px solid #7C2D7F;
  position: relative;
  cursor: pointer;
}
.c-accordeon .question .arrow {
  position: absolute;
  top: 14px;
  right: -36px;
  transition: all 0.25s ease-in-out;
}
.c-accordeon .question.open {
  border-color: #fff;
}
.c-accordeon .question.open .arrow {
  transform: rotate(90deg);
}
.c-faq-links {
  margin-top: 0;
}
.c-faq-links .question {
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  padding: 12px 0;
  margin: 0;
  border-bottom: 2px solid #7C2D7F;
  position: relative;
  cursor: pointer;
}
.c-faq-links .question .arrow {
  position: absolute;
  top: 14px;
  right: -36px;
  transition: all 0.25s ease-in-out;
}
.c-anchor-menu {
  margin-top: 40px;
}
.c-anchor-menu ul,
.c-anchor-menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-anchor-menu li {
  padding: 8px 0;
}
.c-anchor-menu a {
  display: block;
  font-weight: 700;
  position: relative;
  padding-left: 30px;
}
.c-anchor-menu a .text {
  display: inline-block;
  border-bottom: 1px solid #000;
}
.c-anchor-menu a i {
  position: absolute;
  top: 0;
  left: 0;
}
.c-anchor-menu a:hover {
  color: #7C2D7F;
}
.c-article-list .article-item {
  margin-bottom: 40px;
}
.c-article-list .article-item:last-child {
  margin-bottom: 0;
}
.c-article-list .article-item.c-kostenleerjaar .article-output p {
  display: block;
  margin-bottom: 5px;
}
.c-article-list .article-item .article-actions.floats {
  margin-top: 20px;
  overflow: hidden;
}
.c-article-list .article-item .article-actions.floats .float-a {
  float: left;
  margin-right: 50px;
}
.c-article-list .article-item .article-actions.floats .float-b {
  float: left;
}
.c-tiplist li {
  padding: 4px 0;
  position: relative;
}
.c-tiplist li .infotip {
  position: relative;
  top: -5px;
  opacity: 0.8;
}
/* artikel blokken in de slider */
.c-article-box .article-image {
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}
.c-article-box .article-image:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: #7C2D7F;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.c-article-box .article-image .arrow {
  font-size: 46px;
  color: #fff;
  position: absolute;
  top: 70%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out 0.1s;
  opacity: 0;
}
.c-article-box .bgimg {
  height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.c-article-box .bgimg img {
  display: none;
}
.c-article-box .title .inside {
  display: block;
  position: relative;
  padding-right: 28px;
}
.c-article-box .title .inside i {
  position: absolute;
  top: 0;
  right: 0;
}
.c-article-box:hover .article-image:before {
  transform: scale(1);
  opacity: 0.7;
}
.c-article-box:hover .article-image .arrow {
  top: 50%;
  opacity: 1;
}
.c-article-box:hover .bgimg {
  transform: scale(1.1);
}
.article-grid-item:nth-child(4n+2) .article-image:before,
.slick-slide:nth-child(4n+2) .article-image:before {
  background-color: #217A3D;
}
.article-grid-item:nth-child(4n+3) .article-image:before,
.slick-slide:nth-child(4n+3) .article-image:before {
  background-color: #69aadc;
}
.article-grid-item:nth-child(4n+4) .article-image:before,
.slick-slide:nth-child(4n+4) .article-image:before {
  background-color: #D82B84;
}
.c-blocks {
  text-align: center;
}
.c-blocks .c-block {
  display: inline-block;
  text-align: left;
  position: relative;
  padding-right: 200px;
}
.c-blocks .c-block .line-2 {
  margin-bottom: 0;
}
.c-blocks .c-block .button {
  position: absolute;
  right: 0;
  bottom: 17px;
}
.c-blocks .c-block .button.v-middle {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.c-article-data {
  margin-top: 60px;
}
.c-article-data .data-footer-text {
  margin-top: 15px;
}
.c-article-data .data-footer-text .key {
  display: inline-block;
  margin-right: 30px;
}
.c-article-data .data-footer-text .value {
  display: inline-block;
  font-family: 'TheSansBold', sans-serif;
}
.c-data-table {
  width: 100%;
}
.c-data-table .title {
  display: block;
  font-family: 'TheSansBold', sans-serif;
}
.c-data-table .subtitle {
  display: block;
  font-size: 14px;
}
.c-data-table tr {
  border-bottom: 2px solid #69aadc;
}
.c-data-table td {
  vertical-align: bottom;
  padding: 5px 0;
}
.c-data-table td.center {
  text-align: center;
}
.c-data-table td.key {
  width: 160px;
}
.c-data-table-beschikbaar {
  width: 80%;
}
.c-data-table-beschikbaar .title {
  display: block;
  font-family: 'TheSansBold', sans-serif;
}
.c-data-table-beschikbaar .subtitle {
  display: block;
  font-size: 14px;
}
.c-data-table-beschikbaar tr {
  border-bottom: 2px solid #69aadc;
}
.c-data-table-beschikbaar tr:last-child {
  border-bottom: none;
}
.c-data-table-beschikbaar td {
  vertical-align: bottom;
  padding: 5px 0;
}
.c-data-table-beschikbaar td.value {
  width: 80px;
  text-align: center;
  border-left: 2px solid #69aadc;
}
.c-data-table-beschikbaar td.value.fit {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}
.c-list-view .flex-wrapper:nth-child(even) .list-row {
  background-color: white;
}
.c-list-view .list-row {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 0 25px;
  border-radius: 40px;
  margin: 5px 0;
}
.c-list-view .list-row.row-pull-left {
  margin-left: -25px;
}
.c-list-view .list-row:nth-child(odd) {
  background-color: #E8F2FA;
}
.c-list-view .list-row .row-action {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 32px;
  transition: all 0.3s ease-in-out;
}
.c-list-view .list-row.has-goto .row-action {
  right: 25px;
}
.c-list-view .list-row.has-goto:hover {
  background-color: #cae2f5;
}
.c-list-view .list-row.has-goto:hover .row-action {
  right: 10px;
}
.c-list-view .list-row .cell {
  width: 25%;
  float: left;
  padding: 10px 0;
}
.c-list-view .list-row .cell.cell-brd {
  border-left: 2px solid #D5D5D5;
  padding-left: 15px;
}
.c-list-view .list-row .cell.cell-title {
  width: 75%;
}
.c-list-view .list-row .cell.cell-1x {
  background-color: #0a939f;
}
.c-list-view .list-row .cell.cell-2x {
  background-color: #4c7c33;
}
.c-list-view .list-row .cell.cell-3x {
  background-color: indianred;
}
.c-list-view .list-row .cell.cell-4x {
  background-color: dodgerblue;
}
@media (max-width: 991px) {
  .c-list-view .list-row.row-pull-left {
    margin-left: 0;
  }
}
.c-ajax-loader {
  min-height: 200px;
  background-image: url(./img/loader.gif);
  background-position: center center;
  background-repeat: no-repeat;
}
/* This is the core CSS of Tooltipster */
.infotip {
  cursor: pointer;
}
/* GENERAL STRUCTURE RULES (do not edit this section) */
.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
  if greater that the natural height of the tooltip, will be enforced
  in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}
.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
  and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}
.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}
.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}
/* ANIMATIONS */
/* Open/close animations */
/* fade */
.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
  opacity: 1;
}
/* grow */
.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
/* swing */
.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}
/* fall */
.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}
.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}
/* slide */
.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}
.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}
/* Update animations */
/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */
/* fade */
@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}
/* rotate */
@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}
.tooltipster-update-rotate {
  animation: tooltipster-rotating 600ms;
}
/* scale */
@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}
/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */
/* .tooltipster-box */
.tooltipster-sidetip .tooltipster-box {
  background: #F7D62D;
  border-radius: 10px;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}
/* .tooltipster-content */
.tooltipster-sidetip .tooltipster-content {
  font-size: 90%;
  color: #000;
  padding: 25px;
}
.tooltipster-sidetip .tooltipster-content p:last-child {
  margin-bottom: 0;
}
/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */
.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  /* half the width, for centering */
  margin-left: -10px;
  top: 0;
  width: 20px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
  been positioned yet */
  top: 0;
  width: 10px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  /* same as .tooltipster-left .tooltipster-arrow */
  top: 0;
  width: 10px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}
/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */
.tooltipster-sidetip .tooltipster-arrow-background,
.tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}
/* .tooltipster-arrow-background */
.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #F7D62D;
  left: 0;
  top: 3px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #F7D62D;
  left: -3px;
  top: 0;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #F7D62D;
  left: 3px;
  top: 0;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #F7D62D;
  left: 0;
  top: -3px;
}
/* .tooltipster-arrow-border */
.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #F7D62D;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #F7D62D;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #F7D62D;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #F7D62D;
}
/* tooltipster-arrow-uncropped */
.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}
/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}
.selectric-responsive {
  width: 100%;
}
.selectric {
  border: 1px solid #E0E0E0;
  border-bottom-width: 2px;
  background: #F8F8F8;
  position: relative;
  border-radius: 4px;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 30px 0 0;
  padding: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #444;
  min-height: 18px;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOCA1IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjgiIGhlaWdodD0iNSI+PHBhdGggZD0iTTcuNzgxIDEuNDQ0Yy0uMjgxLjMwNy0zLjIzOCAzLjMxMS0zLjIzOCAzLjMxMS0uMTUxLjE2NC0uMzQ3LjI0NS0uNTQ0LjI0NS0uMTk3IDAtLjM5NC0uMDgyLS41NDMtLjI0NSAwIDAtMi45NTctMy4wMDMtMy4yMzktMy4zMTEtLjI4MS0uMzA3LS4zLS44NTkgMC0xLjE4OC4zMDEtLjMyOC43MTktLjM1NCAxLjA4NyAwbDIuNjk1IDIuNzU1IDIuNjk0LTIuNzU1Yy4zNjgtLjM1NC43ODctLjMyOCAxLjA4NyAwIC4zMDEuMzI4LjI4Mi44ODEgMCAxLjE4N3oiIGZpbGw9IiNiYmIiLz48L3N2Zz4K) no-repeat 50%;
  font: 0/0 a;
}
@media \0screen\,screen\9  {
  .selectric .button {
    color: #DDD;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}
.selectric-focus .selectric {
  border-color: #AAA;
}
.selectric-hover .selectric {
  border-color: #CCC;
}
.selectric-hover .selectric .button {
  color: #888;
}
.selectric-hover .selectric .button:after {
  border-top-color: #888;
}
.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric {
  border-color: #CCC;
  background: #F0F0F0;
  margin-top: 1px;
  border-bottom-width: 1px;
}
.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}
.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}
.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}
/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fefefe;
  border: 1px solid #CCC;
  z-index: -1;
  box-shadow: 0 0 10px -6px, inset 0 0 0px 1px #fff;
  margin: 8px 0;
  padding: 10px 0;
  border-radius: 4px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-items:before,
.selectric-items:after {
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #BBB;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}
.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}
.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #BBB;
  margin-top: 0;
}
.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 6px 30px 6px 10px;
  color: #666;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}
.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}
.selectric-items li:after {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  right: -10px;
  width: 30px;
  height: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEwIj48cGF0aCBkPSJNMCA1YzAtLjIyNC4wNzItLjQxNS4yMTctLjU3MmwxLjA1My0xLjE0NWMuMTQ0LS4xNTcuMzItLjIzNi41MjYtLjIzNi4yMDYgMCAuMzgyLjA3OS41MjYuMjM2bDIuMjc2IDIuNDgzIDUuMDc5LTUuNTNjLjE0NS0uMTU3LjMyLS4yMzYuNTI2LS4yMzYuMjA2IDAgLjM4Mi4wNzkuNTI2LjIzNmwxLjA1MyAxLjE0NWMuMTQ0LjE1Ny4yMTcuMzQ4LjIxNy41NzIgMCAuMjI0LS4wNzIuNDE1LS4yMTcuNTcybC02LjY1OCA3LjIzOWMtLjE0NC4xNTctLjMyLjIzNi0uNTI2LjIzNi0uMjA2IDAtLjM4Mi0uMDc5LS41MjYtLjIzNmwtMy44NTYtNC4xOTJjLS4xNDUtLjE1Ny0uMjE3LS4zNDgtLjIxNy0uNTcyeiIgZmlsbD0iI2JiYiIvPjwvc3ZnPgo=) no-repeat 50% 50%;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}
.selectric-items li:hover {
  background: #F0F0F0;
  color: #444;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}
.article-grid .row {
  margin-right: -40px;
  margin-left: -40px;
}
.article-grid .item {
  margin-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
}
.article-list .item {
  padding: 50px 0;
}
.article-list .item .article-image {
  position: relative;
  overflow: hidden;
}
.article-list .item .article-image:after {
  display: block;
  content: "";
  width: 130%;
  height: 100px;
  background: #fff;
  transform-origin: top left;
  transform: skewY(-5deg);
  position: absolute;
  bottom: -100px;
  left: 0;
}
.article-list .item .col-left .inner {
  padding-right: 20px;
}
.article-list .item .col-right .inner {
  padding-left: 20px;
}
.article-list .item .article-header {
  max-width: 450px;
  margin-bottom: 20px;
}
.article-list .item .article-header h2 {
  margin-bottom: 20px;
}
.article-list .item .article-header .label {
  font-size: 16px;
  color: #69aadc;
}
.article-list .item .article-output {
  margin-bottom: 40px;
}
.article-list .item .article-actions {
  overflow: hidden;
}
.article-list .item .article-actions .button {
  float: left;
  margin: 10px 10px 0 0;
}
.article-list .item:nth-child(even) {
  background: #002957;
}
.article-list .item:nth-child(even) .article-image:after {
  background: #002957;
}
.tooltip_templates {
  display: none !important;
}
.section-finder {
  max-width: 2500px;
}
.section-finder .map-toggles {
  display: none;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
.section-finder .col-left,
.section-finder .col-right {
  width: 50%;
  float: left;
}
.section-finder .col-left {
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.section-finder .col-right {
  float: right;
  position: relative;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}
.section-finder .col-right .toggle-map {
  display: block;
  background: #fff;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 200;
  font-size: 40px;
  padding: 20px 20px 20px 10px;
  border-radius: 0 80px 80px 0;
}
.section-finder .col-right .toggle-map i {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.section-finder .col-right .toggle-map:hover {
  opacity: 1 !important;
}
.section-finder .finder-map-wrapper {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 80px;
}
.section-finder .finder-gmap {
  height: calc(100vh - 80px);
}
@media (max-width: 550px) {
  .section-finder .finder-map-wrapper {
    top: 65px;
  }
  .section-finder .finder-gmap {
    height: calc(100vh - 65px);
  }
}
.section-finder.fullscreen .col-left {
  width: 0;
}
.section-finder.fullscreen .col-right {
  width: 100%;
}
.section-finder.fullscreen .col-right .toggle-map {
  opacity: 1 !important;
}
.section-finder.fullscreen .col-right .toggle-map i {
  transform: rotate(180deg);
}
.section-finder.fullscreen .col-right .toggle-map:hover {
  opacity: 1 !important;
}
.section-finder .col-left .box-left {
  width: 280px;
  float: left;
  padding: 30px 15px 30px 30px;
}
.section-finder .col-left .box-right {
  width: calc(100% - 280px);
  float: left;
  padding: 30px 30px 30px 30px;
}
.section-finder .col-left .breadcrumbs {
  font-size: 14px;
  margin-bottom: 30px;
}
.section-finder .col-left .breadcrumbs > .spacer {
  margin: 0 1rem;
}
.section-finder .col-left .sorting-container {
  width: 100%;
  margin-bottom: 30px;
}
.section-finder .col-left .sorting-container .select-box {
  width: 220px;
  float: right;
}
.section-finder .col-left .sorting-container .frm-select {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 36px;
  border: 2px solid #000;
  border-radius: 60px;
  background-color: #fff;
  padding: 0 15px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.section-finder .ng2-nouislider {
  margin: 30px 0 50px;
}
.section-finder .ng2-nouislider .noUi-target {
  background: #e2e2e2;
  border-radius: 5px;
  border: none;
  box-shadow: none;
}
.section-finder .ng2-nouislider .noUi-horizontal {
  height: 5px;
}
.section-finder .ng2-nouislider .noUi-horizontal .noUi-handle {
  width: 10px;
  height: 26px;
  right: -5px;
  top: -10px;
  background-color: #000;
  border-color: #000;
  box-shadow: none;
  border-radius: 8px;
}
.section-finder .ng2-nouislider .noUi-horizontal .noUi-handle:before,
.section-finder .ng2-nouislider .noUi-horizontal .noUi-handle:after {
  display: none;
}
.section-finder .ng2-nouislider .noUi-connect {
  background: #000;
}
.section-finder .ng2-nouislider .noUi-pips {
  color: #000;
}
.section-finder .ng2-nouislider .noUi-pips-horizontal {
  padding: 12px 0 0 0;
  height: 50px;
}
.section-finder .ng2-nouislider .noUi-pips-horizontal .noUi-marker-large {
  height: 12px;
  background-color: #000;
}
.section-finder .ng2-nouislider .noUi-marker-normal {
  opacity: 0;
}
.section-finder .ng2-nouislider .noUi-value {
  color: #000;
  font-size: 14px;
  font-family: 'TheSansBold', sans-serif;
}
.section-finder .noUi-customlabel {
  font-size: 14px;
  text-align: right;
  margin-right: -13px;
}
.section-finder .filter-layer {
  position: relative;
}
.section-finder .filter-layer-actions {
  display: none;
}
.section-finder .filter-layer-actions .toggle-open {
  display: block;
  position: fixed;
  bottom: 40px;
  left: 25px;
  z-index: 100;
  transition: all 0.3s ease 0s;
}
.section-finder .filter-layer-actions .toggle-close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 20;
}
.section-finder .finder-filters .filter-box {
  margin-bottom: 15px;
}
.section-finder .finder-filters .filter-title {
  display: inline-block;
  position: relative;
}
.section-finder .finder-filters .filter-title .infotip {
  position: absolute;
  top: -10px;
  right: -20px;
}
.section-finder .finder-filters .filter-toggle {
  display: inline-block;
  line-height: 1.2em;
  padding: 8px 20px;
  border: 2px solid #000;
  border-radius: 30px;
  position: relative;
}
.section-finder .finder-filters .filter-toggle .number-selected {
  margin-left: 6px;
}
.section-finder .finder-filters .filter-toggle.active {
  background-color: #000;
  color: #fff;
}
.section-finder .finder-filters .filter-options {
  display: none;
  padding: 15px 20px;
}
.section-finder .finder-filters .filter-options label {
  display: block;
  padding: 2px 0 2px 30px;
  position: relative;
  font-size: 15px;
  line-height: 1.25em;
  cursor: pointer;
  margin-bottom: 8px;
}
.section-finder .finder-filters .filter-options label:before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("./img/svg/form/check-off.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
}
.section-finder .finder-filters .filter-options label input {
  margin-right: 5px;
  display: none;
}
.section-finder .finder-filters .filter-options label.checked:before {
  background-image: url("./img/svg/form/check-on.svg");
}
.section-finder .finder-filters .filter-options .helptext {
  color: #000;
  font-size: 12px;
  padding: 8px 55px 0 17px;
  opacity: 0.8;
}
.section-finder .finder-filters .filter-options.open {
  display: block;
}
.section-finder .finder-results .result {
  margin-bottom: 40px;
  overflow: hidden;
}
.section-finder .finder-results .result .result-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  float: left;
  margin-right: 40px;
}
.section-finder .finder-results .result .result-image img {
  display: none;
}
.section-finder .finder-results .result .result-info {
  width: calc(100% - 200px);
  float: left;
}
.section-finder .finder-results .result .box {
  padding-left: 30px;
  position: relative;
}
.section-finder .finder-results .result .box .btn-favorite {
  position: absolute;
  top: 0;
  left: 0;
}
.section-finder .finder-results .result .title {
  margin: 0;
}
.section-finder .finder-results .result .subtitle {
  display: block;
}
.section-finder .finder-results .result .info {
  font-size: 14px;
  line-height: 1.4em;
  margin: 15px 0;
}
.section-finder .finder-results .result .info p:last-child {
  margin-bottom: 0;
}
.section-finder .finder-results .result .distance {
  font-size: 14px;
  font-style: italic;
  opacity: 0.7;
}
.section-finder .gm-style .gm-style-iw-c {
  padding: 0 !important;
  border-radius: 0 !important;
}
.section-finder .gm-style-iw {
  min-width: 300px !important;
  max-width: 360px !important;
  min-height: 150px;
}
.popup-box {
  width: 360px;
  padding: 25px 20px 20px;
  position: relative;
}
.popup-box .popup-close {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  font-size: 13px;
  overflow: hidden;
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 100;
}
.popup-box .popup-close i {
  opacity: 1;
}
.popup-box .popup-close:hover {
  opacity: 1;
  color: #7C2D7F;
}
.popup-box .location-image {
  width: 100%;
  max-width: 100%;
  pointer-events: none;
}
.popup-box .location-header {
  padding: 15px 0;
  position: relative;
}
.popup-box .location-header .btn-favorite {
  font-size: 18px;
  position: absolute;
  top: 40px;
  left: 0;
}
.popup-box .location-header .title {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 5px;
}
.popup-box .location-header .meta {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 30px;
}
.popup-box .location-address {
  padding-left: 30px;
  font-size: 15px;
  line-height: 1.4em;
}
@media (max-width: 500px) {
  .popup-box {
    width: 100%;
  }
}
@media (max-width: 460px) {
  .section-finder .gm-style-iw {
    min-width: 280px !important;
    max-width: 300px !important;
  }
  .popup-boxxx {
    width: 280px;
  }
}
@media (max-width: 415px) {
  .popup-boxxx {
    width: 250px;
  }
}
@media (max-width: 1480px) {
  .section-finder .col-left .filter-layer-actions {
    display: block;
  }
  .section-finder .col-left .filter-layer {
    position: fixed;
    width: 360px;
    height: calc(100% - 80px);
    top: 80px;
    left: -370px;
    z-index: 400;
    padding: 40px 25px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    transition: all 0.5s ease 0s;
  }
  .section-finder .col-left .filter-layer.open {
    left: 0;
  }
  .section-finder .col-left .filter-layer.open .toggle-open {
    opacity: 0;
    left: -20px;
    pointer-events: none;
  }
  .section-finder .col-left .box-right {
    width: 100%;
    float: none;
    padding: 30px 30px 30px 30px;
  }
}
@media (max-width: 1280px) {
  .section-finder .col-right .toggle-map {
    font-size: 36px;
    padding: 15px 15px 15px 10px;
  }
  .section-finder .finder-results .result .result-image {
    width: 140px;
    height: 120px;
    margin-right: 30px;
  }
  .section-finder .finder-results .result .result-info {
    width: calc(100% - 170px);
  }
}
@media (max-width: 550px) {
  .section-finder .col-left .filter-layer {
    height: calc(100% - 65px);
    top: 65px;
  }
  .section-finder .finder-gmap {
    height: calc(100vh - 65px);
  }
}
@media (max-width: 470px) {
  .section-finder .finder-results .result .result-image {
    float: none;
    width: 100%;
    height: 220px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .section-finder .finder-results .result .result-info {
    width: 100%;
    float: none;
  }
}
.section-mijn-scholen .select-type-buttons {
  margin-left: calc(18% + 20px);
}
.section-mijn-scholen .select-type-buttons a {
  margin: 0 20px 20px 0;
}
.section-mijn-scholen .col-left,
.section-mijn-scholen .col-right {
  margin-bottom: 60px;
}
.section-mijn-scholen .col-left {
  width: 18%;
  float: left;
  position: relative;
  z-index: 100;
}
.section-mijn-scholen .col-left:before {
  display: none;
  content: "";
  width: 1600px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  background: #fff;
}
.section-mijn-scholen .col-right {
  width: 82%;
  float: left;
  overflow: hidden;
  padding-right: 100px;
}
.section-mijn-scholen .col-right .inner {
  position: relative;
  margin-bottom: 60px;
}
.section-mijn-scholen .col-right .inner:before {
  display: block;
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #D5D5D5;
}
.section-mijn-scholen .slick-scholen .slick-list {
  overflow: visible !important;
}
.section-mijn-scholen .slick-scholen .slick-dots {
  bottom: -50px;
}
.section-mijn-scholen .slick-scholen .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.section-mijn-scholen .slick-scholen .item {
  display: block !important;
}
.section-mijn-scholen .slick-scholen .item .school-header {
  padding: 0 15px 0 20px;
}
.section-mijn-scholen .slick-scholen .item .school-header .bgimg {
  display: block;
  height: 240px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.section-mijn-scholen .slick-scholen .item .school-header .bgimg img {
  display: none;
}
.section-mijn-scholen .slick-scholen .item .school-name {
  position: relative;
  padding: 30px 10px 20px;
}
.section-mijn-scholen .slick-scholen .item .school-name .btn-favorite {
  position: absolute;
  top: 52px;
  left: 10px;
}
.section-mijn-scholen .slick-scholen .item .school-name .title {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.section-mijn-scholen .slick-scholen .item .school-name .meta {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 30px;
}
.section-mijn-scholen .slick-scholen .item .attribute {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-mijn-scholen .school-attributes .at-row:last-child .attribute {
  border-bottom: none;
}
.section-mijn-scholen .school-attributes .attribute {
  display: block;
  padding: 6px 20px 6px 60px;
  background: #fff;
  min-height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #D5D5D5;
}
.section-mijn-scholen .school-attributes .attribute .open-day-attribute-value {
  height: 55px;
}
.section-mijn-scholen .school-attributes .attribute.open-day-attribute:last-child {
  border-bottom: none;
}
.section-mijn-scholen .school-attributes .attribute .label {
  display: none;
  font-size: 11px;
  line-height: 1;
  opacity: 0.6;
  margin-top: 5px;
}
.section-mijn-scholen .school-attributes .attribute:nth-child(even) {
  background: #fff;
}
.section-mijn-scholen .school-attributes.legenda {
  text-align: right;
}
.section-mijn-scholen .school-attributes.legenda .at-row .attribute {
  display: inline-block;
  width: 100%;
}
.section-mijn-scholen .school-attributes.legenda .at-row:nth-child(even) .attribute {
  background: #fff;
}
.section-mijn-scholen .attribute-layer {
  text-align: right;
}
.section-mijn-scholen .attribute-layer .at-row {
  position: relative;
}
.section-mijn-scholen .attribute-layer .at-row .attribute {
  display: inline-block;
  width: 100%;
  padding: 6px 20px;
  padding-right: 15px;
  min-height: 40px;
}
.section-mijn-scholen .attribute-layer .at-row.two-liner .attribute {
  min-height: 65px;
  line-height: 50px;
}
.section-mijn-scholen .attribute-layer .at-row .infotip {
  position: absolute;
  top: 2px;
  right: 7px;
}
.section-mijn-scholen .attribute-layer .at-row:nth-child(even) .attribute {
  background: #fff;
}
.section-mijn-scholen .section-actions {
  clear: both;
  text-align: center;
}
.section-mijn-scholen .slick-slide .school-attributes .attribute:last-child {
  border-bottom: none;
}
@media (max-width: 1280px) {
  .section-mijn-scholen .col-left {
    width: 22%;
  }
  .section-mijn-scholen .col-right {
    width: 78%;
    padding-right: 60px;
  }
}
@media (max-width: 991px) {
  .section-mijn-scholen .col-left {
    display: none;
  }
  .section-mijn-scholen .col-right {
    width: 100%;
    padding-right: 100px;
  }
  .section-mijn-scholen .col-right .inner {
    padding-bottom: 25px;
  }
  .section-mijn-scholen .slick-dots {
    width: calc(100% + 100px);
    bottom: -30px;
    left: 0;
  }
  .section-mijn-scholen .school-attributes .attribute .label {
    display: block;
  }
}
@media (max-width: 450px) {
  .section-mijn-scholen .col-right {
    padding-right: 60px;
  }
  .section-mijn-scholen .slick-dots {
    width: calc(100% + 60px);
  }
}
.section-mijnscholen-articles .st-inner:before {
  width: 140px;
  height: 140px;
  top: 0;
  left: 0;
  background-image: url(./img/svg/mijn-scholen/2.svg);
  background-position: top left;
}
.section-mijnscholen-articles .st-inner:after {
  width: 200px;
  height: 200px;
  top: 0;
  right: 0;
  background-image: url(./img/svg/mijn-scholen/3.svg);
  background-position: top right;
}
.section-mijnscholen-articles .st-inner .before {
  width: 140px;
  height: 140px;
  bottom: 0;
  right: 0;
  background-image: url(./img/svg/mijn-scholen/4.svg);
  background-position: bottom right;
}
.section-mijnscholen-articles .st-inner .after {
  width: 140px;
  height: 140px;
  bottom: 0;
  left: 0;
  background-image: url(./img/svg/mijn-scholen/5.svg);
  background-position: bottom left;
}
.section-mijnscholen-articles .st-header {
  max-width: 850px;
  margin-bottom: 60px;
}
.page-btn-back {
  display: block;
  width: 240px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.page-btn-back:before {
  display: block;
  content: "";
  width: 100%;
  height: 200%;
  border-radius: 50%;
  background-color: #69aadc;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all 0.15s ease-out;
}
.page-btn-back .label {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 22px;
  position: absolute;
  top: 30px;
  left: 0;
  transition: all 0.15s ease-out;
}
.page-btn-back i {
  font-size: 50px;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  transition: all 0.15s ease-out;
}
.page-btn-back:hover {
  opacity: 1;
}
.page-btn-back:hover:before {
  background-color: #D82B84;
}
.page-btn-back:hover .label {
  top: 60px;
}
.page-btn-back:hover i {
  top: 15px;
}
@media (max-width: 991px) {
  .page-btn-back {
    transform-origin: left center;
    transform: scale(0.8);
    top: -12px;
  }
}
.pdf-wrapper {
  padding: 25px 0;
}
.pdf-wrapper .page-title {
  font-size: 24px;
  margin-bottom: 20px;
}
.pdf-wrapper .pdf-header {
  margin-bottom: 40px;
  padding: 0 1cm;
}
.pdf-wrapper .pdf-footer {
  margin-top: 40px;
  padding: 0 1cm;
}
.school-grid .item {
  width: 50%;
  float: left;
  padding: 0 1cm;
  margin-top: 30px;
  margin-bottom: 1cm;
}
.school-grid .item .school-header {
  padding: 0;
}
.school-grid .item .school-header .school-image {
  display: block;
}
.school-grid .item .school-header .school-image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.school-grid .item .school-header .school-name {
  position: relative;
  padding: 20px 0 10px;
}
.school-grid .item .school-header .school-name .title {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.school-grid .item .school-header .school-name .meta {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.school-grid .item .school-attributes {
  font-size: 11px;
  line-height: 1.4em;
}
.school-grid .item .school-attributes .attribute {
  display: block;
}
.school-grid .item .school-attributes .attribute .key {
  display: inline-block;
  width: 150px;
}
.school-grid section {
  display: none;
}
.school-grid section:nth-of-type(2n) {
  float: none;
  clear: both;
  display: block;
  width: 100%;
  height: 1px;
}
.school-grid section:nth-of-type(4n) {
  float: none;
  clear: both;
  display: block;
  width: 100%;
  height: 1px;
  page-break-after: always;
}
.school-grid section:last-of-type {
  display: none !important;
}
