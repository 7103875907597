.slick-articles {

  width: calc(100% + 30px);

  .item {

    .inner {
      margin-right: 30px;
    }

  }

  .slick-active {

    .item {



    }

  }

}


.slick-open-days-outer {
  padding: 0 145px;
  margin-bottom: 80px;
}

.slick-open-days {

  .slick-dots {
    left: 0;
    bottom: -40px;
  }

  .item {
    padding: 0 15px;
  }

  .inner {
    padding: 20px;
    border: 1px solid @dark;
    border-radius: 15px;

    .date {
      .f2;
      font-size: 22px;
    }

    .type {

    }

    .time {
      .f2;
    }

    .info {

      margin-top: 10px;

      a {
        word-break: break-all;
      }

      p {
        margin-bottom: 0;
      }

    }

  }

}


@media (max-width: 1280px) {


  .slick-open-days-outer {
    padding: 0;
    margin-top: 60px;
  }

}

@media (max-width: 991px) {


}

@media (max-width: 767px) {

  .slick-open-days-outer {
    margin-top: 30px;
  }

}




