.section-home-header {

  position: relative;
  z-index: 20;

  .st-inner {

    padding-top: 100px;
    padding-bottom: 160px;

    &:before {
      width: 140px;
      height: 140px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/home/1.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/home/2.svg);
      background-position: top right;
    }

    .before {
      width: 240px;
      height: 240px;
      bottom: -83px;
      left: 0;

      background-image: url(../img/svg/home/3.svg);
      background-position: top left;
    }

  }

  .content {
    padding-left: 160px;
    padding-right: 160px;
  }

  .st-header {
    margin-bottom: 60px;
  }

  .category-list {

    overflow: hidden;

    .cat-button {
      display: inline-block;
      font-size: 26px;
      text-transform: lowercase;
      padding: 12px 60px 12px 20px;

      border: 2px solid @dark;
      border-radius: 50px;

      position: relative;

      .arrow {

        font-size: 40px;

        position: absolute;
        top: 50%;
        right: 3px;
        transform: translateY(-50%);
      }

    }

    &.primary {

      border-bottom: 2px solid @black;

      li {
        width: 33.3334%;
        float: left;
        margin-bottom: 30px;

        a {

          &.ko {

            i {
              color: @color_ko;
            }

            &:hover {

              color: @white;
              background-color: @color_ko;
              border-color: @color_ko;

              i {
                color: @white;
              }

            }

          }
          &.bo {

            i {
              color: @color_bo;
            }

            &:hover {

              color: @white;
              background-color: @color_bo;
              border-color: @color_bo;

              i {
                color: @white;
              }

            }

          }
          &.vo {

            i {
              color: @color_vo;
            }

            &:hover {

              color: @white;
              background-color: @color_vo;
              border-color: @color_vo;

              i {
                color: @white;
              }

            }

          }
          &.mbo {

            i {
              color: @color_mbo;
            }

            &:hover {

              color: @white;
              background-color: @color_mbo;
              border-color: @color_mbo;

              i {
                color: @white;
              }

            }

          }
          &.hbo {

            i {
              color: @color_hbo;
            }

            &:hover {

              color: @white;
              background-color: @color_hbo;
              border-color: @color_hbo;

              i {
                color: @white;
              }

            }
          }
        }
      }
    }

    &.secondary {

      li {
        width: 33.3334%;
        float: left;
        margin-top: 30px;

        a {
          &.vavo {

            i {
              color: @color_vavo;
            }

            &:hover {

              color: @white;
              background-color: @color_vavo;
              border-color: @color_vavo;

              i {
                color: @white;
              }
            }
          }
          &.so {

            i {
              color: @color_so;
            }

            &:hover {

              color: @white;
              background-color: @color_so;
              border-color: @color_so;

              i {
                color: @white;
              }

            }

          }
          &.io {

            i {
              color: @color_io;
            }

            &:hover {

              color: @white;
              background-color: @color_io;
              border-color: @color_io;

              i {
                color: @white;
              }

            }

          }

        }

      }

    }

  }

  .category-list {

    a {

      [class^="icon-"],
      [class*=" icon-"] {
        display: inline-block;
        transform: translate(0, 0);
        transition: all 0.1s ease-out;
      }

      .hover &,
      &:hover {
        [class^="icon-"],
        [class*=" icon-"] {
          transition: all 0.3s cubic-bezier(0.2, 0.9, 0.3, 2.5), color 0.1s ease-out;
        }

        .icon-right-small { transform: translate(3px, 0); }
      }

    }

  }


}


@media (max-width: 1480px) {

  .section-home-header {

    .content {
      padding-left: 80px;
      padding-right: 80px;
      .category-nav {
        .category-list {
          &.secondary {

            li:nth-last-child(1) {
              width: 50%;
            }
          }
        }
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-home-header {

    .content {
      padding-left: 0;
      padding-right: 0;
    }

  }

}

@media (max-width: 991px) {

  .section-home-header {

    .st-inner {
      padding-top: 60px;
      padding-bottom: 160px;

      &:before {
        display: none;
      }

      .before {
        width: 180px;
        height: 180px;
        bottom: -62px;
      }

    }

    .category-list {

      &.primary {

        li {
          width: 50%;
          margin-bottom: 20px;

          a {
            font-size: 24px;
            max-width: none;
          }

        }

      }

      &.secondary {

        li {
          width: 50%;
          margin-top: 20px;

          &:first-child {
            width: 50%;;
          }

          a {
            font-size: 24px;
            max-width: none;
          }

        }

      }

    }

  }

}

@media (max-width: 740px) {

  .section-home-header {

    .category-list {

      &.primary {

        li {
          width: 100%;
          float: none;
        }

      }

      &.secondary {

        li {
          width: 100%;
          float: none;
        }

      }

    }

  }

}



.section-home-article-a {

  .st-inner {

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/home/4.svg);
      background-position: top right;
    }

  }

  .col-left {

    position: relative;

    .st-image {

      width: 150%;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;

      .bgcover;

      img {
        display: none;
      }

    }

  }

  .col-right {

    .inner {
      padding: 160px 0 160px 160px;
      position: relative;

      &:before {
        display: block;
        content: "";
        position: absolute;
        pointer-events: none;

        background-size: contain;
        background-repeat: no-repeat;

        width: 160px;
        height: 160px;
        bottom: 0;
        left: -25px;

        background-image: url(../img/svg/home/5.svg);
        background-position: bottom left;

      }

    }

  }

}

@media (max-width: 1480px) {

  .section-home-article-a {

    .col-right {

      .inner {
        padding: 120px 0 180px 80px;
      }

    }

  }

}

@media (max-width: 1280px) {

  .section-home-article-a {

    .st-inner {

      &:after {
        width: 110px;
        height: 160px;
      }

    }

    .col-right {

      .inner {
        padding: 60px 0 140px 40px;

        &:before {
          width: 120px;
          height: 120px;
        }

      }

    }

  }

}

@media (max-width: 991px) {

  .section-home-article-a {

    .st-inner {

      &:after {
        display: none;
      }

    }

    .col-left {

      .st-image {

        width: auto;
        height: auto;
        position: static;

        img {
          display: block;
        }

      }

    }

    .col-right {

      .inner {
        padding: 60px 0 60px 0;

        &:before {
          display: none;
        }

      }

    }

  }

}

@media (max-width: 767px) {


}




.section-home-article-b {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      top: 0;
      left: -100px;

      background-image: url(../img/svg/home/6.svg);
      background-position: top left;
    }

  }

  .col-left {
    float: right;
  }

  .st-image {

    position: relative;

    &.has-before {

      &:before {
        width: 160px;
        height: 100px;
        top: 0;
        right: 0;

        background-image: url(../img/svg/home/10.svg);
        background-size: contain;
        background-position: top right;
        background-repeat: no-repeat;

      }

    }

  }

}

@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-home-article-b {

    .st-inner {

      padding-top: 120px;

    }

  }

}

@media (max-width: 991px) {

  .section-home-article-b {

    .col-left {
      margin-bottom: 60px;
    }

  }

}

@media (max-width: 767px) {


}





.section-home-article-c {

  .st-inner {

    &:before {
      width: 200px;
      height: 200px;
      bottom: 0;
      left: -65px;

      background-image: url(../img/svg/home/9.svg);
      background-position: bottom left;
    }

    &:after {
      width: 200px;
      height: 200px;
      top: -90px;
      right: 0;

      background-image: url(../img/svg/home/8.svg);
      background-position: top right;
    }

  }

  .col-right {

    .inner {

      max-width: 600px;

    }

  }

}


@media (max-width: 1480px) {

  .section-home-article-c {

    .col-right {

      .inner {

        max-width: 540px;

      }

    }

  }

}

@media (max-width: 1280px) {



}

@media (max-width: 991px) {

  .section-home-article-c {

    .st-inner {
      padding-top: 40px;
      padding-bottom: 160px;

      &:before {
        width: 160px;
        height: 160px;
      }

      &:after {
        width: 160px;
        height: 160px;
        top: -45px;
      }

    }

    .col-left {

      .st-header {
        text-align: left;
      }

    }

    .col-right {

      .inner {

        max-width: none;

      }

    }

  }

}

@media (max-width: 767px) {

  .section-home-article-c {

    .st-inner {

      &:before {
        width: 160px;
        height: 100px;
        left: -45px;
      }

      &:after {
        width: 80px;
        height: 140px;
        top: -30px;
      }

    }

  }

}

@media (max-width: 540px) {

  .section-home-article-c {

    .st-inner {

      &:before {
        width: 130px;
        height: 85px;
        left: -40px;
      }

    }

  }

}
