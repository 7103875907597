.page-btn-back {
  display: block;
  width: 240px;
  height: 120px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 200%;
    border-radius: 50%;

    background-color: @blue;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    transition: all 0.15s ease-out;
  }

  .label {
    display: block;
    width: 100%;
    text-align: center;

    font-size: 22px;

    position: absolute;
    top: 30px;
    left: 0;

    transition: all 0.15s ease-out;
  }

  i {

    font-size: 50px;

    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);

    transition: all 0.15s ease-out;
  }

  &:hover {

    opacity: 1;

    &:before {
      background-color: @pink;
    }

    .label {
      top: 60px;
    }

    i {
      top: 15px;
    }

  }
}

@media (max-width: 991px) {
  .page-btn-back {
    transform-origin: left center;
    transform: scale(0.8);
    top: -12px;
  }
}
