
/* ==========================================================================
	BASE
   ========================================================================== */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }


html, body {
  box-sizing: border-box;
  height: 100%;

  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
	font-family: @font1;
	font-size: 17px;
	line-height: 1.6em;
	color: @black;
	font-weight: normal;
}

ul,ol,li {
	margin: 0;
	padding: 0;
	list-style: none;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
}
/* @end */


/* @group default links */
a:link, a:active, a:visited {
  outline: none;
  color: @black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:hover {
	opacity: 0.7;
}


a:active,
a:focus,
*:focus {
	outline: none !important;
	text-decoration: none;
}

.noclick {
  cursor: default;
}

.bold {
	font-weight: bold;
}

img {
	max-width: 100%;
}

.align-right {
	text-align: right;
}

.container {
	position: relative;
}

.containerxx {
	background: rgba(75,125,88, 0.1);
}

.plx {
	pointer-events: none;
}

.container {
	position: relative;
}



@media (max-width: 991px) {

	body {

	}

}

@media (max-width: 767px) {

	body {

	}

}



.indented {

	margin: 0 20px;

}


.bg-blue {
	background-color: @blue;
}

.bg-blue-2 {
	background-color: @blue2;
}

.bg-blue-3 {
	background-color: @blue3;
}

.bg-green {
	background-color: @green;
}


/* ==========================================================================
	HEADINGS
   ========================================================================== */

h1,h2,h3,h4,
.h1, .h2, .h3, .h4  {

	font-family: @font2;
	line-height: 1.1em;
	margin: 0 0 15px;
	color: @black;
	font-weight: normal;

	a {
		color: inherit;
	}

	.duotone {
		font-weight: bold;
	}

}

.split-title {

	span {
		display: block;
	}

}


.f1 {
	font-family: @font1;
}

.f2 {
	font-family: @font2;
}

.f3 {
	font-family: @font3;
}

.f4 {
	font-family: @font4;
	line-height: 1.2em;
}


.t-upper {
	text-transform: uppercase;
}

.t-lower {
	text-transform: lowercase;
}

.t-intro {
	text-transform: uppercase;
	font-family: @font2;
}

.mb-0 {
	margin-bottom: 0;
}
.mb-1 {
	margin-bottom: 10px;
}
.mb-2 {
	margin-bottom: 20px;
}
.mb-3 {
	margin-bottom: 30px;
}
.mb-4 {
	margin-bottom: 50px;
}
.mb-5 {
	margin-bottom: 50px;
}
.mb-6 {
	margin-bottom: 60px;
}




h0, .h0 {
	font-size: 60px;
}

h1, .h1 {
	font-size: 58px;
}

h2, .h2 {
	font-size: 38px;
}

h3, .h3 {
	font-size: 34px;
}

h4, .h4 {
	font-size: 20px;
}

h5, .h5 {
	font-size: 16px;
}


@media (max-width: 1281px) {

	h0, .h0 {
		font-size: 40px;
	}

	h1, .h1 {
		font-size: 40px;
	}

	h2, .h2 {
		font-size: 30px;
	}

	h3, .h3 {
		font-size: 26px;
	}

	h4, .h4 {
		font-size: 20px;
	}

	h5, .h5 {
		font-size: 16px;
	}

}


@media (max-width: 991px) {



}

@media (max-width: 767px) {

	h0, .h0 {
		font-size: 36px;
	}

	h1, .h1 {
		font-size: 36px;
	}

	h2, .h2 {
		font-size: 30px;
	}

	h3, .h3 {
		font-size: 24px;
	}

	h4, .h4 {
		font-size: 20px;
	}

	h5, .h5 {
		font-size: 16px;
	}

}






.h-white {
	color: @white;
}
.h-dark {
	color: @dark;
}
.h-green {
	color: @green;
}
.h-red {
	color: @red;
}
.h-blue {
	color: @blue;
}
.h-blue2 {
	color: @blue2;
}
.h-blue3 {
	color: @blue3;
}
.h-blue4 {
	color: @blue4;
}
.h-inline {
	display: inline-block;
}


.a-left {
	text-align: left;
}
.a-center {
	text-align: center;
}
.a-right {
	text-align: right;
}




/* ==========================================================================
	ARTICLE OUTPUT  DEFAULTS
   ========================================================================== */
.article-intro {

	font-family: @font3;
	color: @blue;
	margin-bottom: 30px;

}


.article-output {

	h1, h2, h3, h4, h5 {
		margin-bottom: 8px;
		color: @black;
	}

	p {

		margin-bottom: 30px;

		&:last-child {

			margin-bottom: 0;

		}

	}

	a {
		font-weight: 700;
		border-bottom: 2px solid @dark;
	}

	ul,ol,li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	ul {

		margin: 0 0 20px;

		li {
			margin-left: 30px;
			list-style: disc;
		}

	}

	ol {
		margin: 0 0 20px;

		li {
			margin-left: 30px;
			list-style: decimal;
		}

	}


	table {

		width: 100%;
		margin-bottom: 30px;

		td {
			padding: 5px 8px;

			&:last-child {
				width: auto;
			}

		}

		tr {
			background: @baby;
		}

		tr:nth-child(odd) {
			background: #fff;
		}

		tr:first-child {

			background: #76a7d8;

			td {
				.f2;
				color: @black;
				font-weight: bold;
			}

		}

		tbody {

			th {
				background: @dark;
				color: @white;
				text-align: left;
				padding: 5px 8px;

				&:last-child {
					width: auto;
				}

			}

		}


		&.medium-table {

		}

		&.large-table {

			width: 100%;
			max-width: 1600px;

			tbody {

				th,
				td {
					white-space: normal;
				}

			}

		}

	}

	.scrollpointer {
		display: none;
	}

}



@media (max-width: 991px) {

	.article-output {

		.table-scroll-container {

			overflow-y: scroll;
			padding-bottom: 10px;

			table {
				width: 1000px;
				margin-bottom: 0 !important;
			}

		}

		.scrollpointer {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 30px;
			color: @dark;
			background: #ccc;
			line-height: 30px;
		}

	}

}

@media (max-width: 776px) {

	.article-output {

		.table-scroll-container {

			table {
				width: 1000px;
			}

		}

	}

}


@media (max-width: 1281px) {

	.article-output {

		h1 {
			font-size: 32px;
		}

		h2 {
			font-size: 28px;
		}

		h3 {
			font-size: 22px;
		}

		h4 {
			font-size: 18px;
		}

	}

}

@media (max-width: 991px) {

	.article-output {

		h1 {
			font-size: 30px;
		}

		h2 {
			font-size: 26px;
		}

		h3 {
			font-size: 20px;
		}

		h4 {
			font-size: 18px;
		}

	}

}

@media (max-width: 767px) {

	.article-output {

		h1 {
			font-size: 28px;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 20px;
		}

		h4 {
			font-size: 18px;
		}

	}

}
