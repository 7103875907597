.section {

  .st-inner {

    padding: 80px 0;

    max-width: 5000px;
    margin: 0 auto;
    position: relative;

    &:before,
    &:after,
    .before,
    .before-2,
    .after,
    .after-2 {
      display: block;
      content: "";
      position: absolute;
      pointer-events: none;

      background-size: contain;
      background-repeat: no-repeat;

    }

    &:before {

    }

    &:after {

    }

    &.before {

    }

    &.py-md {
      padding: 100px 0;
    }

    &.py-lg {
      padding: 120px 0;
    }

    &.py-0 {
      padding: 0;
    }


    &.pb-8 {
      padding-bottom: 80px;
    }
    &.pb-9 {
      padding-bottom: 90px;
    }
    &.pb-10 {
      padding-bottom: 100px;
    }
    &.pb-11 {
      padding-bottom: 110px;
    }
    &.pb-12 {
      padding-bottom: 120px;
    }


  }

  .container {
    position: relative;
    z-index: 10;
  }

  .indent {
    padding-left: 160px;
  }

  .st-image {

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      pointer-events: none;
    }

  }

  &.no-bff {

    .st-inner {

      &:before,
      &:after,
      .before,
      .before-2,
      .after,
      .after-2 {
        display: none !important;
      }

    }

  }

}

.debug {

  .section {

    border-bottom: 1px solid red;

    .st-inner {

      &:before,
      &:after,
      .before,
      .after {
        background-color: deeppink;
      }

      &:before {
        background-color: #4c7c33;
      }

      &:after {
        background-color: #DD271C;
      }

      &.before {
        background-color: pink;
      }

    }

    .st-image {

      &:before,
      &:after {
        background-color: deeppink;
      }

    }

  }

}



@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section {

    .indent {
      padding-left: 0;
    }

  }

}

@media (max-width: 991px) {

  .section {

    .st-inner {

      padding: 60px 0;

      &.py-lg {
        padding: 100px 0;
      }

    }

  }

}