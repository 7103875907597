
/* ==========================================================================
	FORM CONTROL
   ========================================================================== */

form.formResponsive {

	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color: @white;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: @white;
		opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: @white;
		opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: @white;
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: @white;
	}
	::placeholder { /* Most modern browsers support this now. */
		color: @white;
	}

	fieldset {
		padding: 0;
		margin: 0;
		border: none;
	}

	textarea,
	input[type="text"] {
		width: 100%;
		height: 50px;
		padding: 3px 8px;
		background: none;
		color: @white;
		border: 2px solid @blue_m;
		border-radius: 1px;
	}

	textarea {
		height: 150px;
		padding: 8px;
	}

	label {

		.formRequired {

			display: inline-block;
			color: @orange;
			font-weight: normal;
			font-size: 16px;

			position: relative;
			top: -5px;

		}

	}

	.formError {
		color: @orange;
	}

	.rsform-block {

		width: 100%;
		clear: both;
		float: none;
		margin-bottom: 20px;

		.formDescription {
			display: none;
		}

		&.rsform-error-parent {

			background: rgba(229,102,38,0.1);

			textarea,
			input[type="text"] {
				border-color: @orange;
			}

		}

		&.rsform-submit {
			background: none;
			padding: 0;
			border-radius: 0;

			.formBody {
				text-align: right;
			}

		}

		.formControlLabel {
			display: none;
			color: @dark;
			font-weight: bold;
		}

		&.rsform-block-veiligheidscode {
			height: 1px;
			background: none !important;
			padding: 0;
			margin: 0;
		}

	}


	.rsform-select {

		background: rgba(0,0,0,0.03);
		padding: 20px;

		.formControlLabel {
			margin-bottom: 10px;
		}

		select {
			width: 100%;
		}

	}


	.rsform-checkboxes,
	.rsform-radios {

		background: rgba(0,0,0,0.03);
		padding: 20px;

		&.rsform-error-parent {

		}

		.formControlLabel {
			margin-bottom: 10px;
		}

	}


	.formBody {

		width: 100%;
		clear: both;
		float: none;

		&.frm-checkboxes,
		&.frm-radios {

			label {
				display: block;
				width: 100%;
				float: none;
				margin-bottom: 10px;
			}

			input {
				margin-right: 10px;
			}

		}

	}

}