.article-grid {

  .row {
    margin-right: -40px;
    margin-left: -40px;
  }

  .item {
    margin-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }

}


.article-list {

  .item {

    padding: 50px 0;

    .article-image {
      position: relative;
      overflow: hidden;

      &:after {
        display: block;
        content: "";
        width: 130%;
        height: 100px;

        background: @white;

        transform-origin: top left;
        transform: skewY(-5deg);

        position: absolute;
        bottom: -100px;
        left: 0;
      }

    }

    .col-left {

      .inner {
        padding-right: 20px;
      }

    }

    .col-right {

      .inner {
        padding-left: 20px;
      }

    }

    .article-header {
      max-width: 450px;
      margin-bottom: 20px;

      h2 {
        margin-bottom: 20px;
      }

      .label {
        font-size: 16px;
        color: @blue;
      }

    }

    .article-output {
      margin-bottom: 40px;
    }

    .article-actions {

      overflow: hidden;

      .button {
        float: left;
        margin: 10px 10px 0 0;
      }

    }



    &:nth-child(even) {

      background: @blue3;

      .article-image {

        &:after {
          background: @blue3;
        }

      }

    }

  }

}
