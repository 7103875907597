.section-mijn-scholen {


  .select-type-buttons {

    margin-left: calc(18% + 20px);

    a {
      margin: 0 20px 20px 0;

      &:hover {

      }

    }

  }

  .col-left,
  .col-right {
    margin-bottom: 60px;
  }

  .col-left {
    width: 18%;
    float: left;
    position: relative;
    z-index: 100;

    &:before {
      display: none;
      content: "";
      width: 1600px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;

      background: @white;
    }

  }

  .col-right {
    width: 82%;
    float: left;
    overflow: hidden;
    padding-right: 100px;

    .inner {
      position: relative;
      margin-bottom: 60px;

      &:before {
        display: block;
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        background: @grey5;
      }

    }

  }

  .slick-scholen {

    .slick-list {
      overflow: visible !important;
    }

    .slick-dots {
      bottom: -50px;
    }

    .slick-track {
      margin-left: 0;
      margin-right: 0;
    }

    .item {

      display: block !important;

      .school-header {
        padding: 0 15px 0 20px;

        .bgimg {
          display: block;
          height: 240px;
          .bgcover;

          img {
            display: none;
          }

        }

      }

      .school-name {

        position: relative;
        padding: 30px 10px 20px;

        .btn-favorite {
          position: absolute;
          top: 52px;
          left: 10px;
        }

        .title {
          font-size: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        .meta {
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 30px;
        }

      }


      .attribute {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

  }

  .school-attributes {
    .at-row:last-child {
      .attribute {
        border-bottom: none;
      }
    }

    .attribute {
      display: block;
      padding: 6px 20px 6px 60px;
      background: @white;
      min-height: 40px;

      .open-day-attribute-value {
        height: 55px;
      }

      font-size: 16px;

      box-sizing: border-box;
      border-bottom: 1px solid #D5D5D5;

      &.open-day-attribute:last-child {
        border-bottom: none;
      }

      .label {
        display: none;
        font-size: 11px;
        line-height: 1;
        opacity: 0.6;
        margin-top: 5px;
      }

      &:nth-child(even) {
        background: @white;
      }

    }

    &.legenda {

      text-align: right;

      .at-row {

        .attribute {
          display: inline-block;
          width: 100%;
        }

        &:nth-child(even) {

          .attribute {
            background: @white;
          }

        }

      }

    }

  }

  .attribute-layer {

    text-align: right;

    .at-row {

      position: relative;

      .attribute {
        display: inline-block;
        width: 100%;
        padding: 6px 20px;
        padding-right: 15px;
        min-height: 40px;
      }

      &.two-liner {
        .attribute {
          min-height: 65px;
          line-height: 50px;
        }
      }

      .infotip {
        position: absolute;
        top: 2px;
        right: 7px;
      }

      &:nth-child(even) {

        .attribute {
          background: @white;
        }

      }
    }

  }

  .section-actions {
    clear: both;
    text-align: center;
  }

  .slick-slide {
    .school-attributes {
      .attribute:last-child {
        border-bottom: none;
      }
    }
  }

}


@media (max-width: 1480px) {


}

@media (max-width: 1280px) {

  .section-mijn-scholen {

    .col-left {
      width: 22%;
    }

    .col-right {
      width: 78%;
      padding-right: 60px;
    }

  }

}

@media (max-width: 991px) {

  .section-mijn-scholen {

    .col-left {
      display: none;
    }

    .col-right {
      width: 100%;
      padding-right: 100px;

      .inner {
        padding-bottom: 25px;
      }

    }

    .slick-dots {
      width: calc(100% + 100px);
      bottom: -30px;
      left: 0;
    }

    .school-attributes {

      .attribute {

        .label {
          display: block;
        }

      }

    }

  }

}

@media (max-width: 450px) {

  .section-mijn-scholen {

    .col-right {
      padding-right: 60px;
    }

    .slick-dots {
      width: calc(100% + 60px);
    }

  }

}


.section-mijnscholen-articles {

  .st-inner {

    &:before {
      width: 140px;
      height: 140px;
      top: 0;
      left: 0;

      background-image: url(../img/svg/mijn-scholen/2.svg);
      background-position: top left;

    }

    &:after {
      width: 200px;
      height: 200px;
      top: 0;
      right: 0;

      background-image: url(../img/svg/mijn-scholen/3.svg);
      background-position: top right;
    }

    .before {
      width: 140px;
      height: 140px;
      bottom: 0;
      right: 0;

      background-image: url(../img/svg/mijn-scholen/4.svg);
      background-position: bottom right;
    }

    .after {
      width: 140px;
      height: 140px;
      bottom: 0;
      left: 0;

      background-image: url(../img/svg/mijn-scholen/5.svg);
      background-position: bottom left;
    }

  }


  .st-header {
    max-width: 850px;
    margin-bottom: 60px;
  }

}
