
.pdf-wrapper {

  padding: 25px 0;

  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .pdf-header {
    margin-bottom: 40px;
    padding: 0 1cm;
  }

  .pdf-footer {
    margin-top: 40px;
    padding: 0 1cm;
  }


}


.school-grid {

  .item {
    width: 50%;
    float: left;
    padding: 0 1cm;
    margin-top: 30px;
    margin-bottom: 1cm;

    .school-header {
      padding: 0;

      .school-image {

        display: block;

        img {
          width: 100%;
          height: 150px;
          object-fit: cover;
        }

      }

      .school-name {

        position: relative;
        padding: 20px 0 10px;

        .title {
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        .meta {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }

    }

    .school-attributes {

      font-size: 11px;
      line-height: 1.4em;

      .attribute {

        display: block;

        .key {
          display: inline-block;
          width: 150px;
        }

      }

    }

  }

  section {
    display: none;
  }

  section:nth-of-type(2n) {
    float: none;
    clear: both;
    display: block;

    width: 100%;
    height: 1px;
  }

  section:nth-of-type(4n) {
      float: none;
      clear: both;
      display: block;

      width: 100%;
      height: 1px;
      page-break-after: always;
  }

  section:last-of-type {
    display: none !important;
  }

}