/* ==========================================================================
	MOBILE MENU
   ========================================================================== */

.nav-toggle {
	
	width: 36px;
	height: 40px;
	
	position: absolute;
	top: 50%;
	right: 25px;

	transform: translateY(-50%);

}


.nav-icon {
	
	display: block;
	width: 36px;
	cursor: pointer;
	position: absolute;
	
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	
	transition: all .3s ease-in-out;
	
	&:before,
	&:after,
	div {
	  background-color: @black;
	  content: '';
	  display: block;
	  position: relative;
	  
	  height: 3px;
	  margin: 6px 0;
	  transition: all .3s ease-in-out;
	}
	
	
	&.i-white {
		
		&:before,
		&:after,
		div {
			background-color: @black;
		}
		
	}

	@icony : 9px;
	
	.shownav & {
		
		&:before,
		&:after,
		div {
		  left: 0;
		}
		
		&:before {
		  transform: translateY(@icony) rotate(135deg);
		  -moz-transform: translateY(@icony) rotate(135deg);
		  -webkit-transform: translateY(@icony) rotate(135deg);
		}
		
		&:after {
		  transform: translateY(-@icony) rotate(-135deg);
		  -moz-transform: translateY(-@icony) rotate(-135deg);
		  -webkit-transform: translateY(-@icony) rotate(-135deg);
		}
		
		div {
		  -moz-transform: scale(0);
		  -webkit-transform: scale(0);
		  transform: scale(0);
		}
		
	}
	
}


.mobile-nav {
	
	width: 100%;
	height: 100%;
	padding: 0;
	color: @black;

	overflow: hidden;
	
	position: fixed;
	top: -100%;
	left: 0;
	z-index: 800;
	
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
	transition: all 0.5s ease 0s;

	background: @white;

	.container {
		height: 100vh;
		z-index: 200;
		
		font-size: 16px;
		line-height: 2.2em;
		
		position: relative;
		
		h2,h3,h4 {
			font-size: 18px;
		}
		
	}

	.top-bar {
		padding-top: 140px;
	}

	a {
		color: @black;
		
		&:hover {
			color: @black;
			opacity: 0.8;
		}
		
	}
	
	.mainnav {

		a {
			display: block;
			text-align: center;
			font-size: 22px;
			line-height: 2.4em;
		}

		ul {

			padding: 0 0 20px;

			a {
				font-size: 18px;
				line-height: 2.2em;
				color: @blue;
			}

		}

	}

}



.shownav {
	
	.mobile-nav { 
		
		top: 0;
		transition: all 0.6s ease 0s;
		
		.before {
			top: 0;
			transition: all 0.5s ease 0s;
		}
		
	}
	
}


@media (max-width: 991px) {



}


@media (max-width: 600px) {


}


.search-layer {

	width: 100%;
	height: 100%;
	padding: 0;
	color: @black;

	overflow: hidden;

	position: fixed;
	top: 0;
	left: -100%;
	z-index: 800;

	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	transition: all 0.5s ease 0s;

	background: @white;
	padding-top: 80px;

	&.show {
		left: 0;
	}

	.container {
		min-height: calc(100vh - 320px); /* padding-top 80 +  padding: 100px 0 140px; = 320px */
		z-index: 200;
	}

	.inner {

		position: relative;
		padding: 100px 0 140px;
		overflow: hidden;

		&:before,
		&:after,
		.before,
		.after {
			content: "";
			position: absolute;
			pointer-events: none;

			background-size: contain;
			background-repeat: no-repeat;

			background-color: rgba(0,0,0,0);
		}

		&:before {
			display: block;
			width: 160px;
			height: 160px;
			top: 0;
			left: 0;

			background-image: url(../img/svg/zoeken/1.svg);
			background-position: top left;

		}

		&:after {
			width: 100px;
			height: 100px;
			top: 0;
			right: 0;

			background-image: url(../img/svg/zoeken/2.svg);
			background-position: top right;
		}

		.before {
			width: 240px;
			height: 200px;
			bottom: -54px;
			right: -120px;

			background-image: url(../img/svg/zoeken/3.svg);
			background-position: bottom right;
		}

		.after {
			width: 200px;
			height: 160px;
			bottom: 0;
			left: -100px;

			background-image: url(../img/svg/zoeken/4.svg);
			background-position: bottom left;
		}

	}

}
